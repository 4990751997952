<template>
  <b-container fluid class="content">
    <!-------------------------------------- PO主頁 -------------------------------------->
    <div v-if="currpage == 'list'">
      <!-------------------------------------- PO主頁 上半部分 -------------------------------------->
      <b-card>
        <b-form>
          <el-row :gutter="10" style="margin-bottom: 10px">
            <el-col :span="6">
              <b-input-group :prepend="$t('Start Date')">
                <flat-pickr
                  :config="picker_config2"
                  class="form-control datepicker"
                  v-model="cur_start_dt"
                >
                </flat-pickr>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('End Date')">
                <flat-pickr
                  :config="picker_config2"
                  class="form-control datepicker"
                  v-model="cur_end_dt"
                >
                </flat-pickr>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <el-select
                class="el-dropdown"
                v-model="cur_date_type"
                :placeholder="$t('Date Type') + ' : '"
              >
                <el-option v-for="data_type in data_type_list"
                          :key="data_type.value"
                          :label="$t(data_type.label)"
                          :value="data_type.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-select
                class="el-dropdown"
                v-model="cur_vendor"
                :placeholder="$t('Vendor') + ' : '"
              >
                <el-option v-for="vendor in vendor_cname_list"
                          :key="vendor.IDn"
                          :label="vendor.CompanyName"
                          :value="vendor.IDn"
                          @click.native="find_vendor_id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-bottom: 10px">
            <el-col :span="6">
              <b-input-group prepend="PO # : ">
                <b-form-input v-model="cur_order_no"></b-form-input>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group prepend="Receive # : ">
                <b-form-input v-model="cur_receive_no"></b-form-input>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('Remarks') + ' : ' ">
                <b-form-input v-model="cur_remarks"></b-form-input>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <SearchAutocomplete
                :url="apiBaseUrl + '/Web/product/search_allforlist'"
                :placeholdertxt="$t('PLU/Barcode/Name') + ' : '"
                :key="componentKey_SearchAutocomplete"
                :initString="cur_product"
                :whereFrom="'PO'"
                :selected="selected_SearchAutocomplete"
                id="list_search_product"
                @input="set_cur_product_name"
                @keyword="reset_cur_product_idn"
              ></SearchAutocomplete>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-bottom: 10px">
            <el-col :span="6">
              <el-select
                class="el-dropdown"
                v-model="cur_LocationID"
                :placeholder="$t('Store') + ' : '"
              >
                <el-option v-for="store in store_list"
                          :key="store.LocationID"
                          :label="store.LocationID"
                          :value="store.LocationID">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2" class="text-center">
              <el-button
                type="primary"
                class="mt-2"
                icon="el-icon-search"
                size="small"
                @click="get_lists"
                >{{ $t("Search") }}</el-button
              >
            </el-col>
            <el-col :span="2" class="text-center">
              <el-button
                type="primary"
                class="mt-2"
                icon="el-icon-refresh-right"
                size="small"
                @click="reset_search"
                >{{ $t("Reset") }}</el-button
              >
            </el-col>
            <el-col :span="2" class="text-center">
              <el-button
                type="primary"
                class="mt-2"
                icon="el-icon-plus"
                size="small"
                @click="add_draft_po"
                v-acl:NewPO.enable.show
                >{{ $t("Add Draft") }}</el-button
              >
            </el-col>
          </el-row>
        </b-form>
      </b-card>
      <!-------------------------------------- PO主頁 下半部分 -------------------------------------->
      <b-card>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <!-------------------------------------- PO主頁 下半部分 Draft tab-------------------------------------->
          <el-tab-pane :label="$t('Draft')" name="Draft">
            <el-skeleton :loading="loadingTabContent" animated>
              <template slot="template">
                <el-skeleton-item
                  variant="rect"
                  style="width: 100%; height: 300px;"
                />
              </template>
              <el-table
                class="table-responsive table product-table mt-2"
                style="width: 100%"
                header-row-class-name="thead-light"
                :cell-style="{ textAlign: 'center' }"
                :data="cur_po_list"
                @row-click="edit_po"
              >
                <el-table-column
                  label="CreateDate"
                  class="p-0"
                  min-width="15%"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">
                      {{ $t("Create Date") }}
                    </div>
                  </template>
                  <template v-slot="{ row }">
                    {{ row.CreateDate ? row.CreateDate.substr(0,10) : "" }}
                  </template>
                </el-table-column>
                <el-table-column label="Vendor" class="p-0" min-width="25%">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Vendor") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{
                      row.PurchaseFOtherName
                        ? row.PurchaseFName + "(" + row.PurchaseFOtherName + ")"
                        : row.PurchaseFName
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Total"
                  class="p-0"
                  min-width="10%"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ "$ " + row.TotalAmount }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Currency"
                  class="p-0"
                  min-width="10%"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Currency") }}</div>
                  </template>
                  <template slot-scope=scope>
                    {{ determineCurrencyName(scope.row.CurrencyIDn) }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Store"
                  prop="ComTitle"
                  class="p-0"
                  min-width="15%"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="BySales"
                  prop="CreateByName"
                  class="p-0"
                  min-width="10%"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("BySales") }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </el-skeleton>
          </el-tab-pane>
          <!-------------------------------------- PO主頁 下半部分 Pending tab-------------------------------------->
          <el-tab-pane :label="$t('Pending')" name="Pending">
            <el-skeleton :loading="loadingTabContent" animated>
              <template slot="template">
                <el-skeleton-item
                  variant="rect"
                  style="width: 100%; height: 300px;"
                />
              </template>
              <el-table
                class="table-responsive table product-table mt-2"
                style="width: 100%"
                header-row-class-name="thead-light"
                :data="cur_po_list"
                @row-click="edit_po"
              >
                <el-table-column
                  label="OrderNo"
                  prop="OrderID"
                  class="p-0"
                  min-width="100px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("OrderNo") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ row.OrderID }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="OrderDate"
                  class="p-0"
                  min-width="100px"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">
                      {{ $t("Order Date") }}
                    </div>
                  </template>
                  <template v-slot="{ row }">
                    <div class="w-100 p-0 text-center">
                      {{ row.CreateDate ? row.CreateDate.substr(0,10) : "" }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Vendor" class="p-0" min-width="200px" align="center" >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Vendor") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{
                      row.PurchaseFOtherName
                        ? row.PurchaseFName + "(" + row.PurchaseFOtherName + ")"
                        : row.PurchaseFName
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Total"
                  class="p-0"
                  align="center"
                  min-width="100px"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ "$ " + row.TotalAmount }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Store"
                  prop="ComTitle"
                  class="p-0"
                  min-width="100px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="BySales"
                  prop="CreateByName"
                  class="p-0"
                  min-width="100px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("BySales") }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </el-skeleton>
          </el-tab-pane>
          <!-------------------------------------- PO主頁 下半部分 Complete tab-------------------------------------->
          <el-tab-pane :label="$t('Complete')" name="Complete">
            <el-skeleton :loading="loadingTabContent" animated>
              <template slot="template">
                <el-skeleton-item
                  variant="rect"
                  style="width: 100%; height: 300px;"
                />
              </template>
              <el-table
                class="table-responsive table product-table mt-2"
                style="width: 100%"
                header-row-class-name="thead-light"
                :data="cur_po_list"
                @row-click="edit_po"
              >
                <el-table-column
                  label="InvoiceDate"
                  prop="EndDate"
                  class="p-0"
                  min-width="75px"
                  align="center"
                >
                  <template v-slot:header>
                    <div class="w-100 p-0 text-center">
                      {{ $t("Invoice Date") }}
                    </div>
                  </template>
                  <template v-slot="{ row }">
                    <div class="w-100 p-0 text-center">
                      {{ row.EndDate ? row.EndDate.substr(0,10) : "" }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="OrderNo"
                  prop="OrderID"
                  class="p-0"
                  min-width="75px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("OrderNo") }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="Vendor" class="p-0" min-width="200px" align="center">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Vendor") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{
                      row.PurchaseFOtherName
                        ? row.PurchaseFName + "(" + row.PurchaseFOtherName + ")"
                        : row.PurchaseFName
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Total"
                  class="p-0"
                  min-width="75px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ "$ " + row.TotalAmount }}
                  </template>
                </el-table-column>
                <el-table-column label="Returns" class="p-0" min-width="75px" align="center">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Returns") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ "$ " + parseFloat(row.ReturnedTotal).toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column label="Balance" class="p-0" min-width="75px" align="center">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Balance") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ "$ " + parseFloat(row.Balance).toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Status"
                  prop="Status"
                  class="p-0"
                  min-width="100px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Status") }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Store"
                  prop="ComTitle"
                  class="p-0"
                  min-width="100px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="BySales"
                  prop="CreateByName"
                  class="p-0"
                  min-width="100px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("BySales") }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </el-skeleton>
          </el-tab-pane>
          <!-------------------------------------- PO主頁 下半部分 Deleted tab-------------------------------------->
          <el-tab-pane :label="$t('Delete')" name="Deleted">
            <el-skeleton :loading="loadingTabContent" animated>
              <template slot="template">
                <el-skeleton-item
                  variant="rect"
                  style="width: 100%; height: 300px;"
                />
              </template>
              <el-table
                class="table-responsive table product-table mt-2"
                style="width: 100%"
                header-row-class-name="thead-light"
                :data="cur_po_list"
                @row-click="edit_po"
              >
                <el-table-column
                  label="OrderDate"
                  class="p-0"
                  min-width="100px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">
                      {{ $t("Order Date") }}
                    </div>
                  </template>
                  <template v-slot="{ row }">
                    <div class="w-100 p-0 text-center">
                      {{ row.OrderDate ? row.OrderDate.substr(0,10) : "" }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="OrderNo"
                  prop="OrderID"
                  class="p-0"
                  min-width="100px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("OrderNo") }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="Vendor" class="p-0" min-width="200px" align="center">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Vendor") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{
                      row.PurchaseFOtherName
                        ? row.PurchaseFName + "(" + row.PurchaseFOtherName + ")"
                        : row.PurchaseFName
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Total"
                  prop="TotalAmount"
                  class="p-0"
                  min-width="100px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ "$ " + row.TotalAmount }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Store"
                  prop="ComTitle"
                  class="p-0"
                  min-width="100px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="BySales"
                  prop="CreateByName"
                  class="p-0"
                  min-width="100px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("BySales") }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </el-skeleton>
          </el-tab-pane>
          
        </el-tabs>
        <el-row style="margin-top: 20px">
          <el-col :span="20">
            <div>
              <b-pagination
                v-model="current_page"
                :total-rows="page_total"
                :per-page="per_page"
              ></b-pagination>
            </div>
          </el-col>
          <el-col :span="4">
            <div>
              <!-- <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  {{ $t("Max Rows per page") }} {{ this.per_page
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="page_option in per_page_option"
                    :key="page_option"
                    :command="page_option"
                    >{{ page_option }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown> -->
              <span>
                {{ $t("Max Rows per page") }}
              </span>
              <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  {{ per_page }}
                </template>
                <b-dropdown-item 
                  v-for="page_option in per_page_option"
                  :key="page_option"
                  :command="page_option"
                  @click="handleCommand">
                  {{ page_option }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </el-col>
        </el-row>
      </b-card>
    </div>

    <!-------------------------------------- PO訂單詳情分頁 -------------------------------------->
    <div class="po-edit" v-if="currpage == 'edit'">
      <!-------------------------------------- PO訂單詳情分頁 Header -------------------------------------->
      <el-row v-if="cur_po">
        <el-col>
          <div class="so_title p-2">
            <h1>{{ cur_po.OrderID }} {{ cur_po.Status }}</h1>
          </div>
        </el-col>
      </el-row>
      <!-------------------------------------- activeCollapse1 -------------------------------------->
      <el-collapse v-model="activeCollapse1">
        <!-------------------------------------- 地址訊息 -------------------------------------->
        <el-collapse-item
          class="collapseheader addressinfo"
          :title="$t('Address Info')"
          name="AddressInfo"
          @click.native="calculate_el_select_width2()"
        >
          <el-row :gutter="10">
            <!-------------------------------------- 選擇供應商 -------------------------------------->
            <el-col :span="8">
              <el-card shadow="always">
                <div slot="header" class="clearfix">
                  <el-row style="padding: 3px 0px">
                    <el-col :span="12">
                      <div class="so_card_title" style="padding-top: 5px">{{ $t("Vendor") }}</div>
                    </el-col>
                    <el-col :span="12">
                      <div style="float: right">
                        <el-button type="primary" v-if="(cur_po.Status=='Draft' )" size="small" icon="el-icon-edit" circle @click="disableVendorInput" v-acl:POEditAddress.enable.show></el-button>
                        <el-button type="success" v-if="(cur_po.Status=='Draft' )" size="small" icon="el-icon-plus" circle @click="addNewVendor" v-acl:POEditAddress.enable.show></el-button>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <!----------------------------------- Vendor form @Draft ----------------------------------->
                <section v-if="(cur_po.Status=='Draft' )" style="margin: 0 20px; height: 500px;"> 
                  <el-row>
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Name") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      <el-autocomplete
                        class="inline-input fred"
                        v-model="cur_vendor_name"
                        value-key="CompanyName"
                        :fetch-suggestions="querySearch"
                        :placeholder="$t('Name')"
                        @select="vendor_changed"
                        @blur="resetCurrentPO"
                      >
                        <!-- <i
                          class="el-icon-edit el-input__icon"
                          slot="suffix"> 
                        </i> -->
                        <template slot-scope="{ item }">
                          <span>{{ item.CompanyName }}</span>
                        </template>
                      </el-autocomplete>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Other Name") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      <!-- {{ cur_po.PurchaseFOtherName }} -->
                      <b-input
                        class="form-control"
                        :placeholder="$t('Other Name')"
                        :disabled="disable_vendor_input"
                        v-model="cur_po.PurchaseFOtherName"
                      />
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Contact") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      <!-- {{ cur_po.PurchaseFContact }} -->
                      <b-input
                        class="form-control"
                        :placeholder="$t('Contact')"
                        :disabled="disable_vendor_input"
                        v-model="cur_po.PurchaseFContact"
                      />
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Tel") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      <!-- {{ cur_po.PurchaseFTEL }}
                      {{ cur_po.PurchaseFExt ? "- " + cur_po.PurchaseFExt : "" }} -->
                      <b-input
                        class="form-control"
                        :placeholder="$t('Tel')"
                        :disabled="disable_vendor_input"
                        v-model="cur_po.PurchaseFTEL"
                      />
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Fax") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      <!-- {{ cur_po.PurchaseFFAX }} -->
                      <b-input
                        class="form-control"
                        :placeholder="$t('Fax')"
                        :disabled="disable_vendor_input"
                        v-model="cur_po.PurchaseFFAX"
                      />
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Address1") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      <b-input
                        class="form-control"
                        :placeholder="$t('Address1')"
                        :disabled="disable_vendor_input"
                        v-model="cur_po.PurchaseFAddress1"
                      />
                    </el-col>
                  </el-row>
                  <el-row >
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Address2") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      <b-input
                        class="form-control"
                        :placeholder="$t('Address2')"
                        :disabled="disable_vendor_input"
                        v-model="cur_po.PurchaseFAddress2"
                      />
                    </el-col>
                  </el-row>
                  <el-row >
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Postal Code") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      <b-input
                        class="form-control"
                        :placeholder="$t('Postal Code')"
                        :disabled="disable_vendor_input"
                        v-model="cur_po.PurchaseFZIP"
                      />
                    </el-col>
                  </el-row>
                  <el-row >
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Country") + " : " }}
                    </el-col>
                    <el-col :span="8">
                      <el-select
                        class="el-dropdown2"
                        v-model="cur_po.PurchaseFCountryIDn"
                        :disabled="disable_vendor_input"
                      >
                        <el-option
                          v-for="(country, IDn) in country_list"
                          :key="IDn"
                          :label="country.Country"
                          :value="parseInt(IDn)"
                          @click.native="changed_country(parseInt(IDn), 1)"
                        />
                      </el-select>
                    </el-col>
                  </el-row>
                  <el-row >
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Province") + " : " }}
                    </el-col>
                    <el-col :span="8">
                      <el-select
                        class="el-dropdown2"
                        v-model="cur_po.PurchaseFProvinceIDn"
                        :disabled="disable_vendor_input"
                      >
                        <el-option
                          v-for="(province, idx) in cur_vendor_provincelist"
                          :key="idx"
                          :label="province.Province"
                          :value="parseInt(province.IDn)"
                        >
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                  <el-row >
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("City") + " : " }}
                    </el-col>
                    <el-col :span="8">
                      <base-input >
                        <InputAutocomplete
                          :items="city_list"
                          :initval="cur_po.PurchaseFCity"
                          :placeholder="$t('City')"
                          :disable="disable_vendor_input"
                          :key="cur_po.PurchaseFCity"
                          v-model="cur_po.PurchaseFCity"
                          @input="setinput_city(cur_po.PurchaseFCity, 1)"
                          class="fred"
                        >
                        </InputAutocomplete>
                      </base-input>
                    </el-col>
                  </el-row>
                  <a href="javascript:;" @click="manageVendorAddress" style="float: right; margin-top: 0px; margin-bottom: 10px;" v-if="hasUtilityAuth('POEditAddress')"><u>Manage Vendor Address</u></a>
                </section>
                <!----------------------------------- Vendor form @else ----------------------------------->
                <section  v-else>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t("Name") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      {{ cur_po.PurchaseFName }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t("Other Name") + " : " }}
                    </el-col>
                    <el-col :span="16" >
                      {{ cur_po.PurchaseFOtherName }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t("Contact") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      {{ cur_po.PurchaseFContact }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t("Tel") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      {{ cur_po.PurchaseFTEL }}
                      {{ cur_po.PurchaseFExt ? "- " + cur_po.PurchaseFExt : "" }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t("Fax") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      {{ cur_po.PurchaseFFAX }}
                    </el-col>
                  </el-row>
                  <div v-if="cur_po.PurchaseFAddressIDn != 0">
                    <el-row>
                      <el-col :span="8" class="text-right pr-3">
                        {{ $t("Address") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <div>{{ cur_po.PurchaseFAddress1 }}</div>
                          <div>{{ cur_po.PurchaseFAddress2 }}</div>
                          <div>
                            {{ cur_po.PurchaseFCity }},
                            {{ cur_po.PurchaseFProvinceIDn > 0 ? province_list[cur_po.PurchaseFProvinceIDn].Province : "" }}
                            {{ cur_po.PurchaseFCountryIDn > 0 ? country_list[cur_po.PurchaseFCountryIDn].Country : "" }}
                            {{ cur_po.PurchaseFZIP }}
                          </div>
                      </el-col>
                    </el-row>
                  </div>
                </section>
              </el-card>
            </el-col>
            <!-------------------------------------- 選擇Billing Address-------------------------------------->
            <el-col :span="8">
              <el-card shadow="always">
                <div slot="header" class="clearfix">
                  <el-row style="padding: 3px 0px">
                    <el-col :span="12">
                      <div class="so_card_title" style="padding-top: 5px">
                        {{ $t("Billing Address") }}
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div style="float: right">
                        <el-button type="primary" v-if="false" icon="el-icon-edit" circle @click="disableBillingInput" v-acl:POEditAddress.enable.show></el-button>
                        <el-button type="success" v-if="false" icon="el-icon-plus" circle @click="addNewBilling" v-acl:POEditAddress.enable.show></el-button>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div>
                  <!----------------------------------- Billing address form @Draft ----------------------------------->
                  <section v-if="(cur_po.Status=='Draft')" style="margin: 0 20px; height: 500px; margin-bottom: 29px;"> 
                    <el-row>
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Name") + " : " }}
                      </el-col>
                      <el-col :span="16" v-if="store_list.length >= 1">
                        <el-select
                          class="el-dropdown2"
                          v-model="cur_po.LocationID"
                          :placeholder="$t('Store')"
                        >
                          <el-option
                            v-for="store in store_list"
                            :key="store.LocationID"
                            :value="store.LocationID"
                            :label="store.LocationName"
                            @click.native="store_billing_changed"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Contact") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <b-input
                        class="form-control"
                        :placeholder="$t('Contact')"
                        :disabled="disable_billing_input"
                        v-model="cur_po.ComContact"
                      />
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Tel") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <b-input
                          class="form-control"
                          :placeholder="$t('Tel')"
                          :disabled="disable_billing_input"
                          v-model="cur_po.ComTEL"
                        />
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Fax") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <b-input
                          class="form-control"
                          :placeholder="$t('Fax')"
                          :disabled="disable_billing_input"
                          v-model="cur_po.ComFAX"
                        />
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Address1") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <b-input
                          class="form-control"
                          :placeholder="$t('Address1')"
                          :disabled="disable_billing_input"
                          v-model="cur_po.ComAddress1"
                        />
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Address2") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <b-input
                          class="form-control"
                          :placeholder="$t('Address2')"
                          :disabled="disable_billing_input"
                          v-model="cur_po.ComAddress2"
                        />
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Postal Code") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <b-input
                          class="form-control"
                          :placeholder="$t('Postal Code')"
                          :disabled="disable_billing_input"
                          v-model="cur_po.ComZIP"
                        />
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Country") + " : " }}
                      </el-col>
                      <el-col :span="8">
                        <el-select
                          class="el-dropdown2"
                          v-model="cur_po.ComCountryIDn"
                          :disabled="disable_billing_input"
                        >
                          <el-option
                            v-for="(country, IDn) in country_list"
                            :key="IDn"
                            :label="country.Country"
                            :value="parseInt(IDn)"
                            @click.native="changed_country(cur_po.ComCountryIDn, 2)"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Province") + " : " }}
                      </el-col>
                      <el-col :span="8">
                        <el-select
                          class="el-dropdown2"
                          v-model="cur_po.ComProvinceIDn"
                          :disabled="disable_billing_input"
                        >
                          <el-option
                            v-for="(province, IDn) in cur_billing_provincelist"
                            :key="IDn"
                            :label="province.Province"
                            :value="parseInt(province.IDn)"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("City") + " : " }}
                      </el-col>
                      <el-col :span="8">
                        <base-input>
                          <InputAutocomplete
                            :key="cur_po.ComCity"
                            :items="city_list"
                            :initval="cur_po.ComCity"
                            :placeholder="$t('City')"
                            :disable="disable_billing_input"
                            v-model="cur_po.ComCity"
                            @input="setinput_city(cur_po.ComCity, 2)"
                            class="fred"
                          ></InputAutocomplete>
                        </base-input>
                      </el-col>
                    </el-row>
                  </section>
                  <!----------------------------------- Billing address form @else ----------------------------------->
                  <section  v-else>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                        {{ $t("Name") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        {{ cur_po.ComTitle }}
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                        {{ $t("Contact") + " : " }}
                      </el-col>
                      <el-col :span="16">
                          {{ cur_po.ComContact }}
                        </el-col>
                      </el-row>
                      <el-row>
                      <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                        {{ $t("Tel") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        {{ cur_po.ComTEL }}
                        {{ cur_po.ComExt ? "- " + cur_po.ComExt : "" }}
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                        {{ $t("Fax") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        {{ cur_po.ComFAX }}
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3">
                        {{ $t("Address") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <div>{{ cur_po.ComAddress1 }}</div>
                        <div>{{ cur_po.ComAddress2 }}</div>
                        <div>
                          {{ cur_po.ComCity }},
                          {{ province_list[cur_po.ComProvinceIDn].Province }}
                          {{ country_list[cur_po.ComCountryIDn].Country }}
                          {{ cur_po.ComZIP }}
                        </div>
                      </el-col>
                    </el-row>
                  </section>
                </div>
              </el-card>
            </el-col>
            <!-------------------------------------- 選擇Shipping Address-------------------------------------->
            <el-col :span="8">
              <el-card shadow="always">
                <div slot="header" class="clearfix">
                  <el-row style="padding: 3px 0px">
                    <el-col :span="12">
                      <div class="so_card_title" style="padding-top: 5px">
                        {{ $t("Shipping Address") }}
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div style="float: right">
                        <el-button type="primary" v-if="false" icon="el-icon-edit" circle @click="disableShippingInput" v-acl:POEditAddress.enable.show></el-button>
                        <el-button type="success" v-if="false" icon="el-icon-plus" circle @click="addNewShipping" v-acl:POEditAddress.enable.show></el-button>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <!----------------------------------- Shipping address form @Draft ----------------------------------->
                <section v-if="(cur_po.Status=='Draft')" style="margin: 0 20px; height: 500px; margin-bottom: 29px;">
                  <el-row v-if="store_list.length >= 1">
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Name") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      <el-select
                        class="el-dropdown2"
                        v-model="cur_po.ShipTLocationID"
                        :placeholder="$t('Store')"
                      >
                        <el-option
                          v-for="store in store_list"
                          :key="store.LocationID"
                          :value="store.LocationID"
                          :label="store.LocationName"
                          @click.native="store_shipping_changed"
                        >
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Contact") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      <b-input
                        class="form-control"
                        :placeholder="$t('Contact')"
                        :disabled="disable_shipping_input"
                        v-model="cur_po.ShipTContact"
                      />
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Tel") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      <b-input
                        class="form-control"
                        :placeholder="$t('Tel')"
                        :disabled="disable_shipping_input"
                        v-model="cur_po.ShipTTEL"
                      />
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Fax") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      <b-input
                        class="form-control"
                        :placeholder="$t('Fax')"
                        :disabled="disable_shipping_input"
                        v-model="cur_po.ShipTFAX"
                      />
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Address1") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      <b-input
                        class="form-control"
                        :placeholder="$t('Address1')"
                        :disabled="disable_shipping_input"
                        v-model="cur_po.ShipTAddress1"
                      />
                    </el-col>
                  </el-row>
                  <el-row >
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Address2") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      <b-input
                        class="form-control"
                        :placeholder="$t('Address2')"
                        :disabled="disable_shipping_input"
                        v-model="cur_po.ShipTAddress2"
                      />
                    </el-col>
                  </el-row>
                  <el-row >
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Postal Code") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      <b-input
                        class="form-control"
                        :placeholder="$t('Postal Code')"
                        :disabled="disable_shipping_input"
                        v-model="cur_po.ShipTZIP"
                      />
                    </el-col>
                  </el-row>
                  <el-row >
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("Country") + " : " }}
                    </el-col>
                    <el-col :span="8">
                      <el-select
                        class="el-dropdown2"
                        v-model="cur_po.ShipTCountryIDn"
                        :disabled="disable_shipping_input"
                      >
                        <el-option
                          v-for="(country, IDn) in country_list"
                          :key="IDn"
                          :label="country.Country"
                          :value="parseInt(IDn)"
                          @click.native="changed_country(cur_po.ShipTCountryIDn, 3)"
                        >
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                  <el-row >
                    <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                      {{ $t("Province") + " : " }}
                    </el-col>
                    <el-col :span="8">
                      <el-select
                        class="el-dropdown2"
                        v-model="cur_po.ShipTProvinceIDn"
                        :disabled="disable_shipping_input"
                      >
                        <el-option
                          v-for="(province, idx) in cur_shipping_provincelist"
                          :key="idx"
                          :label="province.Province"
                          :value="parseInt(province.IDn)"
                        >
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                  <el-row >
                    <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                      {{ $t("City") + " : " }}
                    </el-col>
                    <el-col :span="8">
                      <base-input>
                        <InputAutocomplete
                          :key="cur_po.ShipTCity"
                          :items="city_list"
                          :initval="cur_po.ShipTCity"
                          v-model="cur_po.ShipTCity"
                          :placeholder="$t('City')"
                          :disable="disable_shipping_input"
                          @input="setinput_city(cur_po.ShipTCity, 3)"
                          class="fred"
                        ></InputAutocomplete>
                      </base-input>
                    </el-col>
                  </el-row>
                </section>
                <!----------------------------------- Shipping address form @else ----------------------------------->
                <section  v-else>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                      {{ $t("Name") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      {{ cur_po.ShipTTitle }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                      {{ $t("Contact") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      {{ cur_po.ShipTContact }}
                    </el-col>
                  </el-row>
                  <el-row v-if="cur_po.ShipTLocationID">
                    <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                      {{ $t("Tel") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      {{ cur_po.ShipTTEL }}
                      {{ cur_po.ShipTExt ? "- " + cur_po.ShipTExt : "" }}
                    </el-col>
                  </el-row>
                  <el-row v-if="cur_po.ShipTLocationID">
                    <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                      {{ $t("Fax") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      {{ cur_po.ShipTFAX }}
                    </el-col>
                  </el-row>

                  <el-row v-if="cur_po.ShipTLocationID">
                    <el-col :span="8" class="text-right pr-3">
                        {{ $t("Address") + " : " }}
                      </el-col>
                    <el-col :span="16">
                      <div>{{ cur_po.ShipTAddress1 }}</div>
                      <div>{{ cur_po.ShipTAddress2 }}</div>
                      <div>
                        {{ cur_po.ShipTCity }},
                        {{ province_list[cur_po.ShipTProvinceIDn].Province }}
                        {{ country_list[cur_po.ShipTCountryIDn].Country }}
                        {{ cur_po.ShipTZIP }}
                      </div>
                    </el-col>
                  </el-row>
                </section>
              </el-card>
            </el-col>
          </el-row>
        </el-collapse-item>
        <!-------------------------------------- 購買訂單詳細 -------------------------------------->
        <el-collapse-item
          class="collapseheader podetail"
          :title="$t('PO Detail')"
          name="PODetail"
          @click.native="calculate_el_select_width2()"
        >
          <el-row :gutter="10" style="padding: 0px 10px">
            <el-col :span="12">
              <b-input-group class="mt-2" :prepend="$t('PO Number') + ':'">
                <b-input 
                  class="form-control fred2" 
                  style="height: 46px" 
                  v-model="cur_po.OrderID" 
                  disabled />
              </b-input-group>
            </el-col>
            <el-col :span="12">
              <b-input-group class="mt-2" :prepend="$t('Term') + ':'">
                <el-select
                  class="el-dropdown3"
                  v-model="cur_po.PayPeriodIDn"
                  :disabled="cur_po.Status == 'Draft'? false : true"
                >
                  <el-option
                    v-for="(period, idx) in pay_period_list"
                    :key="idx"
                    :label="period.PayPeriod"
                    :value="parseInt(period.IDn)"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="padding: 0px 10px">
            <el-col :span="12">
              <b-input-group class="mt-2" :prepend="$t('Order Date') + ':'">
                <flat-pickr
                  v-model="cur_po.OrderDate"
                  :config="pickr_config"
                  class="form-control"
                  style="height: 46px"
                  :disabled="cur_po.Status == 'Draft'? false : true"
                >
                </flat-pickr>
              </b-input-group>
            </el-col>
            <el-col :span="12">
              <b-input-group class="mt-2" :prepend="$t('Currency') + ':'">
                <el-select
                  class="el-dropdown3"
                  v-model="cur_po.CurrencyIDn"
                  :disabled="cur_po.Status == 'Draft'? false : true"
                >
                  <el-option
                    v-for="item in currency_List"
                    :key="item.IDn"
                    :label="item.Currency"
                    :value="item.IDn"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="padding: 0px 10px">
            <el-col :span="12">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Arrival Date') + ':'">
                <flat-pickr
                  v-model="cur_po.ArrivalDate"
                  :config="pickr_config"
                  class="form-control"
                  style="height: 46px"
                  :disabled="cur_po.Status == 'Draft'? false : true"
                >
                </flat-pickr>
              </b-input-group>
            </el-col>
          </el-row>
          <hr class="solid mb-2 mt-0" />
          <el-row :gutter="10" style="padding: 0px 10px">
            <el-col :span="8">
              <b-input-group class="mb-2" :prepend="$t('Shipping Method') + ':'">
                <el-select
                  class="el-dropdown4"
                  v-model="cur_po.ShipStatus"
                  :disabled="cur_po.Status == 'Draft'? false : true"
                >
                  <el-option
                    v-for="item in shipping_status_option"
                    :key="item.IDn"
                    :label="item.Status"
                    :value="item.Status"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
            <el-col :span="8">
              <el-row>
                <el-col class="mt-1" :span="8">
                  <span style="float: right; margin-top: 4px; margin-right: 10px;">{{$t('Carrier') + ':'}}</span>
                </el-col>
                <el-col :span="14">
                  <el-autocomplete
                    class="inline-input fred3 carrier_input"
                    v-model="cur_carrier_name"
                    value-key="CarrierName"
                    :fetch-suggestions="querySearch2"
                    :placeholder="$t('Carrier')"
                    :disabled="cur_po.Status == 'Draft'? false : true"
                    @select="selectCarrier"
                    @input.native="resetCarrier"
                  ></el-autocomplete>
                </el-col>
              </el-row>
            </el-col>
            
            <!-- Container table  -->
            <el-col :span="7">
              <el-row v-for="(container, index) in containerList" :key="index">
                <el-col class="mt-1" :span="8">
                  <span style="float: right; margin-top: 4px; margin-right: 10px;">{{$t('Container No') + ':'}}</span>
                </el-col>
                <el-col :span="14">
                  <b-form-input
                    class="form-control fred3 container_input"
                    v-model="containerList[index].ContainerNumber"
                    size="nm"
                    :disabled="cur_po.Status == 'Draft'? false : true"
                  >
                  </b-form-input>
                </el-col>
                <el-col :span="2" v-if="index==0">
                  <i class="el-icon-plus" @click="addContainer()" style="font-size: 1.25rem; padding: 10px"></i>
                </el-col>
                <el-col :span="2" v-if="index!=0">
                  <i class="el-icon-close" @click="removeContainer(index)" style="font-size: 1.25rem; padding: 10px"></i>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-collapse-item>
        <!-- {{ vendor_list }} -->
        <!-------------------------------------- 產品列表 -------------------------------------->
        <el-collapse-item
          class="collapseheader productlist"
          :title="$t('Product List')"
          name="ProductList"
        >
          <el-skeleton :loading="loadingPOInfo" animated>
            <template slot="template">
              <el-skeleton-item
                variant="rect"
                style="width: 100%; height: 300px;"
              />
            </template>
            <b-card>
              <el-row v-if="cur_po.Status == 'Complete' || cur_po.Status == 'Deleted'  ? false : true">
                <el-col :span="12" >
                  <el-button type="primary" @click="add_product" :disabled="orderLock" >{{ $t("All Products") }}</el-button>
                  <el-button type="primary" @click="import_product" :disabled="orderLock">{{ $t("Import") }}</el-button>
                  <el-button type="primary" @click="last_order_product" :disabled="orderLock">{{ $t("Last Order") }}</el-button>
                  <el-switch v-if="cur_po.Status == 'Pending' && !hasCompletedRE" v-model="orderLock" :active-text="$t('Lock')" style="margin-left: 20px"></el-switch>
                  <!-- <el-button type="primary" @click="print_order">{{ $t("Print") }}</el-button> -->
                </el-col>
                <el-col :span="10">
                  <b-input-group prepend="PLU/BarCode/Name: ">
                    <b-form-input
                      v-model="quick_add_input"
                      style="height: 46px"
                      v-on:keydown.enter="quickAdd_enter_keydown_handler"
                      :disabled="orderLock"
                    >
                    </b-form-input>
                  </b-input-group>
                </el-col>
                <el-col :span="2">
                  <el-button type="primary" icon="el-icon-plus" :disabled="orderLock" circle @click="quickAdd" style="margin-left: 30px"></el-button>
                </el-col>
              </el-row>
              <el-table
                border
                v-if="show_listed_products"
                class="table-responsive table product-table mt-2"
                style="width: 100%"
                header-row-class-name="thead-light"
                :cell-style="{ textAlign: 'center' }"
                :data="cur_po_product"
                :style="{ marginBottom: 1.5 + 'rem' }"
                :default-expand-all=true
                :row-class-name="setClassName"
              >
                <el-table-column type="expand" width="1px">
                  <template slot-scope="props" >
                    <b-textarea
                      :placeholder="$t('Remarks')"
                      type="textarea"
                      rows="1"
                      max-rows="1"
                      v-model="props.row.Remarks"
                      style="max-height: 30px; overflow-y: hidden; line-height: 0.6;"
                      no-resize
                      :disabled="cur_po.Status == 'Complete' || cur_po.Status == 'Deleted' || orderLock ? true : false"
                    >
                    </b-textarea>
                  </template>
                </el-table-column>
                <el-table-column prop="PLU" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("PLU") }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="Barcode" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Barcode") }}</div>
                  </template>
                </el-table-column>
                <el-table-column class="p-0" min-width="150px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Product Name") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    <div v-if="row.OrderQty>0">
                    {{ row.Name2 && po_show_name2 ? row.Name1 + "(" + row.Name2 + ")" : row.Name1}}
                    </div>
                    <div v-else style="text-decoration: line-through">
                    {{ row.Name2 && po_show_name2 ? row.Name1 + "(" + row.Name2 + ")" : row.Name1}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="Unit" class="p-0" min-width="40px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Unit") }}</div>
                  </template>
                </el-table-column>
                <!-- <el-table-column v-if="!cur_is_return" prop="OriginalPrice" class="p-0" min-width="100px">
                  <template slot="header">
                    <div v-if="!cur_is_return" class="w-100 p-0 text-center">{{ $t("Price") }}</div>
                  </template>
                </el-table-column> -->
                <el-table-column class="p-0" min-width="120px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Unit Cost") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    <el-input size="mini" v-model="row.UnitCost" @keydown.native="number_input(row.UnitCost)" @change="changingUnitCost(row)" :disabled="cur_po.Status == 'Complete' || cur_po.Status == 'Deleted' || orderLock? true : false">
                      <template slot="prepend">&emsp;$&emsp;</template>
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column v-if="!cur_is_return && !cur_is_receive" prop="VendorPLU" class="p-0" min-width="120px">
                  <template slot="header">
                    <div v-if="!cur_is_return && !cur_is_receive" class="w-100 p-0 text-center">{{ $t("Vendor PLU") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    <el-input v-if="!cur_is_return && !cur_is_receive" size="mini" v-model="row.VendorPLU" :disabled="cur_po.Status == 'Complete' || cur_po.Status == 'Deleted' || orderLock? true : false"></el-input>
                  </template>
                </el-table-column>
                <el-table-column v-if="cur_is_return || cur_is_receive" prop="CurOrderQty" class="p-0" min-width="50px">
                  <template slot="header">
                    <div v-if="cur_is_return || cur_is_receive" class="w-100 p-0 text-center">Cur-{{ $t("Qty") }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="OrderQty" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Qty") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    <el-input size="mini" v-model="row.OrderQty" @keydown.native="number_input(row.OrderQty)" @focus="getRevert(row.OrderQty)" @change="changingOrderQty(row)" :disabled="cur_po.Status == 'Complete' || cur_po.Status == 'Deleted' || orderLock? true : false"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="TotalCost" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Subtotal") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ "$ " + row.TotalCost }}
                  </template>
                </el-table-column>
                <el-table-column v-if="cur_po.Status=='Pending'" prop="PendingQty" class="p-0" min-width="80px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Pending Qty") }}</div>
                  </template>
                </el-table-column>
                <el-table-column class="p-0" min-width="40px">
                  <template slot="header">
                    <!-- <base-button type="primary" size="sm" icon @click="add_product">
                      <span class="btn-inner--icon"><i class="ni ni-fat-add"></i></span>
                      <span class="btn-inner--text"></span>
                    </base-button> -->
                  </template>
                  <template v-slot="scope">
                    <div v-if="scope.row.OrderQty>0 && !cur_is_return" class="btn-inner--icon text-center w-100"  @click="remove_po_product(scope.$index, scope.row)">
                      <i class="ni ni-fat-remove icon-remove"></i>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-row :gutter="6">
                <el-col :span="12">
                  <b-textarea
                    :placeholder="$t('Remarks')"
                    type="textarea"
                    rows="6"
                    max-rows="8"
                    v-model="cur_po.Remarks"
                    :disabled="cur_po.Status == 'Complete' || cur_po.Status == 'Deleted' || orderLock ? true : false"
                  >
                  </b-textarea>
                </el-col>
                <el-col :span="7" :offset="3">
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Subtotal') + ' : ' }}
                    </el-col>
                    <el-col :span="16">
                      {{ parseFloat(cur_po.SubTotal).toFixed(2) }}
                    </el-col>
                  </el-row>
                  <el-row v-for="(tax, idx) in cur_po_tax" :key="idx">
                    <el-col :span="8" class="text-right pr-3">
                      {{ tax.TaxType + ' : ' }}
                    </el-col>
                    <el-col :span="16">
                      {{ parseFloat(tax.TaxAmount).toFixed(2) }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Other Fee') + ' : ' }}
                    </el-col>
                    <el-col :span="8" v-if="format_Fee()">
                      <el-input
                        id="other_fees_textbox"
                        size="mini"
                        type="number"
                        v-model="cur_po.OtherFees"
                        @keydown.native="number_input(cur_po.OtherFees)"
                        @change="changingOtherFees(cur_po)"
                        @focus="highlight_text('other_fees_textbox')"
                        @blur="check_input()"
                        :disabled="cur_po.Status == 'Complete' || cur_po.Status == 'Deleted' || orderLock ? true : false"
                      />
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Shipping Fee') + ' : ' }}
                    </el-col>
                    <el-col :span="8" v-if="format_Fee()">
                      <el-input
                        id="ship_fee_textbox"
                        size="mini"
                        type="number"
                        v-model="cur_po.ShipFee"
                        @keydown.native="number_input(cur_po.ShipFee)"
                        @change="changingShippingFees(cur_po)"
                        @focus="highlight_text('ship_fee_textbox')"
                        @blur="check_input()"
                        :disabled="cur_po.Status == 'Complete' || cur_po.Status == 'Deleted' || orderLock ? true : false"
                      />
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Total') + ' : ' }}
                    </el-col>
                    <el-col :span="16">
                      {{ parseFloat(cur_po.TotalAmount).toFixed(2) }}
                    </el-col>
                  </el-row>
                  <el-row v-if="!cur_is_return && !cur_is_receive">
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Paid Amount') + ' : ' }}
                    </el-col>
                    <el-col :span="16">
                      {{ parseFloat(cur_po.PaidAmount).toFixed(2) }}
                    </el-col>
                  </el-row>
                  <el-row v-if="cur_po.POIDn">
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Returned') + ' : ' }}
                    </el-col>
                    <el-col :span="16">
                      {{ parseFloat(cur_po.ReturnedAmount).toFixed(2) }}
                    </el-col>
                  </el-row>
                  <el-row v-if="cur_po.POIDn">
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Credited') + ' : ' }}
                    </el-col>
                    <el-col :span="16">
                      {{ parseFloat(cur_po.CreditedAmount).toFixed(2) }}
                    </el-col>
                  </el-row>

                  <el-row v-if="!cur_is_return && !cur_is_receive">
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Balance') + ' : ' }}
                    </el-col>
                    <el-col :span="16">
                      {{ parseFloat(cur_po.Balance).toFixed(2) }}
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </b-card>
          </el-skeleton>
        </el-collapse-item>
      </el-collapse>
      <!-------------------------------------- activeCollapse2 -------------------------------------->
      <el-collapse v-model="activeCollapse2" >
        <!-------------------------------------- 接收 -------------------------------------->
        <!-- <div @click="fakeLoading('loadingReceiving', 300)"> -->
          <el-collapse-item
            class="collapseheader"
            :title="$t('Receive')"
            name="Receive"
            v-if="cur_po.Status!=='Draft' && cur_po.Status!=='Deleted'"
          >
            <el-skeleton :loading="loadingReceiving" animated>
              <template slot="template">
                <el-skeleton-item
                  variant="rect"
                  style="width: 100%; height: 200px;"
                />
              </template>
              <el-table
                class="table-responsive table product-table mt-2"
                style="width: 100%"
                header-row-class-name="thead-light"
                :data="cur_po_receive_completedOnly"
                :cell-style="{ textAlign: 'center' }"
                :style="{ marginBottom: 1.5 + 'rem' }"  
              >
                <el-table-column type="expand" class="p-0" min-width="100px">
                  <template slot-scope="props">
                    <card style="margin-left: 0.1rem">
                      <el-table
                        class="table-responsive table product-table mt-2"
                        style="width: 100%"
                        header-row-class-name="thead-light"
                        :data="props.row.Products"
                        :cell-style="{ textAlign: 'center' }"
                        :header-cell-style="{ textAlign: 'center' }"
                        :style="{ marginBottom: 1.5 + 'rem' }"
                      >
                        <el-table-column label="Barcode" prop="Barcode" class="p-0" min-width="100px">
                        </el-table-column>
                        <el-table-column label="Name1" prop="Name1" class="p-0" min-width="100px">
                        </el-table-column>
                        <el-table-column v-if="po_show_name2" label="Name2" prop="Name2" class="p-0" min-width="100px">
                        </el-table-column>
                        <el-table-column label="Cost" prop="UnitCost" class="p-0" min-width="100px">
                          <template v-slot="{ row }">
                            {{ "$ " + row.UnitCost }}
                          </template>
                        </el-table-column>
                        <el-table-column label="Qty" prop="ReceivedQty" class="p-0" min-width="100px">
                        </el-table-column>
                        <el-table-column label="SubTotal" prop="TotalCost" class="p-0" min-width="100px">
                          <template v-slot="{ row }">
                            {{ "$ " + row.TotalCost }}
                          </template>
                        </el-table-column>
                        <el-table-column label="Tax" prop="TotalTax" class="p-0" min-width="100px">
                          <template v-slot="{ row }">
                            {{ "$ " + row.TotalTax }}
                          </template>
                        </el-table-column>
                        <el-table-column label="Total" prop="Total" class="p-0" min-width="100px">
                          <template v-slot="{ row }">
                            {{ "$ " + row.Total }}
                          </template>
                        </el-table-column>
                      </el-table>
                      <!-- <hr class="hr-text" data-content="★"> -->
                      <hr class="hr-text" :data-content="$t('END OF ORDER')" >
                    </card>
                  </template>
                </el-table-column>
                <el-table-column class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Receive Date") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ row.ReceiveDate ? row.ReceiveDate.substr(0,10) : "" }}
                  </template>
                </el-table-column>
                <el-table-column prop="OrderID" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">OrderID</div>
                  </template>
                </el-table-column>
                <el-table-column prop="POOrderID" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">PO OrderID</div>
                  </template>
                </el-table-column>
                <el-table-column prop="Status" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Status") }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="SubTotal" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Subtotal") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ "$ " + row.SubTotal }}
                  </template>
                </el-table-column>
                <el-table-column prop="TotalTax" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Tax") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ "$ " + row.TotalTax }}
                  </template>
                </el-table-column>
                <el-table-column prop="TotalAmount" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ "$ " + row.TotalAmount }}
                  </template>
                </el-table-column>
                <!-- <el-table-column class="p-0" min-width="100px">
                  <template slot="header">
                  </template>

                  <template v-slot="{ row }">
                    <base-button type="primary" size="sm" icon @click="pay_receive(row)">{{ $t("Pay") }}</base-button>
                  </template>
                </el-table-column> -->
              </el-table>
            </el-skeleton>
          </el-collapse-item>
        <!-- </div> -->
        <!-------------------------------------- 退還 -------------------------------------->
        <!-- <div @click="fakeLoading('loadingReturn', 300)"> -->
          <el-collapse-item
            class="collapseheader"
            :title="$t('Returns')"
            name="Return"
            v-if="cur_po.Status == 'Pending' || cur_po.Status == 'Complete' "
          >
            <el-skeleton :loading="loadingReturn" animated>
              <template slot="template">
                <el-skeleton-item
                  variant="rect"
                  style="width: 100%; height: 200px;"
                />
              </template>
              <div style="margin: 16px">
                <el-table
                  class="table-responsive table product-table mt-2"
                  style="width: 100%"
                  header-row-class-name="thead-light"
                  :data="cur_po_return"
                  :cell-style="{ textAlign: 'center'}"
                  :header-row-style="{ textAlign: 'center'}"
                  :style="{ marginBottom: 1.5 + 'rem' }"
                >
                <!-- row-key="id" -->
                  <el-table-column class="p-0" min-width="100px">
                    <template slot="header">
                      <div class="w-100 p-0 text-center">{{ $t("Return Date") }}</div>
                    </template>
                    <template v-slot="{ row }">
                      {{ row.ReturnDate ? row.ReturnDate.substr(0,10) : "" }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="OrderID" class="p-0" min-width="100px">
                    <template slot="header">
                      <div class="w-100 p-0 text-center">OrderID</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="POOrderID" class="p-0" min-width="100px">
                    <template slot="header">
                      <div class="w-100 p-0 text-center">PO OrderID</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="Status" class="p-0" min-width="100px">
                    <template slot="header">
                      <div class="w-100 p-0 text-center">{{ $t("Status") }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="SubTotal" class="p-0" min-width="100px">
                    <template slot="header">
                      <div class="w-100 p-0 text-center">{{ $t("Subtotal") }}</div>
                    </template>
                    <template v-slot="{ row }">
                      {{ "$ " + row.SubTotal }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="TotalTax" class="p-0" min-width="100px">
                    <template slot="header">
                      <div class="w-100 p-0 text-center">{{ $t("Tax") }}</div>
                    </template>
                    <template v-slot="{ row }">
                      {{ "$ " + row.TotalTax }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="TotalAmount" class="p-0" min-width="100px">
                    <template slot="header">
                      <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                    </template>
                    <template v-slot="{ row }">
                      {{ "$ " + row.TotalAmount }}
                    </template>
                  </el-table-column>
                  <el-table-column class="p-0" min-width="80px">
                    <template slot="header">
                      <div style="text-align: center">
                        <el-button class="btn-inner--icon" 
                          type="warning"
                          plain
                          @click="init_return('new')"
                          v-acl:POReturn.enable.show>
                          <i class="el-icon-plus" style="font-size: 1.25rem"></i>{{ "&nbsp;New" }}
                        </el-button>
                      </div>
                    </template>
                    <template v-slot="{ row }">
                      <div v-if="row.Status==='Draft'">
                        <el-button class="btn-inner--icon"
                          type="primary"
                          plain
                          @click="init_return('edit', row)"
                          v-acl:POReturn.enable.show>
                          <i class="el-icon-edit" style="font-size: 1.25rem"></i>{{ "&nbsp;&nbsp;Edit" }}
                        </el-button>
                      </div>
                      <div v-else>
                        <el-button class="btn-inner--icon"
                          type="info"
                          plain
                          @click="init_return('complete', row)"
                          v-acl:POReturn.enable.show>
                          <i class="el-icon-s-fold" style="font-size: 1.25rem"></i>{{ "&nbsp;View" }}
                        </el-button>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-skeleton>
          </el-collapse-item>
        <!-- </div> -->
        <!-------------------------------------- 付款 -------------------------------------->
        <el-collapse-item
          class="collapseheader"
          :title="$t('Payment')"
          name="Payment"
          v-if="(cur_po.Status=='Pending' || cur_po.Status=='Complete' || cur_po.Status=='Deleted') && hasUtilityAuth('POPayment')"
        >
          <!---------------------- payment of Payment Section ------------------------>
          <div style="margin: 16px;">
            <div style="text-align: left">{{ $t("Vendor Credit") }} : {{ parseFloat(vendor_credit).toFixed(2) }}</div>
            <div style="text-align: right">{{ $t("Order Total") }} : {{ parseFloat(cur_po.TotalAmount).toFixed(2) }}</div>
            <div style="text-align: right">{{ $t("Paid Amount") }} : {{ parseFloat(cur_po.PaidAmount).toFixed(2) }}</div>
            <div style="text-align: right">{{ $t("Returned") }} : {{ parseFloat(cur_po.ReturnedAmount).toFixed(2) }}</div>
            <div style="text-align: right">{{ $t("Credited") }} : {{ parseFloat(cur_po.CreditedAmount).toFixed(2) }}</div>
            <div style="text-align: right">{{ $t("Balance") }} : {{ parseFloat(cur_po.Balance).toFixed(2) }}</div>
            
            <div>Payment</div>
            <el-table
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_po_payment"
              @row-click="payment_row_click"
            >
              <el-table-column
                label="Payment Type"
                prop="PayType"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Payment Type") }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Payment Method"
                prop="PaymentMethodID"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Payment Method") }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Paid Amount"
                prop="PaymentAmount"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Paid Amount") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="Employee"
                prop="CreateBy"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Employee") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="Remarks"
                prop="Remarks"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Remarks") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="Date"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Date") }}</div>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.CreateDateTime ? scope.row.CreateDateTime.substr(0, 10) : "" }}
                </template>
              </el-table-column>
              <el-table-column class="p-0" min-width="100px">
                <template slot="header">
                  <span v-if="cur_po.Status!='Deleted' && hasUtilityAuth('POPaymentAdd')" class="btn-inner--icon" 
                    @click="init_new_payment()">
                    <i class="ni ni-fat-add icon-add">
                    </i>
                  </span>
                </template>
                <template slot-scope="scope">
                  <span v-if="cur_po.Status!='Deleted' && hasUtilityAuth('POPaymentDelete')" class="btn-inner--icon" 
                    @click="delete_payment_credit(scope.$index, scope.row, 'payment')">
                    <i class="ni ni-fat-remove icon-remove">
                    </i>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!---------------------- credit of Payment Section ------------------------>
          <div style="margin: 16px">
            <div>Credit</div>
            <el-table
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_po_credit"
              @row-click="credit_row_click"
            >
              <el-table-column
                label="Payment Type"
                prop="PayType"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Payment Type") }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Payment Method"
                prop="PaymentMethodID"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Payment Method") }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Paid Amount"
                prop="PaymentAmount"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Paid Amount") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="Employee"
                prop="CreateBy"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Employee") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="Remarks"
                prop="Remarks"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Remarks") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="Date"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Date") }}</div>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.CreateDateTime ? scope.row.CreateDateTime.substr(0, 10) : "" }}
                </template>
              </el-table-column>
              <!-- <el-table-column class="p-0" min-width="100px">
                <template slot="header">
                  <span class="btn-inner--icon" 
                    @click="init_new_credit()">
                    <i class="ni ni-fat-add icon-add">
                    </i>
                  </span>
                </template>
                <template slot-scope="scope">
                  <span class="btn-inner--icon" 
                    @click="delete_payment_credit(scope.$index, scope.row, 'credit')">
                    <i class="ni ni-fat-remove icon-remove">
                    </i>
                  </span>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
        </el-collapse-item>
      </el-collapse>

      <!-------------------------------------- PO訂單詳情分頁 Footer -------------------------------------->
      <b-card v-if="currpage == 'edit'">
        <!-------------------------------------- PO訂單詳情分頁 Draft buttons-------------------------------------->
        <div v-if="cur_po.Status == 'Draft'" class="text-right">
          <el-button type="primary" v-if="cur_po.POIDn>0" @click="print_order('PO')">
            {{ $t("Print PO") }}
          </el-button>
          <el-button type="primary" @click="submit_click('Pending')">
            {{ $t("Pending") }}
          </el-button>
          <el-button type="primary" @click="submit_click('Draft')">
            {{ $t("Save Change") }}
          </el-button>
          <el-button type="primary" v-if="cur_po.POIDn>0" @click="submit_click('Deleted')" v-acl:DeletePO.enable.show>
            {{ $t("Delete") }}
          </el-button>
          <el-button type="primary" @click="cancel_click()">
            {{ $t("Back") }}
          </el-button>
        </div>
        <!-------------------------------------- PO訂單詳情分頁 Pending buttons-------------------------------------->
        <div v-if="cur_po.Status == 'Pending'" class="text-right">
          <span v-if="cur_is_receive">
            <el-button type="primary" @click="submit_click('Receive')">
              {{ $t("Receive") }}
            </el-button>
            <el-button type="primary" @click="cancel_click">
              {{ $t("Back") }}
            </el-button>
          </span>
          <span v-else-if="cur_is_return">
            <el-button type="primary" @click="submit_click('Return')" v-acl:POReturn.enable.show>
              {{ $t("Return") }}
            </el-button>
            <el-button type="primary" @click="cancel_click">
              {{ $t("Back") }}
            </el-button>
          </span>
          <span v-else>
            <el-button type="primary" v-if="!hasCompletedRE" @click="submit_click('Draft')">
              {{ $t("Rollback") }}
            </el-button>
            <el-button type="primary" v-if="!orderLock" @click="submit_click('Pending')">
              {{ $t("Save Change") }}
            </el-button>
            <el-button type="primary" v-if="cur_po.POIDn>0" @click="print_order('PO')">
              {{ $t("Print PO") }}
            </el-button>
            <el-button type="primary" @click="manualOverrideComplete()">
              {{ $t("Complete") }}
            </el-button>
            <!-- <el-button type="primary" @click="submit_click('Deleted')">
              {{ $t("Delete") }}
            </el-button> -->
            <el-button type="primary" @click="cancel_click">
              {{ $t("Back") }}
            </el-button>
          </span>
        </div>
        <!-------------------------------------- PO訂單詳情分頁 Complete buttons-------------------------------------->
        <div v-if="cur_po.Status == 'Complete'" class="text-right">

          <span v-if="cur_is_return">
            <el-button type="primary" @click="submit_click('Return')" v-acl:POReturn.enable.show>
              {{ $t("Return") }}
            </el-button>
            <el-button type="primary" @click="cancel_click">
              {{ $t("Back") }}
            </el-button>
          </span>
          <span v-else>
            <el-button type="primary" v-if="cur_po.POIDn>0" @click="print_order('PO')">
              {{ $t("Print PO") }}
            </el-button>
            <el-button type="primary" @click="cancel_click">
              {{ $t("Back") }}
            </el-button>
          </span>
        </div>
        <!-------------------------------------- PO訂單詳情分頁 Deleted buttons-------------------------------------->
        <div v-if="cur_po.Status == 'Deleted'" class="text-right">
          <el-button type="primary" class="ml-3" @click="cancel_click">
            {{ $t("Back") }}
          </el-button>
        </div>
      </b-card>
    </div>

    <!-------------------------------------- 添加產品 modal -------------------------------------->
    <b-modal ref="product-list-modal" :title="$t('Add Product')" id="search_modal" size="xl">
      <el-skeleton :loading="loadingProducts" animated>
        <template slot="template">
          <div
            style="display: flex; align-items: center; justify-items: space-between; margin: 16px 0px; height: 16px;"
          >
            <el-skeleton-item variant="text" style="width: 60%; margin-right: 80px; margin-left: 100px;" />
            <el-skeleton-item variant="text" style="width: 18%; " />
          </div>
          <el-skeleton-item
            variant="rect"
            style="width: 100%; height: 300px;"
          />
          <div
            style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;"
          >
            <el-skeleton-item variant="text" style="width: 15%; margin-right: 16px;" />
            <el-skeleton-item variant="text" style="width: 45%; margin-right: 16px;" />
            <el-skeleton-item variant="text" style="width: 5%; margin-right: 16px;" />
            <el-skeleton-item variant="text" style="width: 10%; margin-right: 16px;" />
            <el-skeleton-item variant="text" style="width: 10%; margin-right: 16px;" />
            <el-skeleton-item variant="text" style="width: 10%; margin-right: 16px;" />
          </div>
        </template>
        <el-row >
          <el-col :span="19">
            <div style="display: flex; justify-content: center; margin-bottom: 10px; max-height: 150px;">
              <el-cascader
                ref="product_cascader"
                :options="cascader_options"
                :props="props"
                v-model="cascader_selected"
                @change="cascader_selection_change"
                clearable
                collapse-tags
                :placeholder=" $t('Department') + ' / ' + $t('Category') + ' / ' + $t('Product')">
              </el-cascader>
            </div>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="init_new_product">{{ $t("Create New Product") }}</el-button>
          </el-col>
        </el-row>
        <form>
          <section
            v-if="cur_listed_products.length > 0"
            class="search_product_section"
          >
            <!-- && show_listed_products -->
            <el-table
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              height="320"
              header-row-class-name="thead-light"
              :data="cur_listed_products"
              v-bind:cell-style="isNoData ? hideCell: showCell"
            >
            <!-- @row-click="add_order_product" -->
              <el-table-column                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
                min-width="10%"
                prop="PLU"
              >
                <template v-slot:header>
                  {{$t("PLU")}}
                  <b-input
                    v-model="pro_search_plu"
                    size="sm"
                    @input="delay_search_listed_products"
                  />
                </template>
              </el-table-column>
              <el-table-column
                min-width="20%"
                prop="Name1"
              >
                <template v-slot:header>
                  {{$t("Name")}}
                  <b-input
                    v-model="pro_search_name"
                    size="sm"
                    @input="delay_search_listed_products"
                  />
                </template>
              </el-table-column>
              <!-- <el-table-column
                v-if="po_show_name2"
                min-width="20%"
                label="Name2"
                prop="Name2"
              ></el-table-column> -->
              <el-table-column
                min-width="20%"
                prop="Description1"
              >
                <template v-slot:header>
                  {{$t("Description")}}
                  <b-input
                    v-model="pro_search_description"
                    size="sm"
                    @input="delay_search_listed_products"
                    />
                </template>
              </el-table-column>
              <!-- <el-table-column
                min-width="25%"
                label="Remarks"
                prop="Remarks"
              ></el-table-column> -->
              <el-table-column
                min-width="5%"
                prop="Unit"
              >
                <template v-slot:header>
                  {{$t("Unit")}}
                  <b-input
                    v-model="pro_search_unit"
                    size="sm"
                    @input="delay_search_listed_products"
                  />
                </template>
              </el-table-column>
              <el-table-column
                min-width="10%"
                prop="StoreQty"
              >
                <template v-slot:header>
                  {{$t("Quantity")}}
                  <b-input
                    v-model="pro_search_qty"
                    size="sm"
                    @input="delay_search_listed_products"
                    />
                </template>
              </el-table-column>
              <el-table-column
                min-width="10%"
                prop="OnHoldQty"
              >
                <template v-slot:header>
                  {{$t("On Hold")}}
                  <b-input
                    v-model="pro_search_onhold"
                    size="sm"
                    @input="delay_search_listed_products"
                    />
                </template>
              </el-table-column>
              <el-table-column
                min-width="10%"
                prop="OnOrderQty"
              >
                <template v-slot:header>
                  {{$t("On Order")}}
                  <b-input
                    v-model="pro_search_onorder"
                    size="sm"
                    @input="delay_search_listed_products"
                    />
                </template>
              </el-table-column>
              <el-table-column min-width="5%">
                <!-- <template>
                    <span class="btn-inner--icon">
                      <i class="ni ni-fat-add icon-add"></i>
                    </span>
                </template> -->
                
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    @click="add_order_product(scope.$index, scope.row)"><i class="ni ni-fat-add icon-add" style="font-size: 1.25rem"></i>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </section>
          <!-- product search and add -->
          <el-row :gutter="5">
            <el-col :span="4">
              <div class="product_head">PLU</div>
            </el-col>
            <el-col :span="10">
              <div class="product_head">Name</div>
            </el-col>
            <el-col :span="2">
              <div class="product_head">Cost</div>
            </el-col>
            <el-col :span="3">
              <div class="product_head">Quantity</div>
            </el-col>
            <el-col :span="2">
              <div class="product_head">Subtotal</div>
            </el-col>
            <el-col :span="3">
              <div class="product_head" style="text-align: center;">Action</div>
            </el-col>
          </el-row>
          <div style="overflow-y: auto; overflow-x: hidden; height: 8rem;">
            <el-row
              :gutter="2"
              v-for="(prod, idx) in selected_product_list"
              :key="idx"
              class="product_row"
            >
              <el-col :span="4">
                <div>{{ prod.PLU != "" ? prod.PLU : "&nbsp;"}}</div>
              </el-col>
              <el-col :span="10">
                <div>{{ prod.Name1 }} {{prod.Name2 && po_show_name2 ? " - " + prod.Name2 : ""}}</div>
              </el-col>
              <el-col :span="2">
                <div> {{ prod.UnitCost }} </div>
              </el-col>
              <el-col :span="3">
                <div>
                  <el-input v-model="prod.OrderQty" size="mini" style="width: 75%" @change="calculate_product_total(prod)"></el-input>
                </div>
              </el-col>
              <el-col :span="2">
                <div> {{ prod.TotalCost == 0 ? prod.UnitCost * prod.OrderQty : prod.TotalCost}} </div>
              </el-col>
              <el-col :span="3">
                <div class="btn-inner--icon text-center w-100"  @click="remove_product(prod, idx)">
                  <i class="ni ni-fat-remove icon-remove"></i>
                </div>
              </el-col>
            </el-row>
          </div>
        </form>
      </el-skeleton>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-primary"
          @click="submit_product_selection"
        >
          Add
        </button>
        <button type="button" class="btn btn-secondary" @click="close_my_modal">
          Close
        </button>
      </template>
      
    </b-modal>

    <!-------------------------------------- 導入 modal -------------------------------------->
    <b-modal ref="import-list-modal" :title="$t('Import')" id="import_modal">
      <b-alert v-if="import_file_warning" show dismissible variant="warning">
        <span class="alert-text"><strong>Warning!</strong> {{ import_file_warning }}</span>
      </b-alert>
      <section>
        <input type="file" @change="on_import_change" />
        <xlsx-read :file="import_file" @parsed="import_file_parsed">
          <!-- <xlsx-json>
            <template #default="{collection}">
              <div>
                {{ collection }}
              </div>
            </template>
          </xlsx-json> -->
        </xlsx-read>
      </section>
      <xlsx-workbook>
        <xlsx-sheet
          :collection="downloadsheet.data"
          :sheet-name="downloadsheet.name"
        />
        <xlsx-download filename="po_import_template.xlsx" disable-wrapper-click>
          <template #default="{download}">
            <el-button
              type="primary"
              class="mt-4"
              icon="el-icon-download"
              size="small"
              @click="download"
              >Download Import Template</el-button
            >
          </template>
        </xlsx-download>
      </xlsx-workbook>
      <template #modal-footer>
        <button type="button" class="btn btn-secondary" @click="close_import_modal">
          Close
        </button>
      </template>
    </b-modal>

    <!-------------------------------------- 修改地址 modal -------------------------------------->
    <!-- <b-modal
      ref="address-modal"
      size="lg"
      title="New Address"
      id="address_modal"
    >
      <section>
        <el-row :gutter="10" style="margin-left: 10px">
          <el-col :span="24">
            <b-input
              type="text"
              v-model="search"
              @input="onChange"
              placeholder="Please input your address"
              autocomplete="off"
            />
            <ul v-show="isOpen" class="autocomplete2-results">
              <li
                v-for="(result, i) in search_results"
                :key="i"
                @click="setResult(result)"
                class="autocomplete2-result"
              >
                {{ result }}
              </li>
            </ul>
          </el-col>
        </el-row>
        <el-row :gutter="10" style="margin-left: 10px">
          <el-col :span="24">
            <base-input :label="$t('Address Name')">
              <b-input
                :placeholder="$t('Address Name')"
                class="form-control"
                v-model="cur_address.Name"
              />
            </base-input>
          </el-col>
          <el-col :span="24">
            <base-input :label="$t('Address Line') + ' 1'">
              <b-input
                :placeholder="$t('Address Line') + ' 1'"
                class="form-control"
                v-model="cur_address.Street1"
              />
            </base-input>
          </el-col>
          <el-col :span="24">
            <base-input :label="$t('Address Line') + ' 2'">
              <b-input
                :placeholder="$t('Address Line') + ' 2'"
                class="form-control"
                v-model="cur_address.Street2"
              />
            </base-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <base-input :label="$t('Country')">
              <select
                class="form-control"
                v-model="cur_address.CountryIDn"
                @change="changed_country(cur_address.CountryIDn, 0)"
              >
                <option
                  v-for="(country, countryIDn) in country_list"
                  :key="countryIDn"
                  :value="countryIDn"
                >
                  {{ country.Country
                  }}
                </option>
              </select>
            </base-input>
          </el-col>
          <el-col :span="6">
            <base-input :label="$t('Province')">
              <select
                class="form-control"
                v-model="cur_address.ProvinceIDn"
                @change="changed_province"
              >
                <option
                  v-for="(province, idx) in cur_provincelist"
                  :key="idx"
                  :value="province.IDn"
                >
                  {{ province.Province
                  }}
                </option>
              </select>
            </base-input>
          </el-col>
          <el-col :span="6">
            <base-input :label="$t('City')">
              <InputAutocomplete
                :key="cur_address.AddressCity"
                :items="city_list"
                :initval="cur_address.AddressCity"
                v-model="cur_address.AddressCity"
                @input="setinput_city(cur_address.AddressCity,0)"
              ></InputAutocomplete>
            </base-input>
          </el-col>
          <el-col :span="6">
            <base-input :label="$t('Postal Code')">
              <b-input
                :placeholder="$t('Postal Code')"
                class="form-control"
                v-model="cur_address.ZIP"
              />
            </base-input>
          </el-col>
        </el-row>
      </section>
      <template #modal-footer>
        <el-button type="primary" @click="cancel_edit_address">
          {{ $t("Cancel") }}
        </el-button>
        <el-button type="primary" @click="submit_address">
          {{ $t("Submit") }}
        </el-button>
      </template>
    </b-modal> -->

    <!-------------------------------------- 添加Vendor modal -------------------------------------->
    <b-modal ref="add-vendor-modal" :title="$t('Add Vendor')" id="search_modal" size="xl" @hidden="resetModal">
      <form>
        <div style="padding: 0 20px" v-loading="loading">
          <el-row>
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Name") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Name')"
                v-model="new_vendor.Name"
                @input="validateInputField"
                aria-describedby="input-live-help input-live-feedback"
                :state="Name1_State"
              />
              <b-form-invalid-feedback id="input-live-feedback">
                Enter vendor's name
              </b-form-invalid-feedback>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Other Name") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Other Name')"
                v-model="new_vendor.OtherName"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Tel") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Tel')"
                v-model="new_vendor.Tel"
                @input="validateInputField"
                aria-describedby="input-live-help input-live-feedback"
                :state="TEL_State"
              />
              <b-form-invalid-feedback id="input-live-feedback">
                Enter valid phone number
              </b-form-invalid-feedback>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Type") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <InputAutocomplete2
                :items="company_typelist"
                :placeholder="$t('Type')"
                :key_field="'IDn'"
                :value_field="'CompanyType'"
                v-model="new_vendor.CompanyType"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Address1") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Address1')"
                v-model="new_vendor.Address1"
                @input="validateInputField"
                aria-describedby="input-live-help input-live-feedback"
                :state="Address1_State"
              />
              <b-form-invalid-feedback id="input-live-feedback">
                Enter address
              </b-form-invalid-feedback>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Address2") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Address2')"
                v-model="new_vendor.Address2"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("City") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <InputAutocomplete
                :items="city_list"
                :placeholder="$t('City')"
                :mandatory="true"
                :state="City_State"
                v-model="new_vendor.City"
              >
              </InputAutocomplete>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Country") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <el-select
                v-model="new_vendor.CountryIDn"
              >
                <el-option
                  v-for="(country, IDn) in country_list"
                  :key="IDn"
                  :label="country.Country"
                  :value="parseInt(IDn)"
                  @click.native="changed_country(parseInt(IDn), 4)"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Province") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <el-select
                v-model="new_vendor.ProvinceIDn"
              >
                <el-option
                  v-for="(province, idx) in new_vendor_provincelist"
                  :key="idx"
                  :label="province.Province"
                  :value="parseInt(province.IDn)"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Postal Code") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Postal Code')"
                v-model="new_vendor.ZIP"
                @input="validateInputField"
                aria-describedby="input-live-help input-live-feedback"
                :state="PostalCode_State"
              />
              <b-form-invalid-feedback id="input-live-feedback">
                Enter postal code
              </b-form-invalid-feedback>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Payment Term") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <InputAutocomplete2
                :items="pay_periodlist"
                :initval="Number(new_vendor.PaymentTermIDn)"
                :placeholder="$t('Payment Term')"
                :key_field="'IDn'"
                :value_field="'PayPeriod'"
                v-model="new_vendor.PaymentTermIDn"
              />
            </el-col>
          </el-row>
          <hr>
          <b style="padding-left: 50px">{{ $t("Contact") }}</b>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("First Name") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('First Name')"
                v-model="new_vendor.Contact.FirstName"
              />
            </el-col>
          </el-row>

          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Last Name") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Last Name')"
                v-model="new_vendor.Contact.LastName"
              />
            </el-col>
          </el-row>
          
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Tel") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Tel')"
                v-model="new_vendor.Contact.Tel"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Email") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Email')"
                v-model="new_vendor.Contact.Email"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Position") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <InputAutocomplete2
                :items="positionlist"
                :placeholder="$t('Position')"
                :key_field="'IDn'"
                :value_field="'PositionName'"
                v-model="new_vendor.Contact.Position"
              >
              </InputAutocomplete2>
            </el-col>
          </el-row>
        </div>
      </form>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-primary"
          @click="validateForm"
        >
          Add
        </button>
        <button type="button" class="btn btn-secondary" @click="close_add_vendor_modal">
          Close
        </button>
      </template>
    </b-modal>

    <!-------------------------------------- 管理Vendor Address modal -------------------------------------->
    <b-modal ref="manage-vendor-address-modal" :title="$t('Manage Vendor Address')" id="search_modal" size="lg">
      <div v-if="(cur_po.Status=='Draft' )">
        <div v-if="cur_po.VendorIDn!=undefined && cur_po.VendorIDn>0">
          <!-- TODO: Might not have address -->
          <div class="card" v-for="(address, index) in vendor_list[cur_po.VendorIDn].Address" :key="index"
            style="width: 75%; margin: 0px auto; "
          >
            <div class="card-body" v-bind:style=" address.IDn==cur_po.PurchaseFAddressIDn ? 'border: 1px solid red;' : 'border: none;' "  @click="select_new_vendor_address(address.IDn)">
              <el-row>
                <el-col :span="22">
                  <b>{{ vendor_list[cur_po.VendorIDn].CompanyName }}</b> 
                  <br>
                  {{ address.Street1 + " " + address.Street2 }}
                  <br>
                  {{ address.AddressCity + " " + address.AddressProvince + " " + address.AddressCountry + " " + address.ZIP }}
                </el-col>
                <el-col :span="2">
                  <el-button v-if="false" type="text" @click="editVendorAddress(address)">{{ $t("Edit") }}</el-button>
                </el-col>
              </el-row>
              
            </div>
          </div>
        </div>
      </div>
      
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-primary"
          @click="select_vendor_address"
        >
          {{ $t("Select") }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_manage_vendor_address_modal">
          {{ $t("Close") }}
        </button>
      </template>
    </b-modal>

    <!-------------------------------------- 編輯Vendor Address modal -------------------------------------->
    <!-- <b-modal ref="edit-vendor-address-modal" :title="$t('Edit Vendor Address')" id="search_modal" size="xl">
      <form>
        <div style="padding: 0 20px" >
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Address1") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Address1')"
                v-model="vendor_address_edit.Address1"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Address2") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Address2')"
                v-model="vendor_address_edit.Address2"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("City") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <InputAutocomplete
                :items="city_list"
                :initval="vendor_address_edit.AddressCity"
                :placeholder="$t('City')"
                v-model="vendor_address_edit.AddressCity"
              >
              </InputAutocomplete>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Province") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <el-select
                v-model="vendor_address_edit.ProvinceIDn"
              >
                <el-option
                  v-for="(province, idx) in cur_vendor_provincelist"
                  :key="idx"
                  :label="province.Province"
                  :value="parseInt(province.IDn)"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Country") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <el-select
                v-model="vendor_address_edit.CountryIDn"
              >
                <el-option
                  v-for="(country, IDn) in country_list"
                  :key="IDn"
                  :label="country.Country"
                  :value="parseInt(IDn)"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Postal Code") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Postal Code')"
                v-model="vendor_address_edit.ZIP"
              />
            </el-col>
          </el-row>
        </div>
      </form>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-primary"
          @click="save_vendor_address"
        >
          {{ $t("Save") }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_edit_vendor_address_modal">
          {{ $t("Close") }}
        </button>
      </template>
    </b-modal> -->

    <!-------------------------------------- 添加Billing modal -------------------------------------->
    <b-modal ref="add-billing-modal" :title="$t('Add Vendor')" id="search_modal" size="xl">
      <form>
        <div style="padding: 0 20px" v-loading="loading">
          
          <hr>
          <b style="padding-left: 50px">{{ $t("Contact") }}</b>
          
        </div>
      </form>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-primary"
        >
          Add
        </button>
        <button type="button" class="btn btn-secondary">
          Close
        </button>
      </template>
    </b-modal>
    
    <!-------------------------------------- add payment dialogue ----------------------------------->
    <b-modal
      ref="add-payment-modal"
      :title="$t('Add Payment')"
      id="add-payment-modal"
      size="lg"
    >
      <div style="margin-right: 30px">
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="8" class="text-right" style="margin-top: 5px">
            {{ $t('Payment Type') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-select
              v-model="cur_payment_edit.PayType"
              size="medium"
            >
              <el-option
                v-for="type in payment_type_list"
                :key="type"
                :value="type"
                :label="type"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="8" class="text-right" style="margin-top: 5px">
            {{ $t('Payment Method') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-select
              v-model="cur_payment_edit.PaymentMethodID"
              size="medium"
            >
              <el-option
                v-for="(method, key) in payment_method_list"
                :key="key"
                :value="key"
                :label="method.Name1"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="8" class="text-right" style="margin-top: 5px">
            {{ $t('Amount') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-input
              v-model="cur_payment_edit.PaymentAmount"
            >
            </el-input>
          </el-col>
        </el-row>
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="8" class="text-right" style="margin-top: 5px">
            {{ $t('Remarks') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-input
              v-model="cur_payment_edit.Remarks"
            >
            </el-input>
          </el-col>
        </el-row>
      </div>
      <template #modal-footer>
        <button type="button" class="btn btn-primary" @click="add_payment">
          {{ $t('Add') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_add_payment_model">
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>

    <!-------------------------------------- add credit dialogue ------------------------------------>
    <b-modal
      ref="add-credit-modal"
      :title="$t('Add Credit')"
      id="add-credit-modal"
      size="lg"
    >
      <div style="margin-right: 30px">
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="8" class="text-right" style="margin-top: 5px">
            {{ $t('Payment Type') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-select
              v-model="cur_credit_edit.PayType"
              size="medium"
            >
              <el-option
                v-for="type in credit_type_list"
                :key="type"
                :value="type"
                :label="type"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="8" class="text-right" style="margin-top: 5px">
            {{ $t('Payment Method') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-select
              v-model="cur_credit_edit.PaymentMethodID"
              size="medium"
            >
              <el-option
                v-for="(method, key) in payment_method_list"
                :key="key"
                :value="key"
                :label="method.Name1"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="8" class="text-right" style="margin-top: 5px">
            {{ $t('Amount') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-input
              v-model="cur_credit_edit.PaymentAmount"
            >
            </el-input>
          </el-col>
        </el-row>
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="8" class="text-right" style="margin-top: 5px">
            {{ $t('Remarks') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-input
              v-model="cur_credit_edit.Remarks"
            >
            </el-input>
          </el-col>
        </el-row>
      </div>
      <template #modal-footer>
        <button type="button" class="btn btn-primary" @click="add_credit">
          {{ $t('Add') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_add_credit_model">
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>

    <!-------------------------------------- 添加退貨記錄 modal -------------------------------------->
    <b-modal ref="add-return-modal" :title="$t('Add Return Order')" size="xl" scrollable >
      <b-card>
        <el-table
          border
          class="table-responsive table product-table mt-2"
          style="width: 100%"
          header-row-class-name="thead-light"
          :cell-style="{ textAlign: 'center' }"
          :data="display_PR_array"
          :style="{ marginBottom: 1.5 + 'rem' }"
        >
          <el-table-column prop="PLU" class="p-0" min-width="50px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("PLU") }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="Barcode" class="p-0" min-width="100px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Barcode") }}</div>
            </template>
          </el-table-column>
          <el-table-column class="p-0" min-width="200px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Product Name") }}</div>
            </template>
            <template v-slot="{ row }">
              {{ row.Name2 && po_show_name2 ? row.Name1 + "(" + row.Name2 + ")" : row.Name1}}
            </template>
          </el-table-column>
          <el-table-column prop="Unit" class="p-0" min-width="50px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Unit") }}</div>
            </template>
          </el-table-column>
          <el-table-column class="p-0" min-width="50px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "S/N" }}</div>
            </template>
            <template v-slot="{ row }">
              <div v-if="row.SerialNumber">
                <i class="el-icon-connection hover_hand red_color" @click="selectSN(row)"></i>
              </div>
              <div v-else>
                {{ "-" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="UnitCost" class="p-0" min-width="110px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "Unit Cost" }}</div>
            </template>
            <template v-slot="{ row }">
              {{ "$ " + row.UnitCost }}
            </template>
          </el-table-column>
          <el-table-column v-if="cur_pr_mode!=='complete'" prop="ReceivedQty" class="p-0" min-width="110px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "Received Qty" }}</div>
            </template>
          </el-table-column>
          <el-table-column class="p-0" min-width="110px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "Return Qty" }}</div>
            </template>
            <template v-slot="{ row }">
              <b-form-input size="sm" v-model="row.ReturnQty" :disabled="cur_pr_mode=='complete'" @change="validateReturnQty(row)"/>
            </template>
          </el-table-column>
          <el-table-column class="p-0" min-width="110px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "Return Subtotal" }}</div>
            </template>
            <template v-slot="{ row }">
              
              <div v-if="row.ReturnQty * Number(row.UnitCost) == 0">
                {{ "-" }}
              </div>
              <div v-else-if="!isNaN(row.ReturnQty * Number(row.UnitCost))">
                {{ "$ " + (row.ReturnQty * Number(row.UnitCost)).toFixed(2) }}
              </div>
              <div v-else>
                {{ "-" }}
              </div>
              
            </template>
          </el-table-column>
        </el-table>
        <div>
          <el-row :gutter="20">
            <el-col :span="5" :offset="16" class="text-right pr-3">
              {{ $t('Subtotal') + ' :&emsp;$' }}
            </el-col>
            <el-col :span="3" style="text-align: right; padding-right: 30px">
              {{ parseFloat(cur_pr_returnSubtotal).toFixed(2) }}
            </el-col>
          </el-row>
          <el-row :gutter="20" v-for="(tax, idx) in cur_pr_returnTaxes" :key="idx">
            <el-col :span="5" :offset="16" class="text-right pr-3">
              {{ tax.TaxType + ' :&emsp;$' }}
            </el-col>
            <el-col :span="3" style="text-align: right; padding-right: 30px">
              {{ parseFloat(tax.TaxAmount).toFixed(2) }}
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="5" :offset="16" class="text-right pr-3">
              {{ $t('Shipping Fee') + ' :&emsp;$' }}
            </el-col>
            <el-col :span="3" style="text-align: right; padding-right: 30px">
              <b-form-input class="text-right" size="sm" v-model="returnShippingFee" :disabled="cur_pr_mode=='complete'" @change="validateReturnShippingFee"/>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="5" :offset="16" class="text-right pr-3">
              {{ $t('Other Fee') + ' :&emsp;$' }}
            </el-col>
            <el-col :span="3" style="text-align: right; padding-right: 30px">
              <b-form-input class="text-right" size="sm" v-model="returnOtherFee" :disabled="cur_pr_mode=='complete'" @change="validateReturnOtherFee"/>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="5" :offset="16" class="text-right pr-3">
              {{ $t('Total') + ' :&emsp;$' }}
            </el-col>
            <el-col :span="3"  style="text-align: right; padding-right: 30px">
              {{ parseFloat(cur_pr_returnTotal).toFixed(2) }}
            </el-col>
          </el-row>
        </div>

      </b-card>
      <template #modal-footer>
        <!--
          Draft         -> Creating a new Draft
          DraftDraft    -> Updating an existing Draft
          Post          -> Posting a new Order
          PostDraft     -> Posting an existing Draft
          EditCompleted -> Editing a completed Order
        -->
        <button type="button" class="btn btn-danger" @click="close_add_return_model">
          {{ $t('Close') }}
        </button>
        <button v-if="cur_pr_mode === 'complete'" type="button" class="btn btn-primary" @click="print_order('PR')">
          {{ $t('Print PR') }}
        </button>
        <button v-if="cur_pr_mode === 'edit'" type="button" class="btn btn-warning" @click="delete_pr()" >
          {{ $t('Delete') }}
        </button>
        <button v-if="cur_pr_mode === 'new'" type="button" class="btn btn-warning" @click="submitPR('Draft')" >
          {{ $t('Save as Draft') }}
        </button>
        <button v-if="cur_pr_mode === 'edit'" type="button" class="btn btn-warning" @click="submitPR('DraftDraft')" >
          {{ $t('Save as Draft') }}
        </button>
        <button v-if="cur_pr_mode === 'new'" type="button" class="btn btn-primary" @click="submitPR('Post')">
          {{ $t('Add') }}
        </button>
        <button v-if="cur_pr_mode === 'edit'" type="button" class="btn btn-primary" @click="submitPR('PostDraft')">
          {{ $t('Add') }}
        </button>
      </template>
    </b-modal>

    <!-------------------------------------- SN select dialogue -------------------------------------->
    <b-modal
      ref="select-sn-modal"
      :title="$t('Input S/N')"
      id="select-sn-modal"
      size="sm"
      scrollable 
    >
      <div>
        <el-row>
          <el-table
            id="selectSNTable"
            ref="selectSNTable"
            class="table-responsive table product-table mt-2"
            style="width: 100%"
            height=250
            header-row-class-name="thead-light"
            :data="display_SN_array"
            v-if="cur_pr_mode !== 'complete'"
          >
            <el-table-column>
              <template slot="header">
                <div style="margin-left: 10px">{{ $t("Serial Number") }}</div>
              </template>
              <template slot-scope="scope" >
                <span style="margin-left: 15px" @click="checkSN(scope.row)"> <i class="el-icon-plus" style="font-size: 1rem"></i> </span>
                <span style="margin-left: 20px"> {{ scope.row }} </span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row>
          <el-table
            id="inputSNTable"
            ref="inputSNTable"
            class="table-responsive table product-table mt-2"
            style="width: 100%"
            height=250
            header-row-class-name="thead-light"
            :data="selectedSN"
          >
            <el-table-column>
              <template slot="header">
                <div style="margin-left: 20px">{{ $t("Selected") }}</div>
              </template>
              <template slot-scope="scope" >
                <span style="margin-left: 15px" v-if='cur_pr_mode !=="complete"' @click="uncheckSN(scope.row)"> <i class="el-icon-close" style="font-size: 1rem"></i> </span>
                <span style="margin-left: 20px"> {{ scope.row }} </span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row>
          {{ selectedSN.length }} / {{parseInt(cur_PR_row.ReturnQty)}}
        </el-row>
      </div>

      <template #modal-footer>
        <button type="button" v-if="cur_pr_mode !== 'complete'" class="btn btn-secondary" @click="submitSelectedSN">
          {{ $t('Submit') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_select_sn_model">
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>

    <!-------------------------------------- 新增產品 modal -------------------------------------->
    <b-modal ref="add-product-modal" :title="$t('Create New Product')" size="xl" scrollable>
      <div v-loading="loadingCreateNewProduct">
        <b-card>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="Department" prop="DepartmentIDn">
                  <el-select
                    class="el-dropdown"
                    v-model="ruleForm.DepartmentIDn"
                    :placeholder="$t('Department') + ' : '"
                  >
                    <el-option v-for="department in full_product_list"
                              :key="department.IDn"
                              :label="department.Name1"
                              :value="department.IDn"
                              @click.native="changed_department">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Category" prop="CategoryIDn">
                  <el-select
                    class="el-dropdown"
                    v-model="ruleForm.CategoryIDn"
                    :placeholder="$t('Category') + ' : '"
                  >
                    <el-option v-for="category in categoryList"
                              :key="category.IDn"
                              :label="category.Name1"
                              :value="category.IDn">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="Name 1" prop="Name1">
                  <el-input v-model="ruleForm.Name1"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Name 2">
                  <el-input v-model="ruleForm.Name2"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="Description 1">
                  <el-input v-model="ruleForm.Description1"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Description 2">
                  <el-input v-model="ruleForm.Description2"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="Serialized" prop="HasSerialNumber">
                  <div class="ml-5">
                    <el-radio-group v-model="ruleForm.HasSerialNumber">
                      <el-radio label="1" class="pr-4">{{ $t("Yes") }}</el-radio>
                      <el-radio label="0" >{{ $t("No") }}</el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Weigh" prop="Weigh">
                  <div class="ml-5">
                    <el-radio-group v-model="ruleForm.Weigh">
                      <el-radio label="1" @change='set_PackageWeightUnit' class="pr-4">{{ $t("Yes") }}</el-radio>
                      <el-radio label="0" @change='reset_PackageWeightUnit'>{{ $t("No") }}</el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="Tax Group" prop="TaxTypeIDn">
                  <el-select
                    class="el-dropdown"
                    v-model="ruleForm.TaxTypeIDn"
                    :placeholder="$t('Tax Group')"
                  >
                    <el-option v-for="(tax, idx) in tax_typelist"
                              :key="idx"
                              :label="tax.Name"
                              :value="tax.IDn">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Scale Unit" prop="PackageWeightUnit" v-if="ruleForm.Weigh!=0">
                  <el-select
                    class="el-dropdown"
                    v-model="ruleForm.PackageWeightUnit"
                    :placeholder="$t('Scale Unit')"
                  >
                    <el-option v-for="unit in weigh_unit_list"
                              :key="unit.IDn"
                              :label="unit.Unit"
                              :value="unit.Unit">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <hr style="margin-top: -5px;">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-row>
                  <el-col :span="7" class="text-right pr-3 pt-1" style="margin-top: 2px">
                    {{ $t("Price") + " A : " }}
                  </el-col>
                  <el-col :span="17">
                    <b-input
                      class="form-control"
                      :placeholder="$t('Price') + ' A'"
                      v-model="ruleForm.APrice"
                      @focus="getRevert(ruleForm.APrice)"
                      lazy-formatter
                      :formatter="formatter"
                    />
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-col :span="7" class="text-right pr-3 pt-1" style="margin-top: 2px">
                    {{ $t("Price") + " B : " }}
                  </el-col>
                  <el-col :span="17">
                    <b-input
                      class="form-control"
                      :placeholder="$t('Price') + ' B'"
                      v-model="ruleForm.BPrice"
                      @focus="getRevert(ruleForm.BPrice)"
                      lazy-formatter
                      :formatter="formatter"
                    />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-row>
                  <el-col :span="7" class="text-right pr-3 pt-1" style="margin-top: 2px">
                    {{ $t("Price") + " C : " }}
                  </el-col>
                  <el-col :span="17">
                    <b-input
                      class="form-control"
                      :placeholder="$t('Price') + ' C'"
                      v-model="ruleForm.CPrice"
                      @focus="getRevert(ruleForm.CPrice)"
                      lazy-formatter
                      :formatter="formatter"
                    />
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-col :span="7" class="text-right pr-3 pt-1" style="margin-top: 2px">
                    {{ $t("Price") + " D : " }}
                  </el-col>
                  <el-col :span="17">
                    <b-input
                      class="form-control"
                      :placeholder="$t('Price') + ' D'"
                      v-model="ruleForm.DPrice"
                      @focus="getRevert(ruleForm.DPrice)"
                      lazy-formatter
                      :formatter="formatter"
                    />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-row>
                  <el-col :span="7" class="text-right pr-3 pt-1" style="margin-top: 2px">
                    {{ $t("Price") + " E : " }}
                  </el-col>
                  <el-col :span="17">
                    <b-input
                      class="form-control"
                      :placeholder="$t('Price') + ' E'"
                      v-model="ruleForm.EPrice"
                      @focus="getRevert(ruleForm.EPrice)"
                      lazy-formatter
                      :formatter="formatter"
                    />
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-col :span="7" class="text-right pr-3 pt-1" style="margin-top: 2px">
                    {{ $t("Price") + " F : " }}
                  </el-col>
                  <el-col :span="17">
                    <b-input
                      class="form-control"
                      :placeholder="$t('Price') + ' F'"
                      v-model="ruleForm.FPrice"
                      @focus="getRevert(ruleForm.FPrice)"
                      lazy-formatter
                      :formatter="formatter"
                    />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-row>
                  <el-col :span="7" class="text-right pr-3 pt-1" style="margin-top: 2px">
                    {{ $t("Price") + " G : " }}
                  </el-col>
                  <el-col :span="17">
                    <b-input
                      class="form-control"
                      :placeholder="$t('Price') + ' G'"
                      v-model="ruleForm.GPrice"
                      @focus="getRevert(ruleForm.GPrice)"
                      lazy-formatter
                      :formatter="formatter"
                    />
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-col :span="7" class="text-right pr-3 pt-1" style="margin-top: 2px">
                    {{ $t("Price") + " H : " }}
                  </el-col>
                  <el-col :span="17">
                    <b-input
                      class="form-control"
                      :placeholder="$t('Price') + ' H'"
                      v-model="ruleForm.HPrice"
                      @focus="getRevert(ruleForm.HPrice)"
                      lazy-formatter
                      :formatter="formatter"
                    />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-row>
                  <el-col :span="7" class="text-right pr-3 pt-1" style="margin-top: 2px">
                    {{ $t("Price") + " I : " }}
                  </el-col>
                  <el-col :span="17">
                    <b-input
                      class="form-control"
                      :placeholder="$t('Price') + ' I'"
                      v-model="ruleForm.IPrice"
                      @focus="getRevert(ruleForm.IPrice)"
                      lazy-formatter
                      :formatter="formatter"
                    />
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-col :span="7" class="text-right pr-3 pt-1" style="margin-top: 2px">
                    {{ $t("Price") + " J : " }}
                  </el-col>
                  <el-col :span="17">
                    <b-input
                      class="form-control"
                      :placeholder="$t('Price') + ' J'"
                      v-model="ruleForm.JPrice"
                      @focus="getRevert(ruleForm.JPrice)"
                      lazy-formatter
                      :formatter="formatter"
                    />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form>
        </b-card>
      </div>
      <template #modal-footer>
        <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('Create') }}</el-button>
        <el-button type="danger" @click="close_add_product_model">{{ $t('Close') }}</el-button>
      </template>
    </b-modal>



  </b-container>
</template>
<script>
import {
  Card,
  Table,
  TableColumn,
  Button,
  Pagination,
  Collapse,
  CollapseItem,
  Row,
  Col,
  Tabs,
  TabPane,
  Radio,
  Select,
  Option,
  OptionGroup,
  Autocomplete,
  Icon,
  Loading,
  Cascader,
  CascaderPanel,
  Skeleton,
  SkeletonItem,
  Form,
  FormItem,
  DatePicker,
  TimePicker,
  Switch,
  CheckboxGroup,
  Checkbox,
  RadioGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "element-ui";
import SearchAutocomplete from "../Components/SearchAutocomplete";
import InputAutocomplete from "../InputAutocomplete";
import InputAutocomplete2 from "../InputAutocomplete2";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import { XlsxRead, XlsxWorkbook, XlsxSheet, XlsxDownload} from "vue-xlsx";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Card.name]: Card,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Pagination.name]: Pagination,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Radio.name]: Radio,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Autocomplete.name]: Autocomplete,
    [flatPicker.name]: flatPicker,
    [Icon.name]: Icon,
    [Loading.name]: Loading,
    [Cascader.name]: Cascader,
    [CascaderPanel.name]: CascaderPanel,
    [Skeleton.name]: Skeleton,
    [SkeletonItem.name]: SkeletonItem,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [DatePicker.name]: DatePicker,
    [TimePicker.name]: TimePicker,
    [Switch.name]: Switch,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [RadioGroup.name]: RadioGroup,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    SearchAutocomplete,
    InputAutocomplete,
    InputAutocomplete2,
    XlsxRead,
    XlsxWorkbook,
    XlsxSheet, 
    XlsxDownload,
    // XlsxJson,
    // AddressInput,
  },
  data() {
    return {
      Name1_State: true,
      TEL_State: true,
      Address1_State: true,
      City_State: true,
      PostalCode_State: true,
      orderLock: true,
      props: {
        // props.
        multiple: true,
        expandTrigger: 'hover',
      },
      ruleForm: {
        IDn: 0,
        ID: 0,
        BaseProductIDn: 0,
        DepartmentIDn: 1,
        CategoryIDn: '',
        CategoryID: "",
        PLU: "",
        MinQty: 1,
        Name1: "",
        Name2: "",
        ShortName: "",
        BtnColor: "#cad1d7",
        SortOrder: "999",
        Remarks: "",
        Description1: "",
        Description2: "",
        Brand: "",
        Barcode: "",
        SKU: "",
        Status: "Active",
        OriginCountry: "",
        Unit: "ea",
        UnitName: "ea",
        Weigh: "0",
        PackL: "1",
        PackM: "1",
        PackS: "1",
        Size: "",
        DimL: "0",
        DimM: "0",
        DimS: "0",
        DimUnit: "CM",
        NetWeight: 0,
        PackageWeight: "0",
        PackageWeightUnit: "",
        BoxSize: "",
        Attribute1: "",
        Attribute2: "",
        HasSerialNumber: "0",
        InventoryAutoUpdate: 1,
        Zoning: "",
        Attribute3: "",
        OnlineProduct: "",
        FixPrice: "",
        AisFixed: "",
        BisFixed: "",
        CisFixed: "",
        DisFixed: "",
        EisFixed: "",
        FisFixed: "",
        GisFixed: "",
        HisFixed: "",
        IisFixed: "",
        JisFixed: "",
        OnLinePriceSet: "",
        OnlineShowPrice: "True",
        APrice: "",
        BPrice: "",
        CPrice: "",
        DPrice: "",
        EPrice: "",
        FPrice: "",
        GPrice: "",
        HPrice: "",
        IPrice: "",
        JPrice: "",
        Discountable: "True",
        Type: "Product",
        ExpiryPeriod: "",
        ExpiryPeriodD: "",
        ExpiryPeriodU: "",
        WarrantyPeriod: "",
        WarrantyPeriodD: "",
        WarrantyPeriodU: "",
        seq: "1",
        PrinterIDn: "1",
        IsCombo: 0,
        IsOpenPrice: 0,
        NonRevenue: 0,
        TaxTypeIDn: '',

        ModifierGroups: [],
      },
      rules: {
        Name1: [
          { required: true, message: 'Please input Product Name', trigger: 'blur' }
        ],
        DepartmentIDn: [
          { required: true, message: 'Please select Department', trigger: 'change' }
        ],
        CategoryIDn: [
          { required: true, message: 'Please select Category', trigger: 'change' }
        ],
        Weigh: [
          { required: true, message: 'Please select Weigh option', trigger: 'change' }
        ],
        PackageWeightUnit: [
          { required: true, message: 'Please select Scale Unit', trigger: 'change' }
        ],
        HasSerialNumber: [
          { required: true, message: 'Please select Serialized option', trigger: 'change' }
        ],
        TaxTypeIDn: [
          { required: true, message: 'Please select Tax Group', trigger: 'change' }
        ],
      },
      cascader_options: [],
      cascader_selected: [],
      cascader_search_array: [],
      per_page_option: [5, 10, 20, 50],

      // PreOrder: null,
      token: "",
      user_id: "",
      current_page: 1,
      page_total: 0,
      per_page: 20,
      delay_timer: null,
      po_delay_timer: null,
      po_show_name2: false,
      on_order_products_list: [],

      currpage: "list",
      currreturnpage: "list",
      activeName: "Complete", // Draft Pending Complete Shipped Deleted Back PreOrder Contract Special

      // product_search: "",
      containerList:[{"ContainerNumber":''}],
      carrierList:[{"CarrierNumber":''}],
      vendor_list: [],
      vendor_cname_list: [],
      vendor_cname_list_activeOnly: [],
      pay_period_list: [],
      carrier_list: [],
      carrier_names: [],

      country_list: [],
      province_list: [],
      city_list: [],
      data_type_list: [{
          value: "",
          label: ""
        },{
          value: "Order",
          label: "Order Date"
        }, {
          value: "Complete",
          label: "Complete Date"
      }],
      cur_address_list: [],

      show_listed_products: true,
      showReturn: false,
      showReceive: false,
      showOutbound: false,
      showInbound: false,
      showPayment: true,
      activeCollapse1: ["ProductList"],
      activeCollapse2: [],

      store_list: [],
      sales_list: [],

      isOpen: false,
      search: "",
      search_results: [],
      cur_address: {},
      cur_provincelist: [],
      cur_vendor_provincelist: [],
      cur_billing_provincelist: [],
      cur_shipping_provincelist: [],
      new_vendor_provincelist: [],
      new_address: {
        IDn: 0,
        Type: "vendor",
        ReferenceIDn: 0,
        BillingFlag: 0,
        ShippingFlag: 0,
        Status: 1,
        Name: "",
        Street1: "",
        Street2: "",
        AddressCity: "",
        ProvinceIDn: 2,
        AddressProvince: "British Columbia",
        CountryIDn: 1,
        AddressCountry: "Canada",
        ZIP: "",
        Remarks: "",
      },
      new_vendor: {
        Name: "",
        OtherName: "",
        Tel: "",
        CompanyType: "",
        Address1:"",
        Address2: "",
        ProvinceIDn: 2,
        CountryIDn: 1,
        ZIP: "",
        PaymentTermIDn: "1",
        Contact: {
          FirstName: "",
          LastName: "",
          Tel: "",
          Email: "",
          Position: "",
        },
      },
      loading: false,
      loadingCreateNewProduct: false,
      loadingProducts: true,
      loadingTabContent: true,
      loadingPOInfo: true,
      loadingReceiving: false,
      loadingReturn: false,
      vendor_address_edit: {
        Address1: "",
        Address2: "",
        AddressCity: "",
        ProvinceIDn: "",
        CountryIDn: "",
        ZIP: "",
      },
      vendor_credit: "",
      new_product: {
        IDn: 0,
        ID: 0,
        BaseProductIDn: 0,
        DepartmentIDn: 1,
        CategoryIDn: '',
        CategoryID: "",
        PLU: "",
        MinQty: 1,
        Name1: "",
        Name2: "",
        ShortName: "",
        BtnColor: "#cad1d7",
        SortOrder: "999",
        Remarks: "",
        Description1: "",
        Description2: "",
        Brand: "",
        Barcode: "",
        SKU: "",
        Status: "Active",
        OriginCountry: "",
        Unit: "ea",
        UnitName: "ea",
        Weigh: "0",
        PackL: "1",
        PackM: "1",
        PackS: "1",
        Size: "",
        DimL: "0",
        DimM: "0",
        DimS: "0",
        DimUnit: "CM",
        NetWeight: 0,
        PackageWeight: "0",
        PackageWeightUnit: "",
        BoxSize: "",
        Attribute1: "",
        Attribute2: "",
        HasSerialNumber: "0",
        InventoryAutoUpdate: 1,
        Zoning: "",
        Attribute3: "",
        OnlineProduct: "",
        FixPrice: "",
        AisFixed: "",
        BisFixed: "",
        CisFixed: "",
        DisFixed: "",
        EisFixed: "",
        FisFixed: "",
        GisFixed: "",
        HisFixed: "",
        IisFixed: "",
        JisFixed: "",
        OnLinePriceSet: "",
        OnlineShowPrice: "True",
        APrice: "",
        BPrice: "",
        CPrice: "",
        DPrice: "",
        EPrice: "",
        FPrice: "",
        GPrice: "",
        HPrice: "",
        IPrice: "",
        JPrice: "",
        Discountable: "True",
        Type: "Product",
        ExpiryPeriod: "",
        ExpiryPeriodD: "",
        ExpiryPeriodU: "",
        WarrantyPeriod: "",
        WarrantyPeriodD: "",
        WarrantyPeriodU: "",
        seq: "1",
        PrinterIDn: "1",
        IsCombo: 0,
        IsOpenPrice: 0,
        NonRevenue: 0,
        TaxTypeIDn: '',

        ModifierGroups: [],
      },
      cur_new_product: {
        IDn: 0,
        ID: 0,
        BaseProductIDn: 0,
        DepartmentIDn: 1,
        CategoryIDn: '',
        CategoryID: "",
        PLU: "",
        MinQty: 1,
        Name1: "",
        Name2: "",
        ShortName: "",
        BtnColor: "#cad1d7",
        SortOrder: "999",
        Remarks: "",
        Description1: "",
        Description2: "",
        Brand: "",
        Barcode: "",
        SKU: "",
        Status: "Active",
        OriginCountry: "",
        Unit: "ea",
        UnitName: "ea",
        Weigh: "0",
        PackL: "1",
        PackM: "1",
        PackS: "1",
        Size: "",
        DimL: "0",
        DimM: "0",
        DimS: "0",
        DimUnit: "CM",
        NetWeight: 0,
        PackageWeight: "0",
        PackageWeightUnit: "",
        BoxSize: "",
        Attribute1: "",
        Attribute2: "",
        HasSerialNumber: "0",
        InventoryAutoUpdate: 1,
        Zoning: "",
        Attribute3: "",
        OnlineProduct: "",
        FixPrice: "",
        AisFixed: "",
        BisFixed: "",
        CisFixed: "",
        DisFixed: "",
        EisFixed: "",
        FisFixed: "",
        GisFixed: "",
        HisFixed: "",
        IisFixed: "",
        JisFixed: "",
        OnLinePriceSet: "",
        OnlineShowPrice: "True",
        APrice: "",
        BPrice: "",
        CPrice: "",
        DPrice: "",
        EPrice: "",
        FPrice: "",
        GPrice: "",
        HPrice: "",
        IPrice: "",
        JPrice: "",
        Discountable: "True",
        Type: "Product",
        ExpiryPeriod: "",
        ExpiryPeriodD: "",
        ExpiryPeriodU: "",
        WarrantyPeriod: "",
        WarrantyPeriodD: "",
        WarrantyPeriodU: "",
        seq: "1",
        PrinterIDn: "1",
        IsCombo: 0,
        IsOpenPrice: 0,
        NonRevenue: 0,
        TaxTypeIDn: '',

        ModifierGroups: [],
      },
      // Search condition
      cur_start_dt: "",
      cur_end_dt: "",
      cur_date_type: "", // Order, Complete
      cur_search_input: "",
      cur_vendor: "",
      cur_vendor_id: 0,
      cur_vendor_name: "",
      cur_carrier_name: "",
      cur_order_no: "",
      cur_receive_no: "",
      cur_invoice_no: "",
      cur_remarks: "",
      cur_product: "",
      cur_product_idn: "",      
      cur_LocationID: "",
      cur_user_idn: 0, 
      componentKey_SearchAutocomplete: 0,
      selected_SearchAutocomplete: false,

      // product search conditions
      pro_search_plu: "",
      pro_search_name: "",
      pro_search_description: "",
      pro_search_unit: "",
      pro_search_qty: "",
      pro_search_onhold: "",
      pro_search_onorder: "",

      quick_add_input: "",
      isNoData: false,

      editPayment: false,

      po_qty_changed: false,

      cur_search_departmentIDn: 0,
      cur_search_categoryIDn: "",
      cur_searched_categorys: [],
      cur_searched_products: [],
      cur_listed_products: [],

      full_product_list: [],
      buy_product_list: [],
      cur_product_list: [],
      selected_product_list: [],
      payment_method_list: [],
      payment_type_list: [],
      credit_type_list: [],
      company_typelist: [],
      pay_periodlist: [],
      positionlist: [],
      cur_shipping_list: [],

      cur_payment_edit: {},
      cur_credit_edit: {},
      new_payment: {
        IDn: 0,
        OrderType: "PO",
        ReferenceIDn: "",
        CustomerIDnType: "Vendor", 
        CustomerIDn: 0,
        InvoiceNo: "",
        InvoiceAmount: 0.00,
        PaymentMethodID: "Cash",
        PaymentAmount: 0.00,
        Remarks: "",
        CreateBy: "",
        PaymentDateTime: null,
        CreateDateTime: null,
        PayType: "",
        Balance: 0.00,
        Status: "",
      },
      new_credit: {
        IDn: 0,
        OrderType: "PO",
        ReferenceIDn: 0,
        CustomerIDnType: "Vendor",
        CustomerIDn: 0,
        InvoiceNo: "",
        InvoiceAmount: 0.0,
        PaymentMethodID: "Cash",
        PaymentAmount: 0.0,
        Remarks: "",
        CreateBy: "",
        PaymentDateTime: null,
        CreateDateTime: null,
        PayType: "Redeem",
        Balance: 0.0,
        Status: "",
      },
      cur_po_list: [],
      cur_po_meta: {},
      cur_po: {},
      cur_po_tax: [],
      cur_po_product: [],
      cur_po_return: [],
      cur_po_return_completedOnly: [],
      cur_po_receive: [],
      cur_po_receive_completedOnly: [],
      cur_po_payment: [],
      cur_po_credit: [],
      cur_inbound: [],
      cur_outbound: [],
      display_PR_array: [],
      display_SN_array: [],
      selectedSN: [],
      cur_PR: [],
      cur_PR_row: "",
      total_received_product_array: [],
      total_returned_product_array: [],
      loaded_return_data: false,
      loaded_receive_data: false,
      tax_typelist: null,

      cur_discountRate: 0,
      showDiscountRate: false,
      cur_is_receive: false,
      cur_is_return: false,

      collection: [],
      import_file: null,
      import_file_warning: "",
      downloadsheet: { name: "po import", data: [ ["Barcode", "Name1", "Name2", "Qty", "Cost"] ] },

      // cur_po_product_tax: [],
      new_po: {
        POIDn: 0,
        OrderID: "",
        VendorInvoiceNo: "",

        LocationID: "",
        ComTitle: "",
        ComContact: "",
        ComAddress1: "",
        ComAddress2: "",
        ComCity: "",
        ComProvince: "",
        ComCountry: "",
        ComProvinceIDn: 2,
        ComCountryIDn: 1,
        ComZIP: "",
        ComTEL: "",
        ComExt: "",
        ComFAX: "",

        ShipTLocationID: "",
        ShipTTitle: "",
        ShipTContact: "",
        ShipTAddress1: "",
        ShipTAddress2: "",
        ShipTCity: "",
        ShipTProvince: "",
        ShipTCountry: "",
        ShipTProvinceIDn: "",
        ShipTCountryIDn: 1,
        ShipTZIP: "",
        ShipTTEL: "",
        ShipTExt: "",
        ShipTFAX: "",

        VendorIDn: "",
        PurchaseFName: "",
        PurchaseFOtherName: "",
        PurchaseFAddressIDn: 0,
        PurchaseFContact: "",
        PurchaseFAddress1: "",
        PurchaseFAddress2: "",
        PurchaseFCity: "",
        PurchaseFProvince: "",
        PurchaseFCountry: "",
        PurchaseFProvinceIDn: 2,
        PurchaseFCountryIDn: 1,
        PurchaseFZIP: "",
        PurchaseFTEL: "",
        PurchaseFExt: "",
        PurchaseFFAX: "",

        PayPeriodIDn: 1,
        
        CreateDate: null,
        EndDate: null,
        OrderDate: null,
        ArrivalDate: null,

        CreateByIDn: 0,
        CreateByName: "",
        EndByIDn: 0,
        EndByName: "",

        CarrierIDn: 0,
        CarrierName: "",
        ContainerNo: "",
        ShipStatus: "Delivery",

        Remarks: "",
        Status: "Draft",

        ShipFee: 0.0,
        TAXFlag: "",
        SubTotal: 0.0,
        TotalTax: 0.0,
        OtherFees: 0.0,
        TotalAmount: 0.0,
        Paid: 0,
        PaidAmount: 0.0,
        Discount: 0.0,

        Currency: "CAD",
        CurrencyIDn: 1,
        CurrencyRate: 1,
        ReturnedTotal: 0,
        CreditedTotal: 0, 
        Balance: 0,
      },
      picker_config2: {
        allowInput: true,
        dateFormat: "Y-m-d",
        altFormat: "Y-m-d",
        maxDate: "today", // New Date();  YYYY-MM-DD HH:MM
      },
      pickr_config: {
        allowInput: true,
        enableTime: false,
        noCalendar: false,
        dateFormat: "Y-m-d",
        // time_24hr: true
      },
      currency_List: [{
          IDn: 1,
          Currency: "CAD",
          Rate: 1
        }, {
          IDn: 2,
          Currency: "USD",
          Rate: 1.34
      }],
      shipping_status_option: [{
          IDn: 1,
          Status: "Delivery",
        }, {
          IDn: 2,
          Status: "Pick Up",
        },{
          IDn: 3,
          Status: "Price Check",
        },{
          IDn: 4,
          Status: "Ship Collect",
        },{
          IDn: 5,
          Status: "Ship Prepaid",
      }],
      disable_vendor_input: "",
      disable_billing_input: "",
      disable_shipping_input: "",
      hasCompletedRE: false,
      revertValue: "",
      cur_pr_returnSubtotal: 0,
      cur_pr_returnTaxes: [],
      cur_pr_returnTotal: 0,
      cur_pr_mode: '',  // 'new', 'complete', 'edit'
      PR_IDn: '',
      all_SN_product_array: [],
      returnShippingFee: "",
      returnOtherFee: "",
      departmentList: [],
      categoryList: [],
      weigh_unit_list: [],
      is_add_vendor_modal_opened: false,
    };
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    hasUtilityAuth,
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // Show loading in progress animation
          this.loadingCreateNewProduct = true;
          this.add_new_product();
        } else {
          return false;
        }
      });
    },
    nameState(target) {
      return target ? true : false;
    },
    checkLoadingState(obj){
      let counter = 0;
      const interval = setInterval(()=> {
        // console.log("loading..." + counter);
        if(counter > 300){                   // If loading for over 300 x 10ms
          if(obj == "loadingTabContent"){
            this.loadingTabContent = false;  // set to false
          } else if (obj == "loadingPOInfo"){
            this.loadingPOInfo = false;      // set to false
          } else if (obj == "loadingProducts"){
            this.loadingProducts = false;    // set to false
          }
        } else {
          counter++;
          let loading = this.waitingForResponse(obj);
          if(!loading){
            clearInterval(interval);
          }
        }
      }, 10);
    },
    waitingForResponse(obj){
      if(obj == "loadingTabContent"){
        return this.loadingTabContent;  // true or false
      } else if (obj == "loadingPOInfo"){
        return this.loadingPOInfo;      // true or false
      } else if (obj == "loadingProducts"){
        return this.loadingProducts;    // true or false
      }
    },
    fakeLoading(target, duration){
      // console.log("fakeLoading");
      if(target == 'loadingReceiving'){
        this.loadingReceiving = true;
        setTimeout(() => {
          this.loadingReceiving = false;
        }, duration);
      } else if(target == 'loadingReturn'){
        this.loadingReturn = true;
        setTimeout(() => {
          this.loadingReturn = false;
        }, duration);
      } else if(target == 'loadingProducts'){
        this.loadingProducts = true;
        setTimeout(() => {
          this.loadingProducts = false;
        }, duration);
      }
    },
    resetCurrentPO(){
      let temp_LocationID = '';
      let temp_ShipTLocationID = '';

      // Get a copy of LocationID and ShipTLocationID before resetting cur_po
      let previous_po = JSON.parse(JSON.stringify(this.cur_po));
      if(previous_po.LocationID && previous_po.LocationID != ''){
        temp_LocationID = previous_po.LocationID;
      }
      if(previous_po.ShipTLocationID && previous_po.ShipTLocationID != ''){
        temp_ShipTLocationID = previous_po.ShipTLocationID;
      }

      // Reset cur_po Vendor info
      this.cur_po.VendorIDn = "";
      this.cur_po.PurchaseFName = "";
      this.cur_po.PurchaseFOtherName = "";
      this.cur_po.PurchaseFAddressIDn = 0;
      this.cur_po.PurchaseFContact = "";
      this.cur_po.PurchaseFAddress1 = "";
      this.cur_po.PurchaseFAddress2 = "";
      this.cur_po.PurchaseFCity = "";
      this.cur_po.PurchaseFProvince = "";
      this.cur_po.PurchaseFCountry = "";
      this.cur_po.PurchaseFProvinceIDn = 2;
      this.cur_po.PurchaseFCountryIDn = 1;
      this.cur_po.PurchaseFZIP = "";
      this.cur_po.PurchaseFTEL = "";
      this.cur_po.PurchaseFExt = "";
      this.cur_po.PurchaseFFAX = "";

      // If already chose billing address before changing vendor, use that
      // Else, use first store as billing address
      if(temp_LocationID != ''){
        for(var i=0; i<this.store_list.length; i++){
          if(this.store_list[i].LocationID == temp_LocationID){
            this.cur_po.LocationID = this.store_list[i].LocationID;
          }
        }
      } else {
        this.cur_po.LocationID = this.store_list[1].LocationID;
      }
      
      // If already chose shipping address before changing vendor, use that
      // Else, use first store as shipping address
      if(temp_ShipTLocationID != ''){
        for(var i=0; i<this.store_list.length; i++){
          if(this.store_list[i].LocationID == temp_ShipTLocationID){
            this.cur_po.ShipTLocationID = this.store_list[i].LocationID;
          }
        }
      } else {
        this.cur_po.ShipTLocationID = this.store_list[1].LocationID;
      }

      this.store_shipping_changed();
      this.store_billing_changed();
    },
    setClassName({row, rowIndex}){
        // console.log(row);
        // console.log(rowIndex);
        return 'POProduct';
    },
    querySearch(queryString, cb) {
      var links = this.vendor_cname_list_activeOnly;
      var results = queryString ? links.filter(this.createFilter(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return (link) => {
        return (link.CompanyName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    querySearch2(queryString, cb) {
      var links = Object.values(this.carrier_list); 
      var results = queryString ? links.filter(this.createFilter2(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter2(queryString) {
      return (link) => {
        return (link.CarrierName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    selectCarrier(item){
      this.cur_po.CarrierIDn = item.IDn;
      this.cur_po.CarrierName = item.CarrierName;
    },
    resetCarrier(){
      this.cur_po.CarrierIDn = 0;
    },
    format_Fee(){
      if(this.cur_po.OtherFees === ".00"){
        this.cur_po.OtherFees = "0.00";
      }
      if(this.cur_po.ShipFee === ".00"){
        this.cur_po.ShipFee = "0.00";
      }
      return true;
    },
    highlight_text(id){
      const input = document.getElementById(id);
      input.focus();
      input.select();
    },
    check_input(){
      if(this.cur_po.OtherFees === ""){
        this.cur_po.OtherFees = "0.00";
      } else {
        this.cur_po.OtherFees = parseFloat(this.cur_po.OtherFees).toFixed(2);
      }
      if(this.cur_po.ShipFee === ""){
        this.cur_po.ShipFee = "0.00";
      } else {
        this.cur_po.ShipFee = parseFloat(this.cur_po.ShipFee).toFixed(2);
      }
    },
    checkCompletedRE(target){
      this.hasCompletedRE = false;
      for(var i=0; i<target.length; i++){
        if(target[i].Status === "Complete"){
          this.hasCompletedRE = true;
          break;
        }
      }
    },
    getCompletedRE(target){
      this.cur_po_receive_completedOnly = [];
      for(var i=0; i<target.length; i++){
        if(target[i].Status === "Complete"){
          this.cur_po_receive_completedOnly.push(target[i]);
        }
      }
    },
    getCompletedPR(target){
      this.cur_po_return_completedOnly = [];
      for(var i=0; i<target.length; i++){
        if(target[i].Status === "Complete"){
          this.cur_po_return_completedOnly.push(target[i]);
        }
      }
    },
    set_cur_po_ReturnedAmount(){
      let returnTotal = 0;
      for(let i=0; i<this.cur_po_return_completedOnly.length; i++){
        returnTotal += Number(this.cur_po_return_completedOnly[i].TotalAmount);
      }
      this.cur_po.ReturnedAmount = returnTotal;
    },
    set_cur_po_CreditedAmount(){
      let creditedTotal = 0;
      // console.log("cur_po_credit", this.cur_po_credit);
      for(let i=0; i<this.cur_po_credit.length; i++){
        if(this.cur_po_credit[i].OrderType === 'PR'){
          creditedTotal += Number(this.cur_po_credit[i].PaymentAmount);
        }
      }
      // console.log("creditedTotal", creditedTotal);
      this.cur_po.CreditedAmount = creditedTotal;
    },
    set_total_received_product_array(){
      this.total_received_product_array = [];
      // GET total_received_product_array
      for(let i=0; i<this.cur_po_receive_completedOnly.length; i++){
        if(i == 0){
          // Set total_received_product_array to be the products[] of the first RE in this PO
          this.total_received_product_array = JSON.parse(JSON.stringify(this.cur_po_receive_completedOnly[i].Products));
          for(let j=0; j<this.total_received_product_array.length; j++){
            delete this.total_received_product_array[j].IDn;
            delete this.total_received_product_array[j].ReceiveIDn;
            if(this.total_received_product_array[j].SerialNumber){
              this.total_received_product_array[j].SerialNumber = JSON.parse(this.total_received_product_array[j].SerialNumber);
            }
            this.total_received_product_array[j].ReturnQty = 0;
            this.total_received_product_array[j].ReturnSN = '';
            this.total_received_product_array[j].ReturnSNArray = [];
            for(let k=0; k<this.total_received_product_array[j].Taxes.length; k++){
              delete this.total_received_product_array[j].Taxes[k].IDn;
            }
          }
        } else {
          // If there are more than one RE...
          for(let y=0; y<this.cur_po_receive_completedOnly[i].Products.length; y++){
            for(let x=0; x<this.total_received_product_array.length; x++){
              if(this.cur_po_receive_completedOnly[i].Products[y].POProductIDn == this.total_received_product_array[x].POProductIDn){
                // Found corresponding POProductIDn

                // Increment TotalCost, TotalTax, Total, ReceivedQty of each product
                this.total_received_product_array[x].ReceivedQty = (Number(this.total_received_product_array[x].ReceivedQty) + Number(this.cur_po_receive_completedOnly[i].Products[y].ReceivedQty)).toFixed(2);
                this.total_received_product_array[x].TotalCost = (Number(this.total_received_product_array[x].TotalCost) + Number(this.cur_po_receive_completedOnly[i].Products[y].TotalCost)).toFixed(2);
                this.total_received_product_array[x].TotalTax = (Number(this.total_received_product_array[x].TotalTax) + Number(this.cur_po_receive_completedOnly[i].Products[y].TotalTax)).toFixed(2);
                this.total_received_product_array[x].Total = (Number(this.total_received_product_array[x].Total) + Number(this.cur_po_receive_completedOnly[i].Products[y].Total)).toFixed(2);
                this.total_received_product_array[x].ReturnQty = 0;
                this.total_received_product_array[x].ReturnSN = '';
                this.total_received_product_array[x].ReturnSNArray = [];

                // Concat Serial Number of each product
                if(this.total_received_product_array[x].SerialNumber && this.cur_po_receive_completedOnly[i].Products[y].SerialNumber){
                  this.total_received_product_array[x].SerialNumber = this.total_received_product_array[x].SerialNumber.concat(JSON.parse(this.cur_po_receive_completedOnly[i].Products[y].SerialNumber));
                }
                
                // Calculate taxes
                for(let m=0; m<this.total_received_product_array[x].Taxes.length; m++){
                  for(let n=0; n<this.cur_po_receive_completedOnly[i].Products[y].Taxes.length; n++){
                    if(this.total_received_product_array[x].Taxes[m].TaxID == this.cur_po_receive_completedOnly[i].Products[y].Taxes[n].TaxID){
                      if(this.total_received_product_array[x].Taxes[m].TaxType == '$'){
                        this.total_received_product_array[x].Taxes[m].TaxAmount = (Number(this.total_received_product_array[x].Taxes[m].TaxValue) * Number(this.total_received_product_array[x].ReceivedQty)).toFixed(2);
                      } else {
                        this.total_received_product_array[x].Taxes[m].TaxAmount = (Number(this.total_received_product_array[x].Taxes[m].TaxValue) * Number(this.total_received_product_array[x].ReceivedQty) * 
                                                                             Number(this.total_received_product_array[x].UnitCost) * 0.01 ).toFixed(2);
                      }
                    }
                  }
                }
              } else {
                // No corresponding POProductIDn. User added new items to PO after first receiving
                // TODO: create new entry in total_received_product_array[]
                // Not Possible. Block by UI control
              }
            }
          }
        }
      }
      // console.log("total_received_product_array", this.total_received_product_array);
    },
    set_total_returned_product_array(){
      this.total_returned_product_array = [];
      // GET total_returned_product_array
      for(let i=0; i<this.cur_po_return_completedOnly.length; i++){
        if(i == 0){
          // Set total_returned_product_array to be the products[] of the first PR in this PO
          this.total_returned_product_array = JSON.parse(JSON.stringify(this.cur_po_return_completedOnly[i].Products));
          for(let j=0; j<this.total_returned_product_array.length; j++){
            // delete total_returned_product_array[j].IDn;
            // delete total_returned_product_array[j].ReceiveIDn;
            if(this.total_returned_product_array[j].SerialNumber){
              this.total_returned_product_array[j].SerialNumber = JSON.parse(this.total_returned_product_array[j].SerialNumber);
            }
            for(let k=0; k<this.total_returned_product_array[j].Taxes.length; k++){
              delete this.total_returned_product_array[j].Taxes[k].IDn;
            }
          }
        }  else {
          // If there are more than one PR...             
          for(let j=0; j<this.cur_po_return_completedOnly[i].Products.length; j++){
            for(let k=0; k<this.total_returned_product_array.length; k++){
              if(this.cur_po_return_completedOnly[i].Products[j].POProductIDn == this.total_returned_product_array[k].POProductIDn){
                // Found corresponding POProductIDn

                // Increment TotalCost, TotalTax, Total, ReturnedQty of each product
                this.total_returned_product_array[k].ReturnQty = (Number(this.total_returned_product_array[k].ReturnQty) + Number(this.cur_po_return_completedOnly[i].Products[j].ReturnQty)).toFixed(2);
                this.total_returned_product_array[k].TotalCost = (Number(this.total_returned_product_array[k].TotalCost) + Number(this.cur_po_return_completedOnly[i].Products[j].TotalCost)).toFixed(2);
                this.total_returned_product_array[k].TotalTax = (Number(this.total_returned_product_array[k].TotalTax) + Number(this.cur_po_return_completedOnly[i].Products[j].TotalTax)).toFixed(2);
                this.total_returned_product_array[k].Total = (Number(this.total_returned_product_array[k].Total) + Number(this.cur_po_return_completedOnly[i].Products[j].Total)).toFixed(2);

                // Concat Serial Number of each product
                if(this.total_returned_product_array[k].SerialNumber){
                  this.total_returned_product_array[k].SerialNumber = this.total_returned_product_array[k].SerialNumber.concat(JSON.parse(this.cur_po_return_completedOnly[i].Products[j].SerialNumber));
                }
                
                // Calculate taxes
                for(let m=0; m<this.total_returned_product_array[k].Taxes.length; m++){
                  for(let n=0; n<this.cur_po_return_completedOnly[i].Products[j].Taxes.length; n++){
                    if(this.total_returned_product_array[k].Taxes[m].TaxID == this.cur_po_return_completedOnly[i].Products[j].Taxes[n].TaxID){
                      if(this.total_returned_product_array[k].Taxes[m].TaxType == '$'){
                        this.total_returned_product_array[k].Taxes[m].TaxAmount = (Number(this.total_returned_product_array[k].Taxes[m].TaxValue) * Number(this.total_returned_product_array[k].ReceivedQty)).toFixed(2);
                      } else {
                        this.total_returned_product_array[k].Taxes[m].TaxAmount = (Number(this.total_returned_product_array[k].Taxes[m].TaxValue) * Number(this.total_returned_product_array[k].ReceivedQty) * 
                                                                             Number(this.total_returned_product_array[k].UnitCost) * 0.01 ).toFixed(2);
                      }
                    }
                  }
                }
              } else {
                // No corresponding POProductIDn. User added new items to PO after first receiving
                // TODO: create new entry in total_received_product_array[]
              }
            }
          }
        }
      }
      // console.log("total_returned_product_array", this.total_returned_product_array);
    },
    set_display_PR_array(mode, row){
      
      this.set_total_received_product_array();
      this.set_total_returned_product_array();
      
      if(mode === 'new'){
        this.display_PR_array = JSON.parse(JSON.stringify(this.total_received_product_array));
        for(let i=0; i<this.display_PR_array.length; i++){
          for(let j=0; j<this.total_returned_product_array.length; j++){
            if(this.total_returned_product_array[j].POProductIDn == this.display_PR_array[i].POProductIDn){
              // Found product

              // Decrement ReceivedQty
              this.display_PR_array[i].ReceivedQty = Number(this.display_PR_array[i].ReceivedQty) - Number(this.total_returned_product_array[j].ReturnQty) 

              // Remove S/N
              for(let k=0; k<this.total_returned_product_array[j].SerialNumber.length; k++){
                let index = this.display_PR_array[i].SerialNumber.indexOf(this.total_returned_product_array[j].SerialNumber[k]);
                if (index > -1) {
                  this.display_PR_array[i].SerialNumber.splice(index, 1); // 2nd parameter means remove one item only
                }
              }
            }
          }
        }
      } else if(mode === 'complete'){
        this.display_PR_array = JSON.parse(JSON.stringify(row.Products));
        
        for(let i=0; i<this.display_PR_array.length; i++){
          // Rounding
          this.display_PR_array[i].ReturnQty = Number(this.display_PR_array[i].ReturnQty).toFixed(2);
          // Hide S/N button for non S/N products
          this.display_PR_array[i].SerialNumber = JSON.parse(this.display_PR_array[i].SerialNumber);
          for(let j=0; j<this.total_received_product_array.length; j++){
            if(this.total_received_product_array[j].POProductIDn == this.display_PR_array[i].POProductIDn){
              if(!Array.isArray(this.total_received_product_array[j].SerialNumber) ||
                 this.total_received_product_array[j].SerialNumber.length == 0){
                this.display_PR_array[i].SerialNumber = '';
              }
            }
          }
          // if(Array.isArray(this.display_PR_array[i].SerialNumber) && this.display_PR_array[i].SerialNumber.length === 0){
          //   this.display_PR_array[i].SerialNumber = '';
          // }
        }

        // Fix subtotals and total
        this.calculateReturnSubtotal();
      } else if(mode === 'edit'){
        this.display_PR_array = JSON.parse(JSON.stringify(row.Products));
  
        for(let i=0; i<this.display_PR_array.length; i++){
          // Rounding
          this.display_PR_array[i].ReturnQty = Number(this.display_PR_array[i].ReturnQty).toFixed(2);
          // Hide S/N button for non S/N products
          this.display_PR_array[i].SerialNumber = JSON.parse(this.display_PR_array[i].SerialNumber);
          for(let j=0; j<this.total_received_product_array.length; j++){
            if(this.total_received_product_array[j].POProductIDn == this.display_PR_array[i].POProductIDn){
              if(Array.isArray(this.total_received_product_array[j].SerialNumber) &&
                 this.total_received_product_array[j].SerialNumber.length > 0){
                this.display_PR_array[i].ReturnSNArray = this.display_PR_array[i].SerialNumber;
              } else{
                this.display_PR_array[i].SerialNumber = '';
              }
            }
          }
          // if(Array.isArray(this.display_PR_array[i].SerialNumber) && this.display_PR_array[i].SerialNumber.length === 0){
          //   this.display_PR_array[i].SerialNumber = '';
          // } else {
          //   this.display_PR_array[i].ReturnSNArray = this.display_PR_array[i].SerialNumber;
          // }
        }

        // Decrement ReceivedQty
        for(let i=0; i<this.display_PR_array.length; i++){
          for(let j=0; j<this.total_received_product_array.length; j++){
            if(this.display_PR_array[i].POProductIDn == this.total_received_product_array[j].POProductIDn){
              this.display_PR_array[i].ReceivedQty = Number(this.total_received_product_array[j].ReceivedQty);
            }
          }
          for(let j=0; j<this.total_returned_product_array.length; j++){
            if(this.display_PR_array[i].POProductIDn == this.total_returned_product_array[j].POProductIDn){
              this.display_PR_array[i].ReceivedQty = this.display_PR_array[i].ReceivedQty -  Number(this.total_returned_product_array[j].ReturnQty);
            }
          }
        }

        // Fix subtotals and total
        this.calculateReturnSubtotal();
      }
      
      // console.log("display_PR_array", this.display_PR_array );
      // console.log("cur_po_return", this.cur_po_return);
    },
    init_return(mode, row){
      this.cur_pr_mode = mode;
      if(this.cur_po_receive_completedOnly.length <= 0){
        alert(this.cur_po.OrderID + ' does NOT have any Receive Orders.');
        return;
      }
      this.set_display_PR_array(mode, row);
      this.calculateReturnSubtotal();
      // console.log(row);
      if(mode !== 'new'){
        this.PR_IDn = row.IDn;
        this.returnShippingFee = Number(row.ShipFee).toFixed(2);
        this.returnOtherFee = Number(row.OtherFees).toFixed(2);
      } else {
        this.PR_IDn = '';
        this.returnShippingFee = "0.00";
        this.returnOtherFee = "0.00";
      }
      this.$refs["add-return-modal"].show();
    },
    initSN_data(row) {
      this.cur_PR_row = JSON.parse(JSON.stringify(row));
      if(!this.cur_PR_row.ReturnQty || this.cur_PR_row.ReturnQty == ''){
        this.cur_PR_row.ReturnQty = 0;
      }
      // Update display_SN_array      
      if(this.cur_pr_mode === 'new'){
        this.display_SN_array = row.SerialNumber;
        this.selectedSN = row.ReturnSNArray;
      }
      else if(this.cur_pr_mode == 'complete'){
        this.display_SN_array = [];
        this.selectedSN = this.cur_PR_row.SerialNumber;
        this.cur_PR_row.ReturnQty = Number(this.cur_PR_row.ReturnQty);
      }
      else if(this.cur_pr_mode == 'edit'){
        let temp_display_SN_array = [];
        this.cur_PR_row.ReturnQty = Number(this.cur_PR_row.ReturnQty);
        // Set selectedSN
        this.selectedSN = row.SerialNumber;
        // In case of selectedSN contains already returned S/N, remove S/N
        for(let i=0; i<this.total_returned_product_array.length; i++){
          if(row.POProductIDn == this.total_returned_product_array[i].POProductIDn){
            for(let j=0; j<this.total_returned_product_array[i].SerialNumber.length; j++){
              let index = this.selectedSN.indexOf(this.total_returned_product_array[i].SerialNumber[j]);
              if (index > -1) {
                this.selectedSN.splice(index, 1); // 2nd parameter means remove one item only
              }
            }
          }
          
        }

        // Get all received S/N of this POProduct
        for(let i=0; i<this.total_received_product_array.length; i++){
          if(row.POProductIDn == this.total_received_product_array[i].POProductIDn){
            temp_display_SN_array = JSON.parse(JSON.stringify(this.total_received_product_array[i].SerialNumber));
          }
        }

        // Subtract all returned S/N of this POProduct
        for(let i=0; i<this.total_returned_product_array.length; i++){
          if(row.POProductIDn == this.total_returned_product_array[i].POProductIDn){
            for(let j=0; j<this.total_returned_product_array[i].SerialNumber.length; j++){
              let index = temp_display_SN_array.indexOf(this.total_returned_product_array[i].SerialNumber[j]);
              if (index > -1) {
                temp_display_SN_array.splice(index, 1); // 2nd parameter means remove one item only
              }
            }
          }
        }

        // Subtract selectedSN
        for(let i=0; i<this.selectedSN.length; i++){
          let index = temp_display_SN_array.indexOf(this.selectedSN[i]);
          if (index > -1) {
            temp_display_SN_array.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
        
        // Set display_SN_array
        this.display_SN_array = temp_display_SN_array;
        
      }
    },
    selectSN(row){
      this.initSN_data(row);
      this.$refs["select-sn-modal"].show();
    },
    validateReturnQty(row){
      if(isNaN(row.ReturnQty) && row.ReturnQty){
        alert("Enter numbers only.");
        row.ReturnQty = '';
      }
      if(row.ReturnQty > Number(row.ReceivedQty)){
        alert("Cannot return more than received.");
        row.ReturnQty = '';
      }

      // Reset selectedSN everytime ReturnQTY is changed
      this.selectedSN = [];

      // Format ReturnQty
      row.ReturnQty = Number(row.ReturnQty).toFixed(2);

      // Update PR totals
      this.calculateReturnSubtotal();
    },
    validateReturnShippingFee(){
      if(this.returnShippingFee !== "0.00"){
        if(isNaN(this.returnShippingFee)){
          alert("Enter numbers only.");
          this.returnShippingFee = "0.00";
        } else {
          this.returnShippingFee = Number(this.returnShippingFee).toFixed(2);
        }
      }
      this.calculateReturnSubtotal();
    },
    validateReturnOtherFee(){
      if(this.returnOtherFee !== "0.00"){
        if(isNaN(this.returnOtherFee)){
          alert("Enter numbers only.");
          this.returnOtherFee = "0.00";
        } else {
          this.returnOtherFee = Number(this.returnOtherFee).toFixed(2);
        }
      }
      this.calculateReturnSubtotal();
    },
    calculateReturnSubtotal(){
      this.cur_pr_returnSubtotal = 0;
      for(let i=0; i<this.display_PR_array.length; i++){
        this.display_PR_array[i].ReturnSubtotal = this.display_PR_array[i].Subtotal;
        if( Number(this.display_PR_array[i].ReturnQty) > 0 ){
          this.display_PR_array[i].ReturnSubtotal = (Number(this.display_PR_array[i].ReturnQty) * Number(this.display_PR_array[i].UnitCost));
          this.display_PR_array[i].ReturnSubtotal = (this.display_PR_array[i].ReturnSubtotal).toFixed(2);
          this.cur_pr_returnSubtotal += Number(this.display_PR_array[i].ReturnSubtotal);
        } else{
          this.display_PR_array[i].ReturnSubtotal = (0).toFixed(2);
        }
      }
      this.calculateReturnTax();
    },
    calculateReturnTax(){
      this.cur_pr_returnTaxes = [];

      // Calculate taxes for each product in display_PR_array 
      for(let i=0; i<this.display_PR_array.length; i++){
        this.display_PR_array[i].ReturnTaxes = JSON.parse(JSON.stringify(this.display_PR_array[i].Taxes));
        for(let j=0; j<this.display_PR_array[i].ReturnTaxes.length; j++){
          if( Number(this.display_PR_array[i].ReturnQty) > 0 ){
            if(this.display_PR_array[i].ReturnTaxes[j].TaxType == '$'){
              this.display_PR_array[i].ReturnTaxes[j].TaxAmount = Number(this.display_PR_array[i].ReturnTaxes[j].TaxValue) * Number(this.display_PR_array[i].ReturnQty);
              this.display_PR_array[i].ReturnTaxes[j].TaxAmount = this.display_PR_array[i].ReturnTaxes[j].TaxAmount.toFixed(2);
            } else {
              this.display_PR_array[i].ReturnTaxes[j].TaxAmount = Number(this.display_PR_array[i].ReturnTaxes[j].TaxValue) * Number(this.display_PR_array[i].ReturnQty) *
                                                                  Number(this.display_PR_array[i].UnitCost) * 0.01;
              this.display_PR_array[i].ReturnTaxes[j].TaxAmount = this.display_PR_array[i].ReturnTaxes[j].TaxAmount.toFixed(2);
            }
          } else {
            this.display_PR_array[i].ReturnTaxes[j].TaxAmount = (0).toFixed(2);
          }
        }
      }
      
      // Set cur_pr_returnTaxes
      for(let i=0; i<this.display_PR_array.length; i++){
        this.display_PR_array[i].ReturnTotalTax = 0;
        if(i == 0){
          this.cur_pr_returnTaxes = JSON.parse(JSON.stringify(this.display_PR_array[i].ReturnTaxes));
          for(let j=0; j<this.display_PR_array[i].ReturnTaxes.length; j++){
            this.display_PR_array[i].ReturnTotalTax += Number(this.display_PR_array[i].ReturnTaxes[j].TaxAmount);
          }
        } else {
          for(let j=0; j<this.display_PR_array[i].ReturnTaxes.length; j++){
            this.display_PR_array[i].ReturnTotalTax += Number(this.display_PR_array[i].ReturnTaxes[j].TaxAmount);
            let taxFound = false;
            for(let k=0; k<this.cur_pr_returnTaxes.length; k++){
              if(this.display_PR_array[i].ReturnTaxes[j].TaxID == this.cur_pr_returnTaxes[k].TaxID){
                taxFound = true;
                this.cur_pr_returnTaxes[k].TaxAmount = Number(this.cur_pr_returnTaxes[k].TaxAmount) + Number(this.display_PR_array[i].ReturnTaxes[j].TaxAmount);
                this.cur_pr_returnTaxes[k].TaxAmount = (this.cur_pr_returnTaxes[k].TaxAmount).toFixed(2);
              }
            }
            // Add new tax
            if(!taxFound){
              this.cur_pr_returnTaxes.push(this.display_PR_array[i].ReturnTaxes[j]);
            }
          }
        }
      }
      this.calculateReturnTotal();
    },
    calculateReturnTotal(){
      let returnTaxes_total = 0;
      for(let i=0; i<this.cur_pr_returnTaxes.length; i++){
        returnTaxes_total += Number(this.cur_pr_returnTaxes[i].TaxAmount);
      }
      for(let i=0; i<this.display_PR_array.length; i++){
        this.display_PR_array[i].ReturnTotal = Number(this.display_PR_array[i].ReturnSubtotal) + Number(this.display_PR_array[i].ReturnTotalTax);
        this.display_PR_array[i].ReturnTotal.toFixed(2);
      }
      // console.log(returnTaxes_total);
      // console.log(this.cur_pr_returnSubtotal);
      // console.log(this.cur_pr_returnTaxes);
      // console.log(this.display_PR_array);
      this.cur_pr_returnTotal = Number(this.cur_pr_returnSubtotal) + Number(returnTaxes_total) + Number(this.returnShippingFee) + Number(this.returnOtherFee);
      

    },
    submitPR(PR_status){
      
      /*
        Draft         -> Creating a new Draft
        DraftDraft    -> Updating an existing Draft
        Post          -> Posting a new Order
        PostDraft     -> Posting an existing Draft
        Delete        -> Deleting an existing Draft
      */
      if(PR_status === 'Post'){
        if(!this.validateSN_PR(this.display_PR_array)) return;
        if(!this.validateReturnQty_PR(this.display_PR_array)) return;

        this.post_pr(PR_status);
      }
      else if(PR_status === 'Draft'){
        this.post_pr(PR_status);
      }
      else if(PR_status === 'DraftDraft'){
        this.post_pr(PR_status);
      }
      else if(PR_status === 'PostDraft'){
        if(!this.validateSN_PR(this.display_PR_array)) return;
        if(!this.validateReturnQty_PR(this.display_PR_array)) return;

        this.post_pr(PR_status);
      }
      else if(PR_status === 'Delete'){
        this.post_pr(PR_status);
      }
    },
    validateReturnQty_PR(target){
      // console.log("validateReturnQty_PR target: ", target);
      for(let i=0; i<target.length; i++){
        if(Number(target[i].ReturnQty) > 0 || Number(this.returnShippingFee) > 0 || Number(this.returnOtherFee) > 0){
          return true;
        }
      }
      alert("Not returning any items.");
      return false;
    },
    validateSN_PR(target){
      // console.log("validateSN_PR target: ", target);
      for(let i=0; i<target.length; i++){
        if(Number(target[i].ReturnQty) > 0){
          if(Array.isArray(target[i].SerialNumber)){
            // Check if ReturnSNArray is defined
            if(!target[i].ReturnSNArray){
              alert("Please select the S/N you would like to return.");
              return false;
            }
            // Check if ReturnSNArray is equal to ReturnQty
            if(target[i].ReturnSNArray.length != target[i].ReturnQty){
              alert("Please provide exactly " + target[i].ReturnQty + " S/N.");
              return false;
            }
            // Check if SN exists in database
            for(let j=0; j<target[i].ReturnSNArray.length; j++){
              let found = false;
              let temp = 0;
              for(let k=0; k<this.all_SN_product_array.length; k++){
                if(this.all_SN_product_array[k].SN == target[i].ReturnSNArray[j]){
                  temp = k;
                  if(this.all_SN_product_array[k].Status == 'Active'){
                    found = true;
                    break;
                  } 
                }
              }
              if(!found){
                alert("Cannot delete this SN.\n\nReference:\nSN: " + this.all_SN_product_array[temp].SN
                      + "\nIDn: " + this.all_SN_product_array[temp].IDn
                      + "\nProductIDn: " + this.all_SN_product_array[temp].ProductIDn
                      + "\nStatus: " + this.all_SN_product_array[temp].Status);
                return false;
              }
            }
          }
        }
      }
      return true;
    },
    checkSN(row){
      if(this.selectedSN.length >= this.cur_PR_row.ReturnQty){
        alert('Cannot select more than ' + this.cur_PR_row.ReturnQty + ' S/N.');
        return;
      }
      // Push to selectedSN
      this.selectedSN.push(row);

      // Delete from display_SN_array
      const index = this.display_SN_array.indexOf(row);
      if (index > -1) {
        this.display_SN_array.splice(index, 1);
      }
    },
    uncheckSN(row){
      // Push to display_SN_array
      this.display_SN_array.push(row);

      // Delete from selectedSN
      const index = this.selectedSN.indexOf(row);
      if (index > -1) {
        this.selectedSN.splice(index, 1);
      }
    },
    submitSelectedSN(){
      
      // console.log(JSON.stringify(this.selectedSN));
      this.cur_PR_row.ReturnSNArray = JSON.parse(JSON.stringify(this.selectedSN));
      this.cur_PR_row.ReturnSN = this.selectedSN;
      this.$refs["select-sn-modal"].hide();
    },
    updateVendorCredit(){
      let credited = 0;
      // Update to include current return total
      // console.log("cur_PR: ", this.cur_PR);
      this.cur_po.ReturnedAmount += Number(this.cur_PR.TotalAmount);
      // console.log("Before adding credits:");
      // console.log("PaidAmount: ", this.cur_po.PaidAmount);
      // console.log("ReturnedAmount: ", this.cur_po.ReturnedAmount);
      // console.log("TotalAmount: ", this.cur_po.TotalAmount);
      // console.log("CreditedAmount: ", this.cur_po.CreditedAmount);
      if(Number(this.cur_po.PaidAmount) + Number(this.cur_po.ReturnedAmount) > Number(this.cur_po.TotalAmount) + Number(this.cur_po.CreditedAmount)){
        // create credit reload record, amount = PaidAmount + ReturnedAmount - TotalAmount
        this.cur_credit_edit = JSON.parse(JSON.stringify(this.new_credit));
        this.cur_credit_edit["CustomerIDn"] = this.cur_po.VendorIDn;
        this.cur_credit_edit["ReferenceIDn"] = this.cur_po.POIDn;
        this.cur_credit_edit["InvoiceNo"] = this.cur_po.OrderID;
        this.cur_credit_edit["InvoiceAmount"] = this.cur_po.TotalAmount;
        this.cur_credit_edit["OrderType"]  = "PR";
        this.cur_credit_edit["PaymentAmount"] = (Number(this.cur_po.PaidAmount) + Number(this.cur_po.ReturnedAmount) - Number(this.cur_po.TotalAmount) - Number(this.cur_po.CreditedAmount)).toFixed(2);
        this.cur_credit_edit["PayType"] = "Reload"
        this.cur_credit_edit["PaymentMethodID"] = "Credit"
        this.cur_credit_edit["Remarks"]= " --auto created by " + this.cur_PR.OrderID;
        this.add_credit();
        credited = Number(this.cur_po.CreditedAmount) + Number(this.cur_credit_edit["PaymentAmount"]);
      }
      return credited;
    },
    print_order(type) {
      var url = this.apiBaseUrl + "/Web/Purchase/Pdf?token=" + this.token;
      url += "&user_id=" + this.user_id;
      url += "&type=" + type;
      url += "&POIDn=" + this.cur_po.POIDn;
      if(type == 'PR'){
        url += "&PRIDn=" + this.PR_IDn;
      }
      window.open(url, "_blank");
    },
    add_cur_product_qty() {
      var dpt, cat, prd;
      for (var i = 0; i < this.full_product_list.length; i++) {
        dpt = this.full_product_list[i]['IDn'];
        for (var j = 0; j < this.full_product_list[i]["Categorys"].length; j++) {
          cat = this.full_product_list[i]["Categorys"][j]['IDn'];
          for (var k = 0; k < this.full_product_list[i]["Categorys"][j]["Products"].length; k++) {
            prd = this.full_product_list[i]["Categorys"][j]["Products"][k]['IDn'];
            if (this.inventory_products.hasOwnProperty(dpt) &&
                this.inventory_products[dpt].hasOwnProperty(cat) &&
                this.inventory_products[dpt][cat].hasOwnProperty(prd)) {
              this.full_product_list[i]["Categorys"][j]["Products"][k]['StoreQty'] = this.inventory_products[dpt][cat][prd].StoreQty;
              this.full_product_list[i]["Categorys"][j]["Products"][k]['StoreCost'] = this.inventory_products[dpt][cat][prd].StoreCost;
            } else {
              this.full_product_list[i]["Categorys"][j]["Products"][k]['StoreQty'] = 0;
              this.full_product_list[i]["Categorys"][j]["Products"][k]['StoreCost'] = 0;
            }
          }
        }
      }
    },
    add_cur_order_qty() {
      for (var key in this.cur_po_product) {
        this.cur_po_product['CurOrderQty'] = this.cur_po_product['OrderQty'];
      }
    },
    on_import_change(event) {
      this.import_file = event.target.files ? event.target.files[0] : null;
    },
    import_file_parsed(filedata) {
      if ((this.cur_po.VendorIDn == undefined) || (this.cur_po.VendorIDn <= 0)) {
        alert("Please select vendor first");
        return;
      }
      this.import_file_warning = "";
      this.selected_product_list = [];
      // console.log('import_file_parsed', arguments, filedata);
      var sheets = filedata.Sheets[Object.keys(filedata.Sheets)[0]];
      var a = (sheets["A1"] != undefined)?sheets["A1"].w.trim():"";
      var b = (sheets["B1"] != undefined)?sheets["B1"].w.trim():"";
      var c = (sheets["C1"] != undefined)?sheets["C1"].w.trim():"";
      var d = (sheets["D1"] != undefined)?sheets["D1"].w.trim():"";
      var e = (sheets["E1"] != undefined)?sheets["E1"].w.trim():"";
      var find;
      if ((a != "Barcode") ||
          (b != "Name1") || 
          (c != "Name2") || 
          (d != "Qty") ||
          (e != "Cost") || 
          (sheets["F1"] != undefined)) {
        this.import_file_warning = "Import File has something wrong";
        return;
      }
      for (var idx=2; idx<10000; idx++) {
        // Max 10000 for safe
        a = (sheets["A"+idx] != undefined)?sheets["A"+idx].w.trim():"";
        b = (sheets["B"+idx] != undefined)?sheets["B"+idx].w.trim():"";
        c = (sheets["C"+idx] != undefined)?sheets["C"+idx].w.trim():"";
        d = (sheets["D"+idx] != undefined)?sheets["D"+idx].w.trim():"";
        e = (sheets["E"+idx] != undefined)?sheets["E"+idx].w.trim():"";
        if (!a && !b && !c) {
          break;
        }
        find = false;
        for (var i = 0; i < this.full_product_list.length; i++) {
          for (var j = 0; j < this.full_product_list[i]['Categorys'].length; j++) {
            for (var k = 0; k < this.full_product_list[i]['Categorys'][j]['Products'].length; k++) {
              if ((this.full_product_list[i]['Categorys'][j]['Products'][k].Barcode == a) ||
                  (this.full_product_list[i]['Categorys'][j]['Products'][k].Name1 == b) ||
                  (this.full_product_list[i]['Categorys'][j]['Products'][k].Name2 == c)) {
                let prod = {
                  IDn: 0,
                  key: 0,
                  ProductIDn: this.full_product_list[i]['Categorys'][j]['Products'][k].IDn,
                  PLU: this.full_product_list[i]['Categorys'][j]['Products'][k].PLU,
                  Barcode: this.full_product_list[i]['Categorys'][j]['Products'][k].Barcode,
                  Name1: this.full_product_list[i]['Categorys'][j]['Products'][k].Name1,
                  Name2: this.full_product_list[i]['Categorys'][j]['Products'][k].Name2,
                  Unit: this.full_product_list[i]['Categorys'][j]['Products'][k].Unit,
                  Weigh: this.full_product_list[i]['Categorys'][j]['Products'][k].Weigh,
                  HasSerialNumber: this.full_product_list[i]['Categorys'][j]['Products'][k].HasSerialNumber,
                  UnitCost: parseFloat(e).toFixed(2),
                  OrderQty: parseFloat(d).toFixed(2),
                  TotalCost: (parseFloat(e) * parseFloat(d)).toFixed(2),
                  StoreQty: this.full_product_list[i]["Categorys"][j]["Products"][k].StoreQty,
                  StoreCost: this.full_product_list[i]["Categorys"][j]["Products"][k].StoreCost,
                  Status: "Draft", // Delete, Post, Draft
                  Remarks: "",
                  SerialNumber: [],
                  ReferenceIDn: 0,
                };
                this.selected_product_list.unshift(prod);
                find = true;
                break;
              }
            }
            if (find) {
              break;
            }
          }
          if (find) {
            break;
          }
        }
        if (!find) {
          this.import_file_warning = "Can't find product at line " + idx;
          return;
        }
      }
      this.close_import_modal();
      this.submit_product_selection();
    },
    input_discount_rate() {
      this.cur_discountRate = "";
      this.showDiscountRate = true;
      var that = this;
      this.$nextTick(() => {
        that.$refs["discount_rate"].focus();
      });
    },
    set_cur_product_name(val) {
      this.cur_product = val.value;
      this.cur_product_idn = val.key;
      this.selected_SearchAutocomplete = true;
    },
    reset_cur_product_idn(val){
      this.cur_product_idn = "";
      this.selected_SearchAutocomplete = false;
    },
    handleClick(tab, event) {
      this.loadingTabContent = true;
      this.current_page = 1;
      this.page_total = 0;
      this.per_page = 20;
      this.cur_user_idn = 0;
      this.cur_LocationID = "";
      this.cur_product = "";
      this.cur_product_idn = "";
      this.cur_remarks = "";
      this.cur_invoice_no = "";
      this.cur_order_no = "";
      this.cur_receive_no = "";
      this.cur_vendor = "";
      this.cur_vendor_id = 0;
      this.cur_date_type = "";
      this.get_lists();
      this.checkLoadingState("loadingTabContent");
      this.forceRerender_SearchAutocomplete();
      // this.$forceUpdate();
    },
    forceRerender_SearchAutocomplete(){
      this.componentKey_SearchAutocomplete++;
    },
    number_input(string, evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      // NumberPad 0-9: 96-105
      // NumberPad .  : 110
      // NumberRow 0-9: 48-57
      // Keyboard .   : 190
      // Backspace    : 8
      // Delete       : 46
      // Left-arrow   : 37
      // Right-arrow  : 39
      if ( (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105) &&
           charCode != 110 && charCode != 190 && charCode != 8 && charCode != 46 && charCode != 37 && charCode != 39)
      {
        evt.preventDefault();         // Prevents all other keys
      } else {
        if((charCode >= 48 && charCode <= 57)){
          if(isNaN(evt.key)){
            evt.preventDefault();     // Prevents !@#$%^&*()
          } else {
            return true;
          }
        } else if(charCode == 190){
          if(evt.key == '>'){
            evt.preventDefault();     // Prevents ">"
          } else {
            if(string.toString().includes(".")){
              evt.preventDefault();   // Prevents more than two "." from 190
            } else {
              return true;
            }
          }
        } else if(charCode == 110){
          if(string.toString().includes(".")){
            evt.preventDefault();     // Prevents more than two "." from 110
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    },
    vendor_changed() {
      var illegalvendor = true;
      for (var key in this.vendor_list) {
        if (this.cur_vendor_name == (this.vendor_list[key].CompanyName)) {
          // found it
          this.cur_po.VendorIDn = this.vendor_list[key].IDn;
          illegalvendor = false;
          break;
        }
      }
      if(illegalvendor){
        this.cur_vendor_name = '';
        return;
      }

      // console.log("cur_po", this.cur_po);
      // this.setContainCarrier(0);
      if (this.cur_po.VendorIDn) {
        this.cur_po.PurchaseFName = this.vendor_list[this.cur_po.VendorIDn].CompanyName;
        this.cur_po.PurchaseFOtherName = this.vendor_list[this.cur_po.VendorIDn].OtherName;
        this.cur_po.PurchaseFContact = this.vendor_list[this.cur_po.VendorIDn].ContactFirstName1 + " " + this.vendor_list[this.cur_po.VendorIDn].ContactLastName1;
        this.cur_po.PurchaseFTEL = this.vendor_list[this.cur_po.VendorIDn].TEL;
        this.cur_po.PurchaseFExt = this.vendor_list[this.cur_po.VendorIDn].ContactExt1;
        this.cur_po.PurchaseFFAX = this.vendor_list[this.cur_po.VendorIDn].FAX;
        this.cur_address_list = this.vendor_list[this.cur_po.VendorIDn].Address;
        
        // Guard clause: in case of the Carrier does not exist in database
        if(this.carrier_list[this.vendor_list[this.cur_po.VendorIDn].CarrierIDn] ){
          this.cur_po.CarrierIDn = this.vendor_list[this.cur_po.VendorIDn].CarrierIDn;
        } else {
          this.cur_po.CarrierIDn = 0;
        }
        for(let i=0; i<Object.values(this.carrier_list).length; i++){
          if(Object.values(this.carrier_list)[i].IDn === this.cur_po.CarrierIDn){
            this.cur_po.CarrierName = Object.values(this.carrier_list)[i].CarrierName;
          }
        }

        // Guard cluase: in case of Vendors with no address
        if(this.cur_address_list.length > 0){
          this.cur_po.PurchaseFAddressIDn = this.cur_address_list[0].IDn;
        }
        for (let k in this.cur_address_list) {
          if (this.cur_address_list[k].BillingFlag) {
            this.cur_po.PurchaseFAddressIDn = this.cur_address_list[k].IDn;
          }
        }
      } else {
        this.cur_po.PurchaseFName = "";
        this.cur_po.PurchaseFOtherName = "";
        this.cur_po.PurchaseFContact = "";
        this.cur_po.PurchaseFTEL = "";
        this.cur_po.PurchaseFExt = "";
        this.cur_po.PurchaseFFAX = "";
        this.cur_address_list = [];
        this.cur_po.PurchaseFAddressIDn = 0;
      }
      this.setOrderAndArrivalDate();
      this.set_purchasef_address();
      this.refresh_product();
    },
    set_purchasef_address() {
      let value = null;
      for (let key in this.cur_address_list) {
        if (this.cur_address_list[key].IDn == this.cur_po.PurchaseFAddressIDn) {
          value = {};
          for (let key2 in this.cur_address_list[key]) {
            value[key2] = this.cur_address_list[key][key2];
          }
          break;
        }
      }
      if (value) {
        this.cur_po.PurchaseFAddressIDn = value.IDn;
        // this.cur_po.PurchaseFName = value.Name;
        this.cur_po.PurchaseFAddress1 = value.Street1;  
        this.cur_po.PurchaseFAddress2 = value.Street2;
        this.cur_po.PurchaseFCity = value.AddressCity;
        this.cur_po.PurchaseFProvinceIDn = Number(value.ProvinceIDn);
        this.cur_po.PurchaseFProvince = this.get_province(value.ProvinceIDn);
        this.cur_po.PurchaseFCountryIDn = Number(value.CountryIDn);
        this.cur_po.PurchaseFCountry = this.get_country(value.CountryIDn);
        this.cur_po.PurchaseFZIP = value.ZIP;      
      } else {
        this.cur_po.PurchaseFAddressIDn = 0;
        this.cur_po.PurchaseFName = "";
        this.cur_po.PurchaseFAddress1 = "";  
        this.cur_po.PurchaseFAddress2 = "";
        this.cur_po.PurchaseFCity = "";
        this.cur_po.PurchaseFProvinceIDn = 2;
        this.cur_po.PurchaseFProvince = "";
        this.cur_po.PurchaseFCountryIDn = 1;
        this.cur_po.PurchaseFCountry = "";
        this.cur_po.PurchaseFZIP = "";
      }
      this.changed_country(this.cur_po.PurchaseFCountryIDn, 1);
    },
    get_province(idn) {
      if (this.province_list[idn] != undefined) {
        return this.province_list[idn].ProvinceID;
      }
      return "";
    },
    get_country(idn) {
      if (this.country_list[idn] != undefined) {
        return this.country_list[idn].CountryID;
      }
      return "";
    },
    store_billing_changed() {
      if (this.cur_po.LocationID == "undefined") return;
      let cur_store = null;
      for (let key in this.store_list) {
        if (this.store_list[key].LocationID == this.cur_po.LocationID) {
          cur_store = {};
          for (let key2 in this.store_list[key]) {
            cur_store[key2] = this.store_list[key][key2];
          }
          break;
        }
      }
      if (cur_store) {
        this.cur_po.ComTitle = cur_store.LocationName || "";
        this.cur_po.ComContact = cur_store.Contact || "";
        this.cur_po.ComAddress1 = cur_store.LocationAddress1 || "";
        this.cur_po.ComAddress2 = cur_store.LocationAddress2 || "";
        this.cur_po.ComCity = cur_store.LocationCity || "";
        this.cur_po.ComProvinceIDn = Number(cur_store.ProvinceIDn) || 0;
        this.cur_po.ComCountryIDn = Number(cur_store.CountryIDn) || 0;
        this.cur_po.ComProvince = cur_store.ProvinceIDn
          ? this.province_list[cur_store.ProvinceIDn].Province
          : "";
        this.cur_po.ComProvinceIDn = Number(cur_store.ProvinceIDn);
        this.cur_po.ComCountry = cur_store.CountryIDn
          ? this.country_list[cur_store.CountryIDn].Country
          : "";
        this.cur_po.ComZIP = cur_store.LocactionZIP || "";
        this.cur_po.ComTEL = cur_store.LocationTel || "";
        this.cur_po.ComExt = cur_store.LocationTelExt || "";
        this.cur_po.ComFAX = cur_store.LocationFax || "";
        this.changed_country(this.cur_po.ComCountryIDn, 2);
      } else {
        this.cur_po.LocationID = "";
      }
      if(this.cur_po.VendorIDn && this.cur_po_product.length > 0){
        this.update_po_info();
      }
      this.$forceUpdate();
    },
    store_shipping_changed() {
      let cur_store = null;
      for (let key in this.store_list) {
        if (this.store_list[key].LocationID == this.cur_po.ShipTLocationID) {
          cur_store = {};
          for (let key2 in this.store_list[key]) {
            cur_store[key2] = this.store_list[key][key2];
          }
          break;
        }
      }
      if (cur_store) {
      this.cur_po.ShipTTitle = cur_store.LocationName || "";
      this.cur_po.ShipTContact = cur_store.Contact || "";
      this.cur_po.ShipTAddress1 = cur_store.LocationAddress1 || "";
      this.cur_po.ShipTAddress2 = cur_store.LocationAddress2 || "";
      this.cur_po.ShipTCity = cur_store.LocationCity || "";
      this.cur_po.ShipTProvinceIDn = Number(cur_store.ProvinceIDn) || 0;
      this.cur_po.ShipTCountryIDn = Number(cur_store.CountryIDn) || 0;
      this.cur_po.ShipTProvince = cur_store.ProvinceIDn
        ? this.province_list[cur_store.ProvinceIDn].Province
        : "";
      this.cur_po.ShipTCountry = cur_store.CountryIDn
        ? this.country_list[cur_store.CountryIDn].Country
        : "";
      this.cur_po.ShipTZIP = cur_store.LocactionZIP || "";
      this.cur_po.ShipTTEL = cur_store.LocationTel || "";
      this.cur_po.ShipTExt = cur_store.LocationTelExt || "";
      this.cur_po.ShipTFAX = cur_store.LocationFax || "";
      this.changed_country(this.cur_po.ShipTCountryIDn, 3);
      } else {
        this.cur_po.ShipTLocationID = "";
      }
      var bodyFormData1 = new FormData();
      bodyFormData1.append("token", this.token);
      bodyFormData1.append("user_id", this.user_id);
      bodyFormData1.append("LocationID", this.cur_po.ShipTLocationID);

      // refresh inventory
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO/inventory",
        data: bodyFormData1,
      })
      .then(function (response) {
        if (response.status == "200") {
          var rt = response.data;
          if (rt.status == 0) {
            that.inventory_products = rt.data.inventory_products;
            that.on_order_products_list = rt.data.on_order_products_list;
            that.on_hold_products_list = rt.data.on_hold_products_list;
            that.refresh_product_with_inventory();
          } else if (rt.status == 4) {
            alert(that.$t("No Permission"));
            that.$router.go(-1);
          } else if (rt.message != undefined) {
            alert(rt.message);
          }
        } else {
          that.$router.push("/login");
        }
      })
      .catch(function (error) {
        // console.log(error);
        that.$router.push("/login");
      });

      // change locationid in PO products
      // for (let k in this.cur_po_product) {
      //   this.cur_po_product[k].LocationID = this.cur_po.ShipTLocationID;
      // }
      this.$forceUpdate();
    },
    addContainer(){
      if(this.cur_po.Status !== "Draft"){
        return;
      }
      this.containerList.push({"ContainerNumber":''});
    },
    removeContainer(idx){
      if(this.cur_po.Status !== "Draft"){
        return;
      }
      if(this.containerList.length > 1){
        this.containerList.splice(idx, 1);
      }
    },
    encode_container_string(){
      this.cur_po.ContainerNo = "";
      for(let i=0; i<this.containerList.length; i++){
        let temp = Object.values(this.containerList[i]);
        this.cur_po.ContainerNo += temp[0];
        if(i != this.containerList.length - 1){
          this.cur_po.ContainerNo += ","
        }
      }
    },
    decode_container_string(){
      const tempArray = this.cur_po.ContainerNo.split(",");  // We use "," as the delimiter during encoding
      if(tempArray.length === 0){
        this.containerList = [{"ContainerNumber":''}];
        return;
      } else {
        this.containerList = [];
        for(let i=0; i<tempArray.length; i++){
          this.containerList.push({"ContainerNumber":tempArray[i]});
        }
      }
    },
    get_CarrierName(){
      if(this.cur_po.CarrierIDn && 
         this.cur_po.CarrierIDn>0 && 
         this.carrier_list[this.cur_po.CarrierIDn] )
      {
        this.cur_po.CarrierName = this.carrier_list[this.cur_po.CarrierIDn].CarrierName;
      }
    },
    delay_search_product() {
      if (this.delay_search_timer) {
        clearTimeout(this.delay_search_timer);
        this.delay_search_timer = null;
      }
      var that = this;
      this.delay_search_timer = setTimeout(() => {
        that.search_product();
      }, 400);
    },
    matching_product(prod) {
      if ((!prod.PLU
          || this.pro_search_plu.length < 2
          || prod.PLU.search(this.pro_search_plu) >= 0) 
          &&
          (!prod.Name1
          || !prod.Name2
          || this.pro_search_name.length < 2
          || prod.Name1.search(this.pro_search_name) >= 0
          || prod.Name2.search(this.pro_search_name) >= 0)
          &&
          (!prod.Description
          || this.pro_search_description.length < 2
          || prod.Description.search(this.pro_search_description) >= 0)
          &&
          (!prod.Qty
          || this.pro_search_qty.length < 1
          || prod.Qty.search(this.pro_search_qty) >= 0)
          &&
          (!prod.OnHold
          || this.pro_search_onhold.length < 1
          || prod.OnHold.search(this.pro_search_onhold) >= 0)
          &&
          (!prod.OnOrder
          || this.pro_search_onorder.length < 1
          || prod.OnOrder.search(this.pro_search_onorder) >= 0)) {
        return true;
      }
      return false;
    },
    remove_po_product(idx, row) {
      if(this.cur_po.Status != 'Complete' && this.cur_po.Status != 'Deleted' && !this.orderLock){
        this.po_qty_changed = true;
        row.OrderQty = 0;
        this.cur_po_product.push(this.cur_po_product.splice(idx, 1)[0]);
        this.updatePOProductAmounts(row);
      }
    },
    search_product() {
      this.cur_listed_products = [];
      this.show_listed_products = true;
      // if (this.cur_searched_products.length > 0) {
        if (this.pro_search_plu.length < 2
            && this.pro_search_name.length < 2
            && this.pro_search_description.length < 2
            && this.pro_search_qty.length < 1
            && this.pro_search_onhold.length < 1
            && this.pro_search_onorder.length < 1) {
          this.cur_listed_products = this.cur_searched_products.slice();
        } else {
          for (var i = 0; i < this.cur_searched_products.length; i++) {
            if (this.matching_product(this.cur_searched_products[i])) {
              this.cur_listed_products.push(this.cur_searched_products[i]);
            }
          }
        }
      // } else {
      //   if (this.cur_search_input.length < 2) {
      //     for (var i = 0; i < this.cur_product_list.length; i++) {
      //       for (var j = 0; j < this.cur_product_list[i].Categorys.length; j++) {
      //         for (
      //           var k = 0;
      //           k < this.cur_product_list[i].Categorys[j].Products.length;
      //           k++
      //         ) {
      //           this.cur_listed_products.push(
      //             this.cur_product_list[i].Categorys[j].Products[k]
      //           );
      //         }
      //       }
      //     }
      //   }
      //   for (var i = 0; i < this.cur_product_list.length; i++) {
      //     for (var j = 0; j < this.cur_product_list[i].Categorys.length; j++) {
      //       for (
      //         var k = 0;
      //         k < this.cur_product_list[i].Categorys[j].Products.length;
      //         k++
      //       ) {
      //         if (
      //           this.matching_product(
      //             this.cur_product_list[i].Categorys[j].Products[k]
      //           )
      //         ) {
      //           this.cur_listed_products.push(
      //             this.cur_product_list[i].Categorys[j].Products[k]
      //           );
      //         }
      //       }
      //     }
      //   }
      // }
    },
    quickAdd_enter_keydown_handler(){
      // The 'Enter' key is pressed
      this.quickAdd();
    },
    quickAdd(){
      // Check if user has selected a Vendor
      if ((this.cur_po.VendorIDn == undefined) || (this.cur_po.VendorIDn <= 0)) {
        alert("Please select vendor first");
        return;
      }
      // Reset cascader_selected
      this.cascader_selected = [];
      this.reset_search_parameters();

      // Construct cur_product_list
      let arr = JSON.parse(JSON.stringify(this.full_product_list));
      let temp = {"IDn": "0", "DisplayName": " ", "Categorys": {"Products": {}}};
      arr[arr.length] = temp;
      for(var i = 0; i < arr.length; i++){
        if(i == 0){
          arr[i] = arr[arr.length - 1];
        } else {
          arr[i] = this.full_product_list[i-1]
        }
        if(arr[i].Name1 && arr[i].Name2){
          arr[i].DisplayName = arr[i].Name1 + " - " + arr[i].Name2;
        }
      }
      this.cur_product_list = arr;

      // Search for products that contain keyword in PLU/Barcode/Name1/Name2
      var matching_products = [];
      var total_num_of_products = 0;
      for(let i=0; i<this.full_product_list.length; i++){
        for(let j=0; j<this.full_product_list[i].Categorys.length; j++){
          for(let k=0; k<this.full_product_list[i].Categorys[j].Products.length; k++){
            total_num_of_products++;
            if((this.full_product_list[i].Categorys[j].Products[k].Name1 && this.full_product_list[i].Categorys[j].Products[k].Name1.toLowerCase().includes(this.quick_add_input.toLowerCase())) || 
               (this.full_product_list[i].Categorys[j].Products[k].Name2 && this.full_product_list[i].Categorys[j].Products[k].Name2.toLowerCase().includes(this.quick_add_input.toLowerCase())) || 
               String(this.full_product_list[i].Categorys[j].Products[k].PLU).toLowerCase().includes(this.quick_add_input.toLowerCase()) || 
               String(this.full_product_list[i].Categorys[j].Products[k].Barcode).toLowerCase().includes(this.quick_add_input.toLowerCase()) )
            {
              matching_products.push(this.full_product_list[i].Categorys[j].Products[k]);
              // this.cascader_selected.push([i,j,k]); // Update cascader_selected
            }
          }
        }
      }

      // If all products are matching products, reset cascader_selected
      if( matching_products.length === total_num_of_products ){
        this.cascader_selected = [];
      }

      if(matching_products.length == 0){          // If no matching products
        alert("No results found for keyword '" + this.quick_add_input + "'");
        this.quick_add_input = '';
        return;
      } else if(matching_products.length == 1){   // If 1 matching product
        // add item to list
        this.add_order_product(0, matching_products[0]);
        this.submit_product_selection();
        this.quick_add_input = '';
      } else {                                    // If 2 or more matching products
        // UI fix
        this.calculate_el_select_width3();

        // search (PLU/Barcode/Name)
        this.build_product(matching_products);

      }
    },
    search_department(dept) {
      return dept.IDn == this.cur_search_departmentIDn;
    },
    search_category(cate) {
      return cate.IDn == this.cur_search_categoryIDn;
    },
    remove_product(prod, idx) {
      
      // Remove prod from selected_product_list
      for (var i = 0; i < this.selected_product_list.length; i++) {
        if (this.selected_product_list[i].ProductIDn == prod.ProductIDn) {
          this.selected_product_list.splice(i, 1);
          break;
        }
      }

      this.sync_cascader_selected();
    },
    calculate_product_total(prod) {
      prod.TotalCost = (
        parseFloat(prod.UnitCost) * parseFloat(prod.OrderQty)
      ).toFixed(2);
      this.$nextTick(() => {
        //this.selected_product_list = order;
      });
    },
    add_order_product(index, data) {
      // event.preventDefault();
      // if (this.selected_product_list.length > 0) {
      //   // Check Product existing
      //   for (var i = 0; i < this.selected_product_list.length; i++) {
      //     if (this.selected_product_list[i].ProductIDn == row.IDn) {
      //       alert("Added already");
      //       return;
      //     }
      //   }
      // }
      var orgprice = data.APrice;
      var price;
      if (data.UnitCost != undefined) {
        price = data.UnitCost;
      } else {
        if (this.vendor_list[this.cur_po.VendorIDn].PriceList == "B")
          orgprice = data.BPrice;
        else if (
          this.vendor_list[this.cur_po.VendorIDn].PriceList == "C"
        )
          orgprice = data.CPrice;
        else if (
          this.vendor_list[this.cur_po.VendorIDn].PriceList == "D"
        )
          orgprice = data.DPrice;
        else if (
          this.vendor_list[this.cur_po.VendorIDn].PriceList == "E"
        )
          orgprice = data.EPrice;
        else if (
          this.vendor_list[this.cur_po.VendorIDn].PriceList == "F"
        )
          orgprice = data.FPrice;
        else if (
          this.vendor_list[this.cur_po.VendorIDn].PriceList == "G"
        )
          orgprice = data.GPrice;
        else if (
          this.vendor_list[this.cur_po.VendorIDn].PriceList == "H"
        )
          orgprice = data.HPrice;
        else if (
          this.vendor_list[this.cur_po.VendorIDn].PriceList == "I"
        )
          orgprice = data.IPrice;
        else if (
          this.vendor_list[this.cur_po.VendorIDn].PriceList == "J"
        )
          orgprice = data.JPrice;
        price = orgprice;
      }
      let prod = {
        IDn: 0,
        key: 0,
        ProductIDn: data.IDn,
        PLU: data.PLU,
        Barcode: data.Barcode,
        Name1: data.Name1,
        Name2: data.Name2,
        Unit: data.Unit,
        Weigh: data.Weigh,
        HasSerialNumber: data.HasSerialNumber,
        UnitCost: price,
        OrderQty: 1,
        CurOrderQty: 1,
        TotalCost: 0,
        StoreQty: data.StoreQty,
        StoreCost: data.StoreCost,
        Status: "Draft", // Delete, Post, Draft
        Remarks: "",
        ReferenceIDn: 0,
      };
      this.selected_product_list.unshift(prod);
      this.updatePOBalance(this.cur_po);
      this.sync_cascader_selected();
    },
    add_order_product_array(data){
      for(var i=0; i<data.length; i++){
        var orgprice = data[i].APrice;
        var price;
        if (data[i].UnitCost != undefined) {
          price = data[i].UnitCost;
        } else {
          if (this.vendor_list[this.cur_po.VendorIDn].PriceList == "B")
            orgprice = data[i].BPrice;
          else if (this.vendor_list[this.cur_po.VendorIDn].PriceList == "C")
            orgprice = data[i].CPrice;
          else if (this.vendor_list[this.cur_po.VendorIDn].PriceList == "D")
            orgprice = data[i].DPrice;
          else if (this.vendor_list[this.cur_po.VendorIDn].PriceList == "E")
            orgprice = data[i].EPrice;
          else if (this.vendor_list[this.cur_po.VendorIDn].PriceList == "F")
            orgprice = data[i].FPrice;
          else if (this.vendor_list[this.cur_po.VendorIDn].PriceList == "G")
            orgprice = data[i].GPrice;
          else if (this.vendor_list[this.cur_po.VendorIDn].PriceList == "H")
            orgprice = data[i].HPrice;
          else if (this.vendor_list[this.cur_po.VendorIDn].PriceList == "I")
            orgprice = data[i].IPrice;
          else if (this.vendor_list[this.cur_po.VendorIDn].PriceList == "J")
            orgprice = data[i].JPrice;
          price = orgprice;
        }
        let prod = {
          IDn: 0,
          key: 0,
          ProductIDn: data[i].IDn,
          PLU: data[i].PLU,
          Barcode: data[i].Barcode,
          Name1: data[i].Name1,
          Name2: data[i].Name2,
          Unit: data[i].Unit,
          Weigh: data[i].Weigh,
          HasSerialNumber: data[i].HasSerialNumber,
          UnitCost: price,
          OrderQty: 1,
          CurOrderQty: 1,
          TotalCost: 0,
          StoreQty: data[i].StoreQty,
          StoreCost: data[i].StoreCost,
          Status: "Draft", // Delete, Post, Draft
          Remarks: "",
          ReferenceIDn: 0,
        };
        this.selected_product_list.unshift(prod);
      }
    },
    sync_cascader_selected(){
      // Sync cascader_selected with selected_product_list
      let temparr = [];
      for(var a=0; a<this.selected_product_list.length; a++){
        full_product_list_loop:
        for(let i=0; i<this.full_product_list.length; i++){
          for(let j=0; j<this.full_product_list[i].Categorys.length; j++){
            for(let k=0; k<this.full_product_list[i].Categorys[j].Products.length; k++){
              if(this.full_product_list[i].Categorys[j].Products[k].IDn == this.selected_product_list[a].ProductIDn){
                if(!this.cascader_selected.includes([i, j, k])){
                  temparr.push([i,j,k]);
                  break full_product_list_loop;
                }
              }
            }
          }
        }
      }
      this.cascader_selected = temparr;
    },
    add_draft_po() {
      this.orderLock = false;
      this.cur_po = JSON.parse(JSON.stringify(this.new_po));
      this.cur_vendor_name = "";
      this.cur_carrier_name = "";
      this.containerList = [{"ContainerNumber":''}];
      // Use first store as Default Store
      // this.cur_po.LocationID = this.store_list[Object.keys(this.store_list)[0]].LocationID;
      this.cur_po.LocationID = this.store_list[0].LocationID;
      this.cur_po.ShipTLocationID = this.store_list[0].LocationID;
      this.store_shipping_changed();
      this.store_billing_changed();

      this.activeCollapse1 = ["AddressInfo", "PODetail", "ProductList"];
      this.activeCollapse2 = [];
      this.showReturn = false;
      this.showReceive = false;
      this.showOutbound = false;
      this.showInbound = false;
      this.$refs["product-list-modal"].hide();

      this.currpage = "edit";
      this.changed_country(1,1);
      this.changed_country(1,2);
      this.changed_country(1,3);
      this.disable_vendor_input = true;
      this.disable_billing_input = true;
      this.disable_shipping_input = true;

      setTimeout(()=>{
        this.calculate_el_select_width2()
      },100);
      this.setOrderAndArrivalDate();
      this.showPayment = false;

      this.get_extra();
    },
    close_my_modal() {
      this.selected_product_list = [];
      this.quick_add_input = '';
      this.$refs["product-list-modal"].hide();
    },
    close_import_modal() {
      this.$refs["import-list-modal"].hide();
    },
    close_add_payment_model() {
      this.$refs["add-payment-modal"].hide();
    },
    close_add_credit_model() {
      this.$refs["add-credit-modal"].hide();
    },
    close_add_return_model(){
      this.$refs["add-return-modal"].hide();
    },
    close_select_sn_model(){
      this.$refs["select-sn-modal"].hide();
    },
    close_add_product_model(){
      this.$refs["add-product-modal"].hide();
    },
    convertStringToInt(row) {
      row.PayPeriodIDn = Number(row.PayPeriodIDn);
      row.CurrencyIDn = Number(row.CurrencyIDn);
      row.PurchaseFProvinceIDn = Number(row.PurchaseFProvinceIDn);
      row.PurchaseFCountryIDn = Number(row.PurchaseFCountryIDn);
      row.ShipTProvinceIDn = Number(row.ShipTProvinceIDn);
      row.ShipTCountryIDn = Number(row.ShipTCountryIDn);
      row.ComCountryIDn = Number(row.ComCountryIDn);
      row.ComProvinceIDn = Number(row.ComProvinceIDn);
      return row;
    },
    edit_po(row, column, event) {
      if (!hasUtilityAuth('POEdit')) return;
      this.cur_po = JSON.parse(JSON.stringify(this.convertStringToInt(row)));
      this.showPayment = false;
      this.loadingPOInfo = true;
      this.disable_vendor_input=true;
      if(this.cur_po.Status == 'Draft'){
        this.orderLock = false;
      } else {
        this.orderLock = true;
      }
      // console.log("@edit_po row", row);

      this.set_edit_po();
    },
    set_edit_po() {
      this.po_qty_changed = false;

      this.activeCollapse1 = ["ProductList"];
      this.activeCollapse2 = [];
      this.showReturn = false;
      this.showReceive = false;
      this.showOutbound = false;
      this.showInbound = false;
      this.$refs["product-list-modal"].hide();
      switch (this.cur_po.Status) {
        case "Draft":
          this.changed_country(this.cur_po.PurchaseFCountryIDn,1);
          this.changed_country(this.cur_po.ComCountryIDn,2);
          this.changed_country(this.cur_po.ShipTCountryIDn,3);
          break;
        case "Pending":
          this.showReturn = true;
          this.showReceive = true;
          this.showOutbound = true;
          this.showInbound = true;
          break;
        case "Complete":
          this.showReturn = true;
          this.showReceive = true;
          this.showOutbound = true;
          this.showInbound = true;
          break;
        case "Shipped":
          break;
        case "Deleted":
        case "Back":
        // case "PreOrder":
        case "Contract":
        case "Special":
          break;
      }

      this.currpage = "edit";
      this.find_vendor_name();
      this.get_CarrierName();
      this.decode_container_string();
      this.get_extra();
      this.reset_search_parameters();
      if(this.cur_po.Status == "Draft" && this.cur_po.POIDn != 0) {
        this.setPurchaseFAddressIDn();
      }
      this.checkLoadingState("loadingPOInfo");
    },
    setPurchaseFAddressIDn() {
      this.cur_address_list = this.vendor_list[this.cur_po.VendorIDn].Address;
      if(this.cur_address_list.length > 0){
        for (let key in this.cur_address_list) {
          if (this.cur_address_list[key].Street1 == this.cur_po.PurchaseFAddress1 &&
              this.cur_address_list[key].Street2 == this.cur_po.PurchaseFAddress2 &&
              this.cur_address_list[key].AddressCity == this.cur_po.PurchaseFCity &&
              this.cur_address_list[key].ProvinceIDn == this.cur_po.PurchaseFProvinceIDn &&
              this.cur_address_list[key].CountryIDn == this.cur_po.PurchaseFCountryIDn &&
              this.cur_address_list[key].ZIP == this.cur_po.PurchaseFZIP ) {
            this.cur_po.PurchaseFAddressIDn = this.cur_address_list[key].IDn;
            break;
          }
        }
      }
    },
    async build_product(data) {
      this.cur_listed_products = [];
      this.cascader_search_array = [];
      if ( (this.cur_po.LocationID == undefined) || (this.cur_po.LocationID == '') ) {
        alert("Please select billing address first");
        return;
      }
      if ( (this.cur_po.ShipTLocationID == undefined) || (this.cur_po.ShipTLocationID == '') ){
        alert("Please select shipping address first");
        return;
      }
      if ((this.cur_po.VendorIDn == undefined) || (this.cur_po.VendorIDn <= 0)) {
        alert("Please select vendor first");
        return;
      }
      if(data && data!=undefined){
        this.cur_listed_products = data;
        this.cascader_search_array = data;
      } else {
        for (var i = 0; i < this.cur_product_list.length; i++) {
          for (var j = 0; j < this.cur_product_list[i].Categorys.length; j++) {
            for (var k = 0; k < this.cur_product_list[i].Categorys[j].Products.length; k++) {
              this.cur_listed_products.push(
                this.cur_product_list[i].Categorys[j].Products[k]
              );
              this.cascader_search_array.push(
                this.cur_product_list[i].Categorys[j].Products[k]
              );
            }
          }
        }
      }
      for(var i=0; i<this.cur_listed_products.length; i++){

        // Set StoreQty        
        Object.values(this.inventory_products).forEach(dept => {
          Object.values(dept).forEach(cate => {
            Object.values(cate).forEach(prod => {
              if(prod.IDn == this.cur_listed_products[i].IDn){
                this.cur_listed_products[i].StoreQty = prod.StoreQty;
              }
            });
          });
        });

        // Set OnOrderQty for each product
        if(this.on_order_products_list){
          Object.entries(this.on_order_products_list).forEach(entry => {
            const [key, value] = entry;
            if(this.cur_listed_products[i].IDn == key){
              this.cur_listed_products[i].OnOrderQty = Number(value).toFixed(2);
            }
          });
        }
        if(!this.cur_listed_products[i].OnOrderQty){
          this.cur_listed_products[i].OnOrderQty = "0.00";
        }
        // Set OnHoldQty for each product
        if(this.on_hold_products_list){
          Object.entries(this.on_hold_products_list).forEach(entry => {
            const [key, value] = entry;
            if(this.cur_listed_products[i].IDn == key){
              this.cur_listed_products[i].OnHoldQty = Number(value).toFixed(2);
            }
          });
        }
        if(!this.cur_listed_products[i].OnHoldQty){
          this.cur_listed_products[i].OnHoldQty = "0.00";
        }

      }
      this.reset_search_parameters();
      this.show_listed_products = true;
      this.loadingProducts = true;
      this.$refs["product-list-modal"].show();
      this.fakeLoading("loadingProducts", 500);
      // console.log("@build_product this.full_product_list", this.full_product_list);
    },
    async add_product() {
      this.loadingProducts = true;
      let arr = JSON.parse(JSON.stringify(this.full_product_list));
      let temp = {"IDn": "0", "DisplayName": " ", "Categorys": {"Products": {}}};
      arr[arr.length] = temp;
      for(var i = 0; i < arr.length; i++){
        if(i == 0){
          arr[i] = arr[arr.length - 1];
        } else {
          arr[i] = this.full_product_list[i-1]
        }
        if(arr[i].Name1 && arr[i].Name2){
          arr[i].DisplayName = arr[i].Name1 + " - " + arr[i].Name2;
        }
      }
      this.cur_product_list = arr;
      this.cascader_selected = [];
      this.reset_search_parameters();
      this.calculate_el_select_width3();
      this.build_product();
    },
    async add_product2(prod) {
      // console.log("@add_product2 this.full_product_list", this.full_product_list);
      for(var i=0; i<this.cur_product_list.length; i++){
        if(this.cur_product_list[i].IDn == prod.DepartmentIDn){
          for(var j=0; j<this.cur_product_list[i].Categorys.length; j++){
            if(this.cur_product_list[i].Categorys[j].IDn == prod.CategoryIDn){
              this.cur_product_list[i].Categorys[j].Products.push(prod);
            }
          }
        }
      }
      this.build_product();
    },
    async import_product() {
      this.po_qty_changed = true;
      this.selected_product_list = [];
      this.$refs["import-list-modal"].show();
    },
    async last_order_product() {
      this.cur_product_list = [];
      for (var key1 in this.buy_product_list) {
        let dept = JSON.parse(JSON.stringify(this.buy_product_list[key1]));
        dept.Categorys = [];
        for (var key2 in this.buy_product_list[key1].Categorys) {
          let cate = JSON.parse(JSON.stringify(this.buy_product_list[key1].Categorys[key2]));
          //cate.Products = Object.values(this.buy_product_list[key1].Categorys[key2].Products);
          cate.Products = [];
          for (var key3 in this.buy_product_list[key1].Categorys[key2].Products) {
            let product = JSON.parse(JSON.stringify(this.buy_product_list[key1].Categorys[key2].Products[key3]));
            if (this.inventory_products.hasOwnProperty(key1) &&
                this.inventory_products[key1].hasOwnProperty(key2) &&
                this.inventory_products[key1][key2].hasOwnProperty(key3)) {
              product['StoreQty'] = this.inventory_products[key1][key2][key3].StoreQty;
              product['StoreCost'] = this.inventory_products[key1][key2][key3].StoreCost;
            } else {
              product['StoreQty'] = 0;
              product['StoreCost'] = 0;
            }
            cate.Products.push(product);
          }
          dept.Categorys.push(cate);
        }
        this.cur_product_list.push(dept);
      }
      return this.build_product();
    },
    test(){
      // console.log("Hi");
    },
    submit_product_selection() {
      this.po_qty_changed = true;

      var po_product;
      for (let k in this.selected_product_list) {
        po_product = {};
        po_product["IDn"] = 0;
        po_product["POIDn"] = this.cur_po.POIDn;
        po_product["VendorIDn"] = this.cur_po.VendorIDn;
        po_product["LocationID"] = this.cur_po.ShipTLocationID;
        po_product["ProductIDn"] = this.selected_product_list[k]["ProductIDn"];
        po_product["InventoryIDn"] = "";
        po_product["PLU"] = this.selected_product_list[k]["PLU"];
        po_product["Barcode"] = this.selected_product_list[k]["Barcode"];
        po_product["Name1"] = this.selected_product_list[k]["Name1"];
        po_product["Name2"] = this.selected_product_list[k]["Name2"];
        po_product["OrderQty"] = this.selected_product_list[k]["OrderQty"];
        po_product["CurOrderQty"] = po_product["OrderQty"];
        po_product["Weigh"] = this.selected_product_list[k]["Weigh"];
        po_product["Unit"] = this.selected_product_list[k]["Unit"];
        po_product["UnitCost"] = Number(this.selected_product_list[k]["UnitCost"]).toFixed(2) || "0.00";
        if (po_product["UnitCost"] == "NaN") po_product["UnitCost"] = -1;
        po_product["TotalCost"] = (Number(po_product["UnitCost"]) * Number(po_product["OrderQty"])).toFixed(2) || "0.00"; // Default Order Qty is 1
        if (po_product["TotalCost"] == "NaN") po_product["TotalCost"] = -1;
        po_product["Remarks"] = "";
        po_product["ShippingQty"] = 0;
        po_product["BackQty"] = 0;
        po_product["seqOrder"] = 0;
        po_product["SerialNumbers"] = "";
        po_product["ReferenceIDn"] = "";
        po_product["Type"] = "Local"; //valid values: Local
        po_product["ExpiryDate"] = null; // ???
        po_product["TotalTax"] = 0;
        po_product["JustAdded"] = true;
        this.cur_po_product.push(po_product);
      }
      this.update_po_info();
      this.selected_product_list = [];
      this.quick_add_input = '';
      this.$refs["product-list-modal"].hide();
    },
    manualOverrideComplete(){
      let text = "Important Message:\n\nYou have not received all products in this order.\nDo you wish to manually complete this order?\n\nIf yes, system will change the Order Qty of each product to match your receiving records.\nIf no, nothing will happen.";
      if (confirm(text) == true) {
        // Calculate total received Qty of each product
        // console.log("total_received_product_array", this.total_received_product_array);

        // Change POProduct --OrderQty, TotalCost, Taxes, TotalTax, Total, PendingQty
        for(var i=0; i<this.total_received_product_array.length; i++){
          let POProductFound = false;
          for(var j=0; j<this.cur_po_product.length; j++){
            if(this.total_received_product_array[i].POProductIDn == this.cur_po_product[j].IDn){
              // POProduct found in total_received_product_array, meaning we have received some of this product
              POProductFound = true;
              // Change OrderQty of this.cur_po_product
              this.cur_po_product[j].OrderQty = Number(this.total_received_product_array[i].ReceivedQty);
              this.cur_po_product[j].OrderQty = Number(this.cur_po_product[j].OrderQty).toFixed(2);
              // Change TotalCost of this.cur_po_product
              this.cur_po_product[j].TotalCost = Number(this.cur_po_product[j].OrderQty) * Number(this.cur_po_product[j].UnitCost);
              this.cur_po_product[j].TotalCost = (this.cur_po_product[j].TotalCost).toFixed(2);
              // Change Taxes of this.cur_po_product
              for(var m=0; m<this.cur_po_product[j].Taxes.length; m++){
                if(this.cur_po_product[j].Taxes[m].TaxType == '$'){
                  this.cur_po_product[j].Taxes[m].TaxAmount = (Number(this.cur_po_product[j].Taxes[m].TaxValue) * Number(this.cur_po_product[j].OrderQty)).toFixed(2);
                } else {
                  this.cur_po_product[j].Taxes[m].TaxAmount = (Number(this.cur_po_product[j].Taxes[m].TaxValue) * Number(this.cur_po_product[j].OrderQty) * 
                                                               Number(this.cur_po_product[j].UnitCost) * 0.01 ).toFixed(2);
                }
              }
              // Change TotalTax of this.cur_po_product
              let totalTax = 0;
              for(var m=0; m<this.cur_po_product[j].Taxes.length; m++){
                totalTax += Number(this.cur_po_product[j].Taxes[m].TaxAmount);
              }
              this.cur_po_product[j].TotalTax = Number(totalTax).toFixed(2);
              // Change Total of this.cur_po_product
              this.cur_po_product[j].Total = Number(this.cur_po_product[j].TotalTax) + Number(this.cur_po_product[j].TotalCost);
              // Change PendingQty of this.cur_po_product
              this.cur_po_product[j].PendingQty = 0;
              break;
            }
          }
          if(!POProductFound){
            // If POProduct not found in total_received_product_array, meaning we have never received any of this product.
            this.cur_po_product[j].OrderQty = 0;
            this.cur_po_product[j].TotalCost = 0;
            this.cur_po_product[j].TotalTax = 0;
            this.cur_po_product[j].Total = 0;
            this.cur_po_product[j].PendingQty = 0;
          }
        }

        // Change POTax and Change PO, calculate new Balance
        this.updatePOAmounts();

        // Add credit if needed
        let credited = 0;
        let amount = 0;
        if(Number(this.cur_po.Balance) < 0){
          amount = Number(this.cur_po.Balance) * -1;
          this.cur_credit_edit = JSON.parse(JSON.stringify(this.new_credit));
          this.cur_credit_edit["CustomerIDn"] = this.cur_po.VendorIDn;
          this.cur_credit_edit["ReferenceIDn"] = this.cur_po.POIDn;
          this.cur_credit_edit["InvoiceNo"] = this.cur_po.OrderID;
          this.cur_credit_edit["InvoiceAmount"] = this.cur_po.TotalAmount;
          this.cur_credit_edit["OrderType"]  = "PO";
          this.cur_credit_edit["PaymentAmount"] = amount;
          this.cur_credit_edit["PayType"] = "Reload"
          this.cur_credit_edit["PaymentMethodID"] = "Credit"
          this.cur_credit_edit["Remarks"]= " --auto created by " + this.cur_po.OrderID;
          this.add_credit();
          credited = Number(this.cur_po.CreditedTotal) + Number(this.cur_credit_edit["PaymentAmount"]);
          this.cur_po.CreditedTotal = credited.toFixed(2);
          this.cur_po.CreditedAmount = this.cur_po.CreditedTotal;
          this.cur_po.Balance = Number(this.cur_po.TotalAmount) - Number(this.cur_po.PaidAmount) - Number(this.cur_po.ReturnedTotal) + Number(this.cur_po.CreditedTotal);
          this.cur_po.Balance = (this.cur_po.Balance).toFixed(2);
        }
        // Change PO status to complete 
        this.submit_click('Complete');
      } else {
        // Do nothing
      }
    },
    async submit_click(status) {
      // this.cur_is_return = false;
      // this.cur_is_receive = false;
      // this.update_po_info();
      if ((this.cur_po.VendorIDn == undefined) || (this.cur_po.VendorIDn <= 0)) {
        alert("Please select vendor first");
        return;
      }
      this.post_po(status, 'submit_click');
      this.showPayment = true;
      
      // UI control, depends on how fast post_po() finish
      let timesBeforeTimeout = 40;
      const interval = setInterval(()=> {
        this.calculate_el_select_width();
        if(timesBeforeTimeout == 0 || document.getElementsByClassName("el-dropdown").length > 0){
          clearInterval(interval);
        }
        timesBeforeTimeout--;
      }, 50)
      
      //this.currpage = "list";
    },
    cancel_click() {
      if ( this.cur_is_return || this.cur_is_receive) {
        this.show_listed_products = false;
        this.cur_is_return = false;
        this.cur_is_receive = false;
        this.set_edit_po();
        var that = this;
        this.$nextTick(() => {
          that.show_listed_products = true;
        });
      }
      else {
        this.currpage = "list";
      }
      this.showPayment = true;
      setTimeout(()=>{
        this.calculate_el_select_width()
      },50);
    },
    create_vendor_datalist() {
      this.vendor_cname_list = [];
      this.vendor_cname_list.push({IDn:"", CompanyName:""});
      this.vendor_cname_list_activeOnly = [];
      this.vendor_cname_list_activeOnly.push({IDn:"", CompanyName:""});
      for (var key in this.vendor_list) {
        // this.vendor_cname_list.push(this.vendor_list[key].CompanyName + "-" + this.vendor_list[key].IDn);
        let vendor = {IDn:this.vendor_list[key].IDn, CompanyName:this.vendor_list[key].CompanyName};
        this.vendor_cname_list.push(vendor);
        if(this.vendor_list[key].Status == "1"){
          this.vendor_cname_list_activeOnly.push(vendor);
        }
      }
    },
    find_vendor_id() {
      this.cur_vendor_id = 0;
      for (var key in this.vendor_list) {
        if (this.cur_vendor == (this.vendor_list[key].CompanyName + "-" + this.vendor_list[key].IDn)) {
          // found it
          this.cur_vendor_id = this.vendor_list[key].IDn;
          break;
        }
      }
    },
    find_vendor_name() {
      this.cur_vendor_name = '';
      for (var key in this.vendor_list) {
        if (this.cur_po.VendorIDn == this.vendor_list[key].IDn) {
          // found it
          this.cur_vendor_name = this.vendor_list[key].CompanyName;
          break;
        }
      }
    },
    formatTotalAmount(target){
      for(var i=0; i<target.length; i++){
        target[i].TotalAmount = Number(target[i].TotalAmount).toFixed(2);
      }
    },
    reset_search() {
      // clear list page search product input
      document.getElementById("list_search_product").children[0].value = "";
      this.cur_product = "";
      this.cur_product_idn = "";
      this.cur_user_idn = 0;
      this.cur_LocationID = "";
      this.cur_invoice_no = "";
      this.cur_receive_no = "";
      this.cur_order_no = "";
      this.cur_vendor = "";
      this.cur_vendor_id = 0;
      this.cur_remarks = "";
      this.cur_date_type= "";
      this.setStartandEndDate();
      this.get_lists();
    },
    async get_lists() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      var bodyFormData = new FormData();
      var research = 0;
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      if (this.cur_start_dt) {
        bodyFormData.append("start_dt", this.cur_start_dt);
      }
      if (this.cur_end_dt) {
        bodyFormData.append("end_dt", this.cur_end_dt);
      }
      if (this.cur_date_type) {
        bodyFormData.append("cur_date_type", this.cur_date_type);
      }
      if (this.cur_vendor) {
        bodyFormData.append("VendorIDn", this.cur_vendor);
      }
      if (this.cur_order_no) {
        research = 1;
        bodyFormData.append("OrderID", this.cur_order_no);
      }
      if (this.cur_receive_no) {
        research = 1;
        bodyFormData.append("ReceiveOrderNo", this.cur_receive_no);
      }
      if (this.cur_invoice_no) {
        research = 1;
        bodyFormData.append("InvoiceNo", this.cur_invoice_no);
      }
      if (this.cur_remarks) {
        research = 1;
        bodyFormData.append("Remarks", this.cur_remarks);
      }
      if (this.cur_product_idn) {
        research = 1;
        bodyFormData.append("ProductIDn", this.cur_product_idn);
      }
      if (this.cur_LocationID) {
        research = 1;
        bodyFormData.append("LocationID", this.cur_LocationID);
      }
      if (this.cur_user_idn) {
        research = 1;
        bodyFormData.append("CreateByIDn", this.cur_user_idn);
      }
      bodyFormData.append("Status", this.activeName);
      if (research) {
        bodyFormData.append("research", 1);
      }
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO/list",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (rt.data.Status != undefined) {
                that.activeName = rt.data.Status;
              }
              that.cur_po_list = rt.data.list_list;
              that.formatTotalAmount(that.cur_po_list);
              that.cur_po_meta = rt.data.list_meta;
              if (that.cur_po_meta.cnt != undefined) {
                that.page_total = that.cur_po_meta.cnt;
                that.per_page = rt.data.per_page;
              }
              that.loadingTabContent = false;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    refresh_product_with_inventory() {
      var dpt, cat, prd;
      for (var i = 0; i < this.full_product_list.length; i++) {
        dpt = this.full_product_list[i]['IDn'];
        for (var j = 0; j < this.full_product_list[i]["Categorys"].length; j++) {
          cat = this.full_product_list[i]["Categorys"][j]['IDn'];
          for (var k = 0; k < this.full_product_list[i]["Categorys"][j]["Products"].length; k++) {
            prd = this.full_product_list[i]["Categorys"][j]["Products"][k]['IDn'];

            // Update full_product_list StoreQty
            if (this.inventory_products.hasOwnProperty(dpt) &&
                this.inventory_products[dpt].hasOwnProperty(cat) &&
                this.inventory_products[dpt][cat].hasOwnProperty(prd)) {
              this.full_product_list[i]["Categorys"][j]["Products"][k]['StoreQty'] = this.inventory_products[dpt][cat][prd].StoreQty;
            } else {
              this.full_product_list[i]["Categorys"][j]["Products"][k]['StoreQty'] = 0;
            }
          }
        }
      }
      for(var i=0; i<this.cur_listed_products.length; i++){
        
        // Reset StoreQty, OnOrderQty, OnHoldQty for each product
        this.cur_listed_products[i].StoreQty = 0;
        this.cur_listed_products[i].OnOrderQty = 0;
        this.cur_listed_products[i].OnHoldQty = 0;

        // Set StoreQty        
        Object.values(this.inventory_products).forEach(dept => {
          Object.values(dept).forEach(cate => {
            Object.values(cate).forEach(prod => {
              if(prod.IDn == this.cur_listed_products[i].IDn){
                this.cur_listed_products[i].StoreQty = prod.StoreQty;
              }
            });
          });
        });

        // Set OnOrderQty for each product
        if(this.on_order_products_list){
          Object.entries(this.on_order_products_list).forEach(entry => {
            const [key, value] = entry;
            if(this.cur_listed_products[i].IDn == key){
              this.cur_listed_products[i].OnOrderQty = Number(value).toFixed(2);
            }
          });
        }
        if(!this.cur_listed_products[i].OnOrderQty){
          this.cur_listed_products[i].OnOrderQty = "0.00";
        }
        // Set OnHoldQty for each product
        if(this.on_hold_products_list){
          Object.entries(this.on_hold_products_list).forEach(entry => {
            const [key, value] = entry;
            if(this.cur_listed_products[i].IDn == key){
              this.cur_listed_products[i].OnHoldQty = Number(value).toFixed(2);
            }
          });
        }
        if(!this.cur_listed_products[i].OnHoldQty){
          this.cur_listed_products[i].OnHoldQty = "0.00";
        }

      }
    },
    filterVendorlist(){
      let temp_vendorlist = {};
      Object.entries(this.vendor_list).forEach(entry => {
        const [key, value] = entry;
        // console.log(key, value);
        if(value.CustomerType == 'Vendor'){
          temp_vendorlist[key] = value;
        }
      });
      // console.log("temp_vendorlist", temp_vendorlist);
      // console.log("vendor_list", this.vendor_list);
      this.vendor_list = temp_vendorlist;
    },
    async get_init_data() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      if (this.cur_start_dt) {
        bodyFormData.append("start_dt", this.cur_start_dt);
      }
      if (this.cur_end_dt) {
        bodyFormData.append("end_dt", this.cur_end_dt);
      }
      if (this.cur_date_type) {
        bodyFormData.append("cur_date_type", this.cur_date_type);
      }
      if (this.cur_vendor_id) {
        bodyFormData.append("VendorIDn", this.cur_vendor_id);
      }
      if (this.cur_order_no) {
        bodyFormData.append("OrderID", this.cur_order_no);
      }
      if (this.cur_invoice_no) {
        bodyFormData.append("InvoiceNo", this.cur_invoice_no);
      }
      if (this.cur_remarks) {
        bodyFormData.append("Remarks", this.cur_remarks);
      }
      if (this.cur_LocationID) {
        bodyFormData.append("LocationID", this.cur_LocationID);
      }
      if (this.cur_user_idn) {
        bodyFormData.append("CreateByIDn", this.cur_user_idn);
      }
      bodyFormData.append("Status", this.activeName);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_po_list = rt.data.list_list;
              that.formatTotalAmount(that.cur_po_list);
              that.cur_po_meta = rt.data.list_meta;
              if (that.cur_po_meta.cnt != undefined) {
                that.page_total = that.cur_po_meta.cnt;
                that.per_page = rt.data.per_page;
              }
              that.vendor_list = rt.data.vendor_list;
              that.filterVendorlist();
              that.create_vendor_datalist();
              that.pay_period_list = rt.data.pay_period_list;
              that.carrier_list = rt.data.carrier_list;
              that.carrier_names = rt.data.carrier_names;
              that.full_product_list = rt.data.idxproducts;
              that.store_list = rt.data.store_list;
              // Add an empty element to the end of array, then push the empty element to the front
              // that.store_list[that.store_list.length] = {"LocationID": "", "LocationName": ""};
              // var temp = JSON.parse(JSON.stringify(that.store_list));
              // for(var i = 0; i < that.store_list.length; i++){
              //   if(i == 0){
              //     that.store_list[i] = temp[that.store_list.length - 1];
              //   } else {
              //     that.store_list[i] = temp[i - 1];
              //   }
              // }
              that.sales_list = rt.data.sales_list;
              that.country_list = rt.data.country_list;
              that.province_list = rt.data.province_list;
              that.city_list = rt.data.city_list;
              that.po_show_name2 = rt.data.po_show_name2;
              that.unitlist = rt.data.unitlist;
              that.tax_typelist = rt.data.tax_types;
              that.weigh_unit_list = that.get_typed_unit_list("Weight");
              that.loadingTabContent = false;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_extra() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);
      bodyFormData.append("POIDn", this.cur_po.POIDn);
      bodyFormData.append("LocationID", this.cur_po.ShipTLocationID);
      bodyFormData.append("CustomerIDn", this.cur_po.VendorIDn);

      this.loaded_return_data = false;
      this.loaded_receive_data = false;

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO/extra",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_po_tax = rt.data.po_tax;
              that.cur_po_product = rt.data.po_product;
              that.cur_po_receive = rt.data.po_receive;
              that.cur_po_return = rt.data.po_return;
              that.inventory_products = rt.data.inventory_products;
              that.refresh_product_with_inventory();
              that.$nextTick(() => {
                if (that.cur_po_return.length > 0) {
                  that.loaded_return_data = true;
                }
                if (that.cur_po_receive.length > 0) {
                  that.loaded_receive_data = true;
                }
              });
              that.checkCompletedRE(that.cur_po_receive);
              that.getCompletedRE(that.cur_po_receive);
              that.getCompletedPR(that.cur_po_return);
              that.set_cur_po_ReturnedAmount();
              // that.set_display_PR_array();
              that.add_cur_order_qty();
              that.add_cur_product_qty();
              that.cur_po_payment = rt.data.po_payment;
              that.cur_po_credit = rt.data.po_credit;
              that.set_cur_po_CreditedAmount();
              that.cur_outbound = rt.data.outbound;
              that.cur_inbound = rt.data.inbound;
              that.payment_method_list = rt.data.payment_method_list;
              that.payment_type_list = rt.data.payment_type_list;
              that.credit_type_list = rt.data.credit_type_list;
              that.vendor_credit = rt.data.vendor_credit;
              that.all_SN_product_array = rt.data.all_SN_products;
              that.on_order_products_list = rt.data.on_order_products_list;
              that.on_hold_products_list = rt.data.on_hold_products_list;
              that.full_product_list = rt.data.idxproducts;
              that.set_cascader_options();
              that.sync_cascader_selected();
              try {
                that.cur_po.CreditedAmount = JSON.parse(JSON.stringify(that.cur_po.CreditedTotal));
              } catch {
                that.cur_po.CreditedAmount = 0;
              }
              that.loadingPOInfo = false;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_vendor_list() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/Vendor",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // that.vendorlist = rt.data.vendors;
              // that.vendor_total = rt.data.vendor_total;
              that.company_typelist = rt.data.company_types;
              that.positionlist = rt.data.positions;
              // that.company_classlist = rt.data.company_classes;
              // that.product_classlist = rt.data.product_classes;
              // that.item_groupinglist = rt.data.item_groupings;
              that.pay_periodlist = rt.data.pay_periods;
              // that.credit_termlist = rt.data.credit_terms;
              // that.saleslist = rt.data.sales;
              // that.saleslist[0] = {"IDn": 0, "UserName": " "};
              // that.carrier_list = rt.data.carriers;
              // that.carrier_name_list = rt.data.carrier_names;
              // that.carrier_account_no_list = rt.data.carrier_account_nos;
              // that.countrylist = rt.data.countries;
              // that.provincelist = rt.data.provinces;
              // that.citylist = rt.data.cities;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async refresh_product() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("VendorIDn", this.cur_po.VendorIDn);
      bodyFormData.append("LocationID", this.cur_po.LocationID);
      bodyFormData.append("CountryIDn", this.cur_po.ComCountryIDn);
      bodyFormData.append("ProvinceIDn", this.cur_po.ComProvinceIDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO/buy_product",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.buy_product_list = rt.data.buy_product_list;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    updatePendingQty(){
      for(var i=0; i<this.cur_po_product.length; i++){
        if(this.cur_po_product[i].PendingQty){
          this.cur_po_product[i].PendingQty = this.cur_po_product[i].OrderQty;
          this.cur_po_product[i].OrderQty = Number(this.cur_po_product[i].OrderQty).toFixed(2);
        }
      }
    },
    async post_po(to_status, mode) {
      var bodyFormData = new FormData();
      if (!this.cur_po.Status) this.cur_po.Status = "Draft";
      let from_status = JSON.parse(JSON.stringify(this.cur_po.Status));
      this.cur_po.Status = to_status;
      this.show_listed_products = false;
      this.encode_container_string();
      this.updatePOBalance(this.cur_po);
      if(mode == 'submit_click'){
        this.updatePendingQty();
      }
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("po_from_status", from_status);
      bodyFormData.append("po", JSON.stringify(this.cur_po));
      bodyFormData.append("po_tax", JSON.stringify(this.cur_po_tax));
      bodyFormData.append("po_product", JSON.stringify(this.cur_po_product));

      // console.log("------------------------------------------");
      // Display the key/value pairs
      for (var pair of bodyFormData.entries()) {
        // console.log(pair[0]+ ', ' + pair[1]); 
      }
      // console.log("------------------------------------------");

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO/update",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (rt.data.to_status != undefined) {
                that.activeName = rt.data.to_status;
              }
              that.cur_po_list = rt.data.to_list;
              that.formatTotalAmount(that.cur_po_list);
              that.cur_po_meta = rt.data.to_meta;
              if (that.cur_po_meta.cnt != undefined) {
                that.page_total = that.cur_po_meta.cnt;
                that.per_page = rt.data.per_page;
              }
              if ( that.cur_is_return || that.cur_is_receive) {
                that.cur_is_return = false;
                that.cur_is_receive = false;
                that.cur_po.Status = from_status;
                that.set_edit_po();
              }
              else {
                if(mode == 'submit_click'){
                  that.currpage = "list";
                }
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              that.cur_is_return = false;
              that.cur_is_receive = false;
              that.currpage = "list";
              alert(rt.message);
            }
            that.show_listed_products = true;
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    async delete_pr(){
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("return_idn", this.PR_IDn);

      // console.log("------------------------------------------");
      // Display the key/value pairs
      for (var pair of bodyFormData.entries()) {
          // console.log(pair[0]+ ', ' + pair[1]); 
      }
      // console.log("------------------------------------------");

      var that=this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO/delete_return",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.close_add_return_model();
              that.edit_po(that.cur_po);
              that.currpage = "edit";
              that.activeCollapse2 = ['Return'];
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            that.show_listed_products = true;
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async post_pr(PR_status){
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      this.updatePR();
      this.updateReturnSN();
      bodyFormData.append("po", JSON.stringify(this.cur_PR));
      bodyFormData.append("po_tax", JSON.stringify(this.cur_pr_returnTaxes));
      bodyFormData.append("po_product", JSON.stringify(this.display_PR_array));
      bodyFormData.append("pr_status", PR_status);
      bodyFormData.append("return_idn", this.PR_IDn);

      // console.log("------------------------------------------");
      // Display the key/value pairs
      for (var pair of bodyFormData.entries()) {
          // console.log(pair[0]+ ', ' + pair[1]); 
      }
      // console.log("------------------------------------------");

      var that=this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO/post_return",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // Update vendor credit
              if(PR_status === 'Post' || PR_status === 'PostDraft'){
                let temp = 0;
                temp = that.updateVendorCredit();
                if(temp){
                  that.cur_po.CreditedAmount = temp;
                }
                that.post_po(that.cur_po.Status, 'post_pr');
              }
              that.close_add_return_model();
              that.edit_po(that.cur_po);
              that.currpage = "edit";
              that.activeCollapse2 = ['Return'];
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            that.show_listed_products = true;
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    updatePR(){
      this.cur_PR = JSON.parse(JSON.stringify(this.cur_po));
      this.cur_PR.EndByIDn = this.cur_PR.CreateByIDn;
      this.cur_PR.EndByName = this.cur_PR.CreateByName;
      this.cur_PR.SubTotal = this.cur_pr_returnSubtotal;
      let temp_TotalTax = 0;
      for(let i=0; i<this.cur_pr_returnTaxes.length; i++){
        temp_TotalTax += Number(this.cur_pr_returnTaxes[i].TaxAmount);
      }
      temp_TotalTax = temp_TotalTax.toFixed(2);
      this.cur_PR.TotalTax = temp_TotalTax;
      this.cur_PR.OtherFees = Number(this.returnOtherFee).toFixed(2);
      this.cur_PR.ShipFee = Number(this.returnShippingFee).toFixed(2);
      this.cur_PR.TotalAmount = this.cur_pr_returnTotal;
    },
    updateReturnSN(){
      for(let j=0; j<this.display_PR_array.length; j++){
        if(Array.isArray(this.display_PR_array[j].ReturnSNArray) && this.display_PR_array[j].ReturnSNArray.length > 0){
          this.display_PR_array[j].ReturnSN = JSON.stringify(this.display_PR_array[j].ReturnSNArray);
        } else {
          this.display_PR_array[j].ReturnSN = "";
        }
      }
    },
    changingUnitCost(row) {
      // Rounding
      row.OrderQty = Number(row.OrderQty).toFixed(2);
      row.UnitCost = Number(row.UnitCost).toFixed(2);

      this.updatePOProductAmounts(row);
    },
    changingOtherFees(order){
      // Rounding
      order.OtherFees = Number(order.OtherFees).toFixed(2);

      this.updatePOAmounts();
    },
    changingShippingFees(order){
      // Rounding
      order.ShipFee = Number(order.ShipFee).toFixed(2);

      this.updatePOAmounts();
    },
    changingOrderQty(row) {
      // Calculate amount of items that have been received
      let minAmount = 0;
      for(var i=0; i<this.cur_po_receive.length; i++){
        if(this.cur_po_receive[i].Status === 'Complete'){
          for(var j=0; j<this.cur_po_receive[i].Products.length; j++){
            if(this.cur_po_receive[i].Products[j].POProductIDn === row.IDn){
              minAmount += parseFloat(this.cur_po_receive[i].Products[j].ReceivedQty);
            }
          }
        }
      }
      if(parseFloat(row.OrderQty) < parseFloat(minAmount)){
        this.setRevert(row, 'OrderQty');
        alert("You have already received " + minAmount + " of " + row.Name1 + "\nPlease enter a value not less than " + minAmount + " .");
        return;
      }

      // Rounding
      row.OrderQty = Number(row.OrderQty).toFixed(2);
      row.UnitCost = Number(row.UnitCost).toFixed(2);

      this.updatePOProductAmounts(row);
    },
    updatePOProductAmounts(row){
      // update TotalCost (subtotal)
      if(row.TotalCost){
        row.TotalCost = Number(row.OrderQty) * Number(row.UnitCost);
        row.TotalCost = Number(row.TotalCost).toFixed(2);
      }
      // update Taxes array
      if(row.Taxes && Array.isArray(row.Taxes)){
        for(var i=0; i<row.Taxes.length; i++){
          if(row.Taxes[i].TaxType == '$'){
            row.Taxes[i].TaxAmount = (Number(row.Taxes[i].TaxValue) * Number(row.OrderQty)).toFixed(2);
          } else {
            row.Taxes[i].TaxAmount = (Number(row.Taxes[i].TaxValue) * Number(row.OrderQty) * Number(row.UnitCost) * 0.01 ).toFixed(2);
          }
        }
      }
      // update TotalTax
      row.TotalTax = 0;
      for(var i=0; i<row.Taxes.length; i++){
        row.TotalTax += Number(row.Taxes[i].TaxAmount);
      }
      row.TotalTax = Number(row.TotalTax).toFixed(2);
      // update Total
      row.Total = Number(row.TotalCost) + Number(row.TotalTax);
      row.Total = Number(row.Total).toFixed(2);
     
      this.updatePOAmounts();
    },
    convert_cur_po_product() {
      for(var i=0; i<this.cur_po_product.length; i++){
        let prod = this.cur_po_product[i];
        for(var j=0; j<prod.Taxes.length; j++){
          if(!prod.Taxes[j].ID) {
            prod.Taxes[j].ID = prod.Taxes[j].TaxID;
          }
        }
      }
    },
    updatePOAmounts(){
      // update cur_po_tax
      this.cur_po_tax = [];
      this.convert_cur_po_product();
      for(var i=0; i<this.cur_po_product.length; i++){
        let curProduct_taxes = JSON.parse(JSON.stringify(this.cur_po_product[i].Taxes));
        if(i == 0){
          this.cur_po_tax = curProduct_taxes;
        } else {
          for(var j=0; j<curProduct_taxes.length; j++){
            let taxFound = false;
            for(var k=0; k<this.cur_po_tax.length; k++){
              if(curProduct_taxes[j].ID == this.cur_po_tax[k].ID){
                  taxFound = true;
                  this.cur_po_tax[k].TaxAmount = Number(this.cur_po_tax[k].TaxAmount) + Number(curProduct_taxes[j].TaxAmount);
                  this.cur_po_tax[k].TaxAmount = Number(this.cur_po_tax[k].TaxAmount).toFixed(2);
                }
            }
            if(!taxFound){
              this.cur_po_tax.push(curProduct_taxes[j]);
            }
          }
        }
      }
      // Remove unnecessary key from cur_po_tax
      for(var a=0; a<this.cur_po_tax.length; a++){
        delete this.cur_po_tax[a]["CreateDateTime"];
        delete this.cur_po_tax[a]['Enabled'];
        delete this.cur_po_tax[a]['Exemption'];
        delete this.cur_po_tax[a]['InTax'];
        delete this.cur_po_tax[a]['Remarks'];
        delete this.cur_po_tax[a]['UpdateDateTime'];
        delete this.cur_po_tax[a]['defaultApply'];
      }
      // console.log("cur_po_product", this.cur_po_product);
      // console.log("this.cur_po_tax", this.cur_po_tax);
      // Total, Subtotal, TotalTax
      this.cur_po.TotalAmount = 0;
      this.cur_po.SubTotal = 0;
      this.cur_po.TotalTax = 0;
      for(var i=0; i<this.cur_po_product.length; i++){
        this.cur_po.SubTotal += Number(this.cur_po_product[i].TotalCost);
        for(var j=0; j<this.cur_po_product[i].Taxes.length; j++){
          this.cur_po.TotalTax += Number(this.cur_po_product[i].Taxes[j].TaxAmount);
        }
      }
      this.cur_po.SubTotal = Number(this.cur_po.SubTotal).toFixed(2);
      this.cur_po.TotalTax = Number(this.cur_po.TotalTax).toFixed(2);
      this.cur_po.TotalAmount = Number(this.cur_po.SubTotal) + Number(this.cur_po.TotalTax) + Number(this.cur_po.ShipFee) + Number(this.cur_po.OtherFees);
      // update Balance
      this.updatePOBalance(this.cur_po);
    },
    updatePOBalance(currentPO){
      if(currentPO.ReturnedAmount){
        currentPO.ReturnedTotal = currentPO.ReturnedAmount;
      }
      if(currentPO.CreditedAmount){
        currentPO.CreditedTotal = currentPO.CreditedAmount;
      }
      currentPO.Balance = Number(currentPO.TotalAmount) - Number(currentPO.PaidAmount) - Number(currentPO.ReturnedTotal) + Number(currentPO.CreditedTotal);
      currentPO.Balance = (currentPO.Balance).toFixed(2);
      // console.log(currentPO.Balance);
    },
    update_po_info(mode = 0) {
      // this.update_po_product_tax();
      // this.update_po_tax();
      // this.update_po();
      var bodyFormData = new FormData();
      if (!this.cur_po.Status) this.cur_po.Status = "Draft";
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("POIDn", this.cur_po.POIDn);
      bodyFormData.append("VendorIDn", this.cur_po.VendorIDn);
      bodyFormData.append("LocationID", this.cur_po.LocationID);
      bodyFormData.append("CountryIDn", this.cur_po.ComCountryIDn);
      bodyFormData.append("ProvinceIDn", this.cur_po.ComProvinceIDn);
      bodyFormData.append("po_product", JSON.stringify(this.cur_po_product));
      bodyFormData.append("Mode", mode);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO/update_product",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_po_product = rt.data.po_product;
              if(mode == 0){
                for(var i=0; i<that.cur_po_product.length; i++){
                  delete that.cur_po_product[i].JustAdded;
                }
              }
              for(var i=0; i<that.cur_po_product.length; i++){
                that.cur_po_product[i].UnitCost = parseFloat(that.cur_po_product[i].UnitCost).toFixed(2);
                that.cur_po_product[i].TotalCost = parseFloat(that.cur_po_product[i].TotalCost).toFixed(2);
              }
              that.cur_po_tax = rt.data.po_tax;
              that.updatePOAmounts();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    update_po_product_tax() {
      for (let k in this.cur_po_product) {
        let taxes = [];
        let product_tmp = this.cur_po_product[k];
        let taxes_tmp = product_tmp["Taxes_tmp"];
        for (let l in taxes_tmp) {
          let tax = {};
          let tax_tmp = taxes_tmp[l];

          tax["IDn"] = 0;
          tax["POIDn"] = this.cur_po.POIDn;
          tax["POProductIDn"] = 0;
          tax["ProductIDn"] = product_tmp["ProductIDn"];
          tax["TaxID"] = tax_tmp["ID"];
          tax["TaxName"] = tax_tmp["TaxName"];
          tax["TaxValue"] = tax_tmp["TaxValue"];
          tax["ValueType"] = tax_tmp["ValueType"];

          if (tax["ValueType"] == "$") {
            tax["TaxAmount"] =
              (
                Number(tax["TaxValue"]) * Number(product_tmp["OrderQty"])
              ).toFixed(2) || "0.00";
          } else {
            tax["TaxAmount"] =
              (
                Number(tax["TaxValue"]) *
                Number(product_tmp["UnitCost"]) *
                Number(product_tmp["OrderQty"]) *
                0.01
              ).toFixed(2) || "0.00";
          }
          product_tmp["TotalTax"] += Number(tax["TaxAmount"]) || 0;

          taxes.push(tax);
        }
        product_tmp["Taxes"] = taxes;
        product_tmp["Total"] =
          (
            Number(product_tmp["TotalCost"]) + Number(product_tmp["TotalTax"])
          ).toFixed(2) || "0.00";
      }
    },
    // update_po_tax() {
    //   let po_tax = {};
    //   for (let k in this.cur_po_product) {
    //     let taxes = this.cur_po_product[k]["Taxes"];
    //     for (let l in taxes) {
    //       let tax_id = taxes[l]["ID"];
    //       if (po_tax[tax_id]) {
    //         po_tax[tax_id]["TaxAmount"] += Number(taxes[l]["TaxAmount"]) || 0;
    //       } else {
    //         po_tax[tax_id] = {};
    //         for (let m in taxes[l]) {
    //           po_tax[tax_id][m] = taxes[l][m];
    //         }
    //         po_tax[tax_id]["TaxAmount"] =
    //           Number(po_tax[tax_id]["TaxAmount"]) || 0;
    //       }
    //     }
    //   }
    //   this.cur_po_tax = [];
    //   for (let k in po_tax) {
    //     po_tax[k]["IDn"] = 0;
    //     po_tax[k]["POIDn"] = this.cur_po.POIDn;
    //     po_tax[k]["TaxID"] = po_tax[k]["ID"];
    //     po_tax[k]["TaxAmount"] = po_tax[k]["TaxAmount"].toFixed(2);
    //     this.cur_po_tax.push(po_tax[k]);
    //   }
    // },
    update_po() {
      this.cur_po.SubTotal = 0;
      this.cur_po.TotalTax = 0;
      this.cur_po.TotalAmount = 0;
      this.cur_po.Discount = 0;
      for (let k in this.cur_po_product) {
        this.cur_po.SubTotal += Number(this.cur_po_product[k]["TotalCost"]) || 0;
        // if (this.cur_po_product[k].OrderQty) {
        //   this.cur_po.Discount += (parseFloat(this.cur_po_product[k].OriginalPrice) - parseFloat(this.cur_po_product[k].UnitCost)) * parseFloat(this.cur_po_product[k].OrderQty);
        // }
      }
      this.cur_po.Discount = parseFloat(this.cur_po.Discount).toFixed(2);
      for (let k in this.cur_po_tax) {
        this.cur_po.TotalTax += Number(this.cur_po_tax[k]["TaxAmount"]) || 0;
      }
      this.cur_po.SubTotal = this.cur_po.SubTotal.toFixed(2) || "0.00";
      this.cur_po.TotalTax = this.cur_po.TotalTax.toFixed(2) || "0.00";
      this.cur_po.TotalAmount = (
        (Number(this.cur_po.SubTotal) || 0) +
        (Number(this.cur_po.TotalTax) || 0) +
        (Number(this.cur_po.ShipFee) || 0) +
        (Number(this.cur_po.OtherFees) || 0)
      ).toFixed(2);

      if (this.cur_discountRate > 0) {
        this.cur_po.SubTotal = (this.cur_po.SubTotal - (this.cur_po.SubTotal * this.cur_discountRate / 100)).toFixed(2);
        this.cur_po.TotalTax = (this.cur_po.TotalTax - (this.cur_po.TotalTax * this.cur_discountRate / 100)).toFixed(2);
        this.cur_po.TotalAmount = (this.cur_po.TotalAmount - (this.cur_po.TotalAmount * this.cur_discountRate / 100)).toFixed(2);
      }
      this.updatePOAmounts();
    },
    setinput_city(city, type) {
      if(type == 0){
        this.cur_address.AddressCity = city;
      } else if(type == 1){
        this.cur_po.PurchaseFCity = city;
      }else if(type == 2){
        this.cur_po.ComCity = city;
      }else if(type == 3){
        this.cur_po.ShipTCity = city; 
      }
    },
    changed_province(type) {
      if(type == 0) {
        if (this.cur_provincelist.length <= 0) {
          return;
        }
        if (
          !this.cur_address.ProvinceIDn ||
          this.cur_address.ProvinceIDn == undefined ||
          this.cur_address.ProvinceIDn <= 0
        ) {
          this.cur_address.ProvinceIDn = this.cur_provincelist[0].IDn;
        }
        this.cur_address.AddressProvince = "";
        for (var i = 0; i < this.cur_provincelist.length; i++) {
          if (this.cur_provincelist[i].IDn == this.cur_address.ProvinceIDn) {
            this.cur_address.AddressProvince = this.cur_provincelist[i].Province;
          }
        }
        if (this.cur_address.AddressProvince == "") {
          // Didn't find Province
          this.cur_address.ProvinceIDn = this.cur_provincelist[0].IDn;
        }
      }
    },
    changed_country(countryIDn, type) {
      var tempCountryIDn = countryIDn;
      // type 0: this.cur_address.CountryIDn
      // type 1: cur_po.PurchaseFCountryIDn Vendor
      // type 2: cur_po.ComCountryIDn Billing Address
      // type 3: cur_po.ShipTCountryIDn Shipping Address
      if ( !tempCountryIDn ||
           tempCountryIDn == undefined ||
           tempCountryIDn <= 0)
      {
        tempCountryIDn = 1;     // Set default to Canada
        if( type == 1){
          this.cur_po.PurchaseFCountryIDn = tempCountryIDn;
        }  else if( type == 2){
          this.cur_po.ComCountryIDn = tempCountryIDn;
        }  else if( type == 3){
          this.cur_po.ShipTCountryIDn = tempCountryIDn;
        } 
      }
      // if(  type == 0 ){
      //   for (var idx in this.country_list) {
      //     if (this.country_list[idx].IDn == tempCountryIDn) {
      //       this.cur_address.AddressCountry = this.country_list[idx].Country;
      //     }
      //   }
      // }
      var temp_provincelist = [];
      for (var idx in this.province_list) {
        if (this.province_list[idx].CountryIDn == tempCountryIDn) {
          temp_provincelist.push(this.province_list[idx]);
        }
      }

      if( type == 1){                                                     // When country is changed @choosing vendor
        this.cur_vendor_provincelist = temp_provincelist;                 // Update specific province list
        if(this.cur_po.PurchaseFAddressIDn == 0){                         // If no address given
          if(tempCountryIDn == 1){                                        // If country is CA,
            this.cur_po.PurchaseFProvinceIDn = Number(temp_provincelist[1].IDn);  // Set province to be BC
          } else {                                                        // If country is others,
            this.cur_po.PurchaseFProvinceIDn = Number(temp_provincelist[0].IDn);  // Set province to be the first element
          }
        }   // ------------------------------------------------------------------------------------------------------------------
      }  else if( type == 2){                                             // When country is changed @choosing billing address
        this.cur_billing_provincelist = temp_provincelist;                // Update specific province list
        // if(tempCountryIDn == 1){                                          // If country is CA,
        //   this.cur_po.ComProvinceIDn = temp_provincelist[1].IDn;          // Set province to be BC
        // } else {                                                          // If country is others,
        //   this.cur_po.ComProvinceIDn = temp_provincelist[0].IDn;          // Set province to be the first element
        // }   // ------------------------------------------------------------------------------------------------------------------
      }  else if( type == 3){                                             // When country is changed @choosing shipping address
        this.cur_shipping_provincelist = temp_provincelist;               // Update specific province list
        // if(tempCountryIDn == 1){                                          // If country is CA,
        //   this.cur_po.ShipTProvinceIDn = temp_provincelist[1].IDn;        // Set province to be BC
        // } else {                                                          // If country is others,
        //   this.cur_po.ShipTProvinceIDn = temp_provincelist[0].IDn;        // Set province to be the first element
        // }   // ------------------------------------------------------------------------------------------------------------------
      } else if( type == 4){                                              // When country is changed @creating new Vendor
        this.new_vendor_provincelist = temp_provincelist;                 // Update specific province list       
        if(tempCountryIDn == 1){                                          // If country is CA,
          this.new_vendor.ProvinceIDn = Number(temp_provincelist[1].IDn);         // Set province to be BC
        } else {                                                          // If country is others,
          this.new_vendor.ProvinceIDn = Number(temp_provincelist[0].IDn);         // Set province to be the first element
        } 
      }
      this.changed_province(type);
    },
    cancel_edit_address() {
      this.$refs["address-modal"].hide();
    },
    async submit_address() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      for (var key in this.cur_address) {
        if (key == "ReferenceIDn") {
          bodyFormData.append(key, this.cur_po.VendorIDn);
        } else {
          bodyFormData.append(key, this.cur_address[key]);
        }
        this.cur_payment_edit["ReferenceIDn"] = this.cur_po.POIDn;
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/submit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              var newaddr = rt.data;
              that.cur_address_list.push(newaddr);
              if (newaddr.BillingFlag == 1) {
                that.cur_po.PurchaseFAddressIDn = newaddr.IDn;
                that.setinput_sendto_address();
              }
              that.$refs["address-modal"].hide();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          //XXXXX that.$router.push("/login");
        });
    },
    format_date(date) {
      let p = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      }).formatToParts(date)
        .reduce((acc, part) => {
          acc[part.type] = part.value;
          return acc;
        }, {});
      return `${p.year}-${p.month}-${p.day} ${p.hour}:${p.minute}:${p.second}`; 
    },
    init_new_product(){
      this.cur_new_product = JSON.parse(JSON.stringify(this.new_product));
      this.set_department();
      this.getCategoryList();
      this.setTaxTypeIDn();
      this.$refs["add-product-modal"].show();
    },
    async add_new_product(){
      this.cur_new_product = this.ruleForm;
      this.update_cur_new_product();
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("product", JSON.stringify(this.cur_new_product));
      
      // console.log("------------------------------------------");
      // Display the key/value pairs
      for (var pair of bodyFormData.entries()) {
          // console.log(pair[0]+ ', ' + pair[1]); 
      }
      // console.log("------------------------------------------");

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO/create_new_product",
        data: bodyFormData,
      })
      .then(function (response) {
        if (response.status == "200") {
          var rt = response.data;
          if (rt.status == 0) {
            // console.log("@add_new_product /Api/Web/Purchase/PO/create_new_product rt.data.product", rt.data.product);
            // that.full_product_list = rt.data.idxproducts;
            that.get_extra();
            that.add_product2(rt.data.product);
            that.add_order_product(0, rt.data.product);
            that.loadingCreateNewProduct = false;
            that.close_add_product_model();
          } else if (rt.status == 4) {
            alert(that.$t("No Permission"));
            that.$router.go(-1);
          } else if (rt.message != undefined) {
            alert(rt.message);
          }
        } else {
          that.loadingCreateNewProduct = false;
          that.$router.push("/login");
        }
      })
      .catch(function (error) {
        // console.log("error: ", error);
        that.$router.push("/login");
      });
    },
    validate_new_product(target){
      if(target.Name1 == ''){
        return false;
      }
      return true;
    },
    update_cur_new_product(){
      if(this.cur_new_product.APrice == ''){
        this.cur_new_product.APrice = "0.00";
      }
      if(this.cur_new_product.BPrice == ''){
        this.cur_new_product.BPrice = "0.00";
      }
      if(this.cur_new_product.CPrice == ''){
        this.cur_new_product.CPrice = "0.00";
      }
      if(this.cur_new_product.DPrice == ''){
        this.cur_new_product.DPrice = "0.00";
      }
      if(this.cur_new_product.EPrice == ''){
        this.cur_new_product.EPrice = "0.00";
      }
      if(this.cur_new_product.FPrice == ''){
        this.cur_new_product.FPrice = "0.00";
      }
      if(this.cur_new_product.GPrice == ''){
        this.cur_new_product.GPrice = "0.00";
      }
      if(this.cur_new_product.HPrice == ''){
        this.cur_new_product.HPrice = "0.00";
      }
      if(this.cur_new_product.IPrice == ''){
        this.cur_new_product.IPrice = "0.00";
      }
      if(this.cur_new_product.JPrice == ''){
        this.cur_new_product.JPrice = "0.00";
      }
    },
    set_department(){
      for(var i=0; i<this.full_product_list.length; i++){
        if(i==0){
          this.ruleForm.DepartmentIDn = this.full_product_list[i].IDn;
        }
      }
    },
    changed_department() {
      this.getCategoryList();
    },
    getCategoryList(){
      // Reset categoryList
      this.categoryList = [];

      // Get all categories of chosen department, set categoryList
      for(var i=0; i<this.full_product_list.length; i++){
        if(this.full_product_list[i].IDn == this.ruleForm.DepartmentIDn){
          this.categoryList = this.full_product_list[i].Categorys;
        }
      }

      // If department has more than 1 categories,
      // default select first category in categoryList.
      // Else, select empty.
      if(this.categoryList.length > 0){
        this.ruleForm.CategoryIDn = this.categoryList[0].IDn;
      } else {
        this.ruleForm.CategoryIDn = '';
      }
    },
    setTaxTypeIDn(){
      const obj = this.tax_typelist;
      let temp = '';
      Object.keys(obj).forEach(function(key) {
        if(key == 1){
          temp = obj[key].IDn;
        }
      });
      this.ruleForm.TaxTypeIDn = temp;
    },
    set_PackageWeightUnit(){
      for(var i=0; i<this.weigh_unit_list.length ; i++){
        if(i==0){
          this.ruleForm.PackageWeightUnit = this.weigh_unit_list[i].Unit;
        }
      }
    },
    reset_PackageWeightUnit(){
      this.ruleForm.PackageWeightUnit = '';
    },
    get_typed_unit_list(type) {
      var rslt = [];
      if (typeof type == "string") {
        for (var i = 0; i < this.unitlist.length; i++) {
          if (this.unitlist[i]["Type"] == type) {
            rslt.push(JSON.parse(JSON.stringify(this.unitlist[i])));
          }
        }
      }
      else if (typeof type == "object") {
        for (var i = 0; i < this.unitlist.length; i++) {
          for (var j = 0; j < type.length; j++) {
            if (this.unitlist[i]["Type"] == type[j]) {
              rslt.push(JSON.parse(JSON.stringify(this.unitlist[i])));
            }
          }
        }
      }
      return rslt;
    },
    getRevert(value){
      let temp = JSON.parse(JSON.stringify(value));
      this.revertValue = temp;
    },
    setRevert(obj, target){
      obj[target] = this.revertValue;
    },
    formatter(value) {
      if(!isNaN(value) && value > 0){
        return Number(value).toFixed(2);
      } else {
        return this.revertValue;
      }
    },
    init_new_payment() {
      this.editPayment = true;
      this.cur_payment_edit = JSON.parse(JSON.stringify(this.new_payment));
      this.cur_payment_edit["CustomerIDn"] = this.cur_po.VendorIDn;
      this.cur_payment_edit['ReferenceIDn'] = this.cur_po.POIDn;
      this.cur_payment_edit['InvoiceNo'] = this.cur_po.OrderID;
      this.cur_payment_edit['InvoiceAmount'] = this.cur_po.TotalAmount;
      this.cur_payment_edit["PaymentAmount"] = parseFloat(this.cur_po.TotalAmount) - parseFloat(this.cur_po.PaidAmount);
      this.$refs["add-payment-modal"].show();
    },
    init_new_credit() {
      this.cur_credit_edit = JSON.parse(JSON.stringify(this.new_credit));
      this.cur_credit_edit["CustomerIDn"] = this.cur_po.VendorIDn;
      this.cur_credit_edit["ReferenceIDn"] = this.cur_po.POIDn;
      this.cur_credit_edit["InvoiceNo"] = this.cur_po.OrderID;
      this.cur_credit_edit["InvoiceAmount"] = this.cur_po.TotalAmount;
      this.cur_credit_edit["PaymentAmount"] = 0;
      this.$refs["add-credit-modal"].show();
    },
    add_payment() {
      if (!this.cur_payment_edit.PaymentAmount || this.cur_payment_edit.PaymentAmount <= 0) {
        alert("Please input a number greater than 0");
        return;
      } else if (this.cur_payment_edit.PaymentAmount > Number(this.cur_po.Balance)){
        alert("You cannot pay more than the order Balance");
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      for (let k in this.cur_payment_edit) {
        if (this.cur_payment_edit[k]) {
          bodyFormData.append(k, this.cur_payment_edit[k]);
        }
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO/add_payment",
        data: bodyFormData,
      })
      .then(function (response) {
        if (response.status == "200") {
          var rt = response.data;
          if (rt.status == 0) {
            that.cur_payment_edit = rt.data.payment;
            that.cur_po_payment = rt.data.po_payment;
            that.cur_po_credit = rt.data.po_credit;
            that.cur_po['PaidAmount'] = rt.data.PaidAmount;
            that.vendor_credit = rt.data.vendor_credit;
            that.close_add_payment_model();
            that.post_po(that.cur_po.Status, 'add_payment');
          } else if (rt.status == 4) {
            alert(that.$t("No Permission"));
            that.$router.go(-1);
          } else if (rt.message != undefined) {
            alert(rt.message);
          }
        } else {
          that.$router.push("/login");
        }
      })
      .catch(function (error) {
        that.$router.push("/login");
      });
    },
    add_credit() {
      if (!this.cur_credit_edit.PaymentAmount || this.cur_credit_edit.PaymentAmount <= 0) {
        alert("Please input a number greater than 0");
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      for (let k in this.cur_credit_edit) {
        if (this.cur_credit_edit[k]) {
          bodyFormData.append(k, this.cur_credit_edit[k]);
        }
      }
      var that = this;

      // console.log("------------------------------------------");
      // Display the key/value pairs
      for (var pair of bodyFormData.entries()) {
          // console.log(pair[0]+ ', ' + pair[1]); 
      }
      // console.log("------------------------------------------");

      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO/add_credit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_po_credit = rt.data.po_credit;
              that.vendor_credit = rt.data.vendor_credit;
              that.close_add_credit_model();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    cancel_payment() {
      this.editPayment = false;
    },
    delete_payment_credit(index, row, type) {
      var alertMsg = "Payment delete is a crucial action. Make sure you understand the consequences before acting on it.\n\nAre you sure to continue?";
      var url = "/Api/Web/Purchase/PO/delete_payment";
      if (type != 'payment') {
        alertMsg = "Credit delete is a crucial action. Make sure you understand the consequences before acting on it.\n\nAre you sure to continue?";
        url = "/Api/Web/Purchase/PO/delete_credit";
      }
      var r = confirm(alertMsg);
      if (r == false) {
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("ReferenceIDn", this.cur_po.POIDn);
      bodyFormData.append("CustomerIDn", this.cur_po.VendorIDn);
      var that = this;
      axios({
        method: "post",
        url: url,
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_po_payment = rt.data.po_payment;
              that.cur_po_credit = rt.data.po_credit;
              that.cur_po['PaidAmount'] = rt.data.PaidAmount;
              that.cur_po['Balance'] = Number(that.cur_po['Balance']) + Number(that.cur_po['PaidAmount']);
              that.vendor_credit = rt.data.vendor_credit;
              that.post_po(that.cur_po.Status, 'add_payment');
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    payment_row_click(row, column, event) {
      // console.log("payment_row_click",row, column, event)
      return;
    },
    credit_row_click(row, column, event) {
      // console.log("credit_row_click",row, column, event)
      return;
    },
    setStartandEndDate() {
      // Get current time
      var today = new Date();
      // var month = 5;
      // today.setFullYear(2020, month-1, 31);
      // Get Current day, month, year
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //Jan: mm="01"    Dec: mm="12"
      var yyyy = today.getFullYear();
      // Get 1 month ago day, month, year
      var dd_1_Month_Ago = dd;
      var mm_1_Month_Ago;
      var yyyy_1_Month_Ago = yyyy;
      // Case 1: January now!
      if(mm === "01"){
        mm_1_Month_Ago = "12";  // Set month to be Dec
        yyyy_1_Month_Ago = parseFloat(yyyy) - 1; // Set year to be last year
      }
      // Case 2: March now!
      else if(mm === "03"){
        mm_1_Month_Ago = parseFloat(mm) - 1;   // Set month to be last month

        // Rule: if current date is March 29/30/31, 1 month ago is Feb 28/29, depends on if leap year
        if(parseFloat(dd) > 28){
          if(((0 == parseFloat(yyyy) % 4) && (0 != parseFloat(yyyy) % 100) || (0 == parseFloat(yyyy) % 400))){
            dd_1_Month_Ago = "29";
          } else {
            dd_1_Month_Ago = "28";
          }
        }
      }
      // Case 3: Feb, Apr, June, Sept or Nov now!
      else if(mm === "02" || mm === "04" || mm === "06" || mm === "09" || mm === "11"){
        mm_1_Month_Ago = parseFloat(mm) - 1;   // Set month to be last month
      }
      // Case 4: May, July, Aug, Oct or Dec now!
      else {
        mm_1_Month_Ago = parseFloat(mm) - 1;   // Set month to be last month

        // Rule: if current date is May 31, 1 month ago is Apr 30
        if(parseFloat(dd) > 30){
          dd_1_Month_Ago = "30";
        }
      }

      // Set Start Date and End Date
      this.cur_end_dt = yyyy + '-' + mm + '-' + dd;
      this.cur_start_dt = yyyy_1_Month_Ago + '-' + mm_1_Month_Ago + '-' + dd_1_Month_Ago;
    },
    setOrderAndArrivalDate(){
      // Get current time
      var today = new Date();
      // var month = 5;
      // today.setFullYear(2020, month-1, 31);
      // Get Current day, month, year
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //Jan: mm="01"    Dec: mm="12"
      var yyyy = today.getFullYear();
      // Get 7 days later
      var n_days_later = new Date();
      var n = 7;
      n_days_later.setDate(today.getDate() + n);
      var dd_n_days_later = String(n_days_later.getDate()).padStart(2, '0');
      var mm_n_days_later = String(n_days_later.getMonth() + 1).padStart(2, '0'); //Jan: mm="01"    Dec: mm="12"
      var yyyy_n_days_later = n_days_later.getFullYear();
      // Set Start Date and End Date
      this.cur_po.OrderDate = yyyy + '-' + mm + '-' + dd;
      this.cur_po.ArrivalDate = yyyy_n_days_later + '-' + mm_n_days_later + '-' + dd_n_days_later;
    },
    calculate_el_select_width(){
      for(var i = 0; i < document.getElementsByClassName("el-dropdown").length; i++){
        document.getElementsByClassName("el-dropdown")[i].children[0].children[0].style.borderRadius = "0.375rem";
        document.getElementsByClassName("el-dropdown")[i].style.width = document.getElementsByClassName("el-dropdown")[0].parentElement.clientWidth + "px";
        document.getElementsByClassName("el-dropdown")[i].style.width = (parseFloat(document.getElementsByClassName("el-dropdown")[i].style.width) - 10) + 'px'; // -10 because el-row gutter is 10px
        document.getElementsByClassName("el-dropdown")[i].children[0].children[0].style.height = "43px";
      }
    },
    calculate_el_select_width2(){
      setTimeout(()=>{
        for(var i = 0; i < document.getElementsByClassName("el-dropdown2").length; i++){
          document.getElementsByClassName("el-dropdown2")[i].children[0].children[0].style.borderRadius = "0.375rem";
          document.getElementsByClassName("el-dropdown2")[i].style.width = document.getElementsByClassName("form-control")[0].clientWidth + "px";
          document.getElementsByClassName("el-dropdown2")[i].style.width = (parseFloat(document.getElementsByClassName("el-dropdown2")[i].style.width) + 2.06) + 'px';
          document.getElementsByClassName("el-dropdown2")[i].children[0].children[0].style.height = "43px";
        }
        for(var i = 0; i < document.getElementsByClassName("el-dropdown3").length; i++){
          document.getElementsByClassName("el-dropdown3")[i].children[0].children[0].style.borderRadius = "0 0.375rem 0.375rem 0";
          document.getElementsByClassName("el-dropdown3")[i].style.width = document.getElementsByClassName("fred2")[0].clientWidth + "px";
          document.getElementsByClassName("el-dropdown3")[i].children[0].children[0].style.height = "46px";
        }
        for(var i = 0; i < document.getElementsByClassName("el-dropdown4").length; i++){
          document.getElementsByClassName("el-dropdown4")[i].children[0].children[0].style.borderRadius = "0 0.375rem 0.375rem 0";
          document.getElementsByClassName("el-dropdown4")[i].style.width = (parseFloat(document.getElementsByClassName("fred3")[0].clientWidth) * 1.20) + "px";
          document.getElementsByClassName("el-dropdown4")[i].children[0].children[0].style.height = "46px";
        }
        for(var i = 0; i < document.getElementsByClassName("fred").length; i++){
          document.getElementsByClassName("fred")[i].style.width = document.getElementsByClassName("form-control")[0].clientWidth + "px";
          document.getElementsByClassName("fred")[i].style.width = (parseFloat(document.getElementsByClassName("fred")[i].style.width) + 2.06) + 'px';
        }
      },1);
    },
    calculate_el_select_width3(){
      setTimeout(()=>{
        for(var i = 0; i < document.getElementsByClassName("el-dropdown5").length; i++){
          document.getElementsByClassName("el-dropdown5")[i].children[0].children[0].style.borderRadius = "0 0.375rem 0.375rem 0";
          document.getElementsByClassName("el-dropdown5")[i].children[0].children[0].style.height = "43px";
        }
      }, 1);
    },
    disableVendorInput(){
      if(this.disable_vendor_input){
        this.disable_vendor_input=false;
        return;
      }
      this.disable_vendor_input=true;
    },
    validateInputField(){
      if(this.new_vendor.Name != ''){
        this.Name1_State = true;
      }
      if(this.new_vendor.Tel != ''){
        this.TEL_State = true;
      }
      if(this.new_vendor.Address1 != ''){
        this.Address1_State = true;
      }
      if(this.new_vendor.City != ''){
        this.City_State = true;
      }
      if(this.new_vendor.ZIP != ''){
        this.PostalCode_State = true;
      }
    },
    phoneNumberValidation(phoneNumber){
      var regex = /^[\d.\-()]+$/;
      if(phoneNumber.match(regex)){
        return true;
      } else {
        return false;
      }
    },
    checkNoEmptyInput(){
      if(!this.is_add_vendor_modal_opened){
        return true;
      }
      let passed = true;
      if(this.new_vendor.Name == ''){
        this.Name1_State = false;
        passed = false;
      } else {
        this.Name1_State = true;
      }
      if(this.new_vendor.Tel == ''){
        this.TEL_State = false;
        passed = false;
      } else {
        this.TEL_State = true;
      }
      if(this.new_vendor.Address1 == ''){
        this.Address1_State = false;
        passed = false;
      } else {
        this.Address1_State = true;
      }
      if(this.new_vendor.City == ''){
        this.City_State = false;
        passed = false;
      } else {
        this.City_State = true;
      }
      if(this.new_vendor.ZIP == ''){
        this.PostalCode_State = false;
        passed = false;
      } else {
        this.PostalCode_State = true;
      }
      return passed;
    },
    resetModal(){
      this.is_add_vendor_modal_opened = false;
    },
    validateForm(){
      let allPassed = this.checkNoEmptyInput();
      if(allPassed){
        if(!this.phoneNumberValidation(this.new_vendor.Tel)){
          this.TEL_State = false;
        } else {
          this.TEL_State = true;
          this.submit_new_vendor_address();
        }
      } else {
        const interval = setInterval(()=> {
          allPassed = this.checkNoEmptyInput();
          if(allPassed){
            clearInterval(interval);
          }
        }, 500);
      }
    },
    addNewVendor(){
      this.get_vendor_list();
      this.Name1_State = true;
      this.TEL_State = true;
      this.Address1_State = true;
      this.City_State = true;
      this.PostalCode_State = true;
      let empty_vendor = {
        Name: "",
        OtherName: "",
        Tel: "",
        CompanyType: "",
        Address1:"",
        Address2: "",
        ProvinceIDn: 2,
        CountryIDn: 1,
        ZIP: "",
        PaymentTermIDn: "1",
        Status: 1,
        Contact: {
          FirstName: "",
          LastName: "",
          Tel: "",
          Email: "",
          Position: "",
        },
      };
      this.changed_country(empty_vendor.CountryIDn, 4);
      this.new_vendor = empty_vendor;
      this.is_add_vendor_modal_opened = true;
      this.$refs["add-vendor-modal"].show();
    },
    async submit_new_vendor_address(){

      // Show loading in progress animation
      this.loading = true;

      let new_vendor_addressProvince = "";
      let new_vendor_addressCountry = "";
      
      // Get province name (FULL name, not province code. "Alberta" not "AB") from provinceIDn
      for (var i = 0; i < this.cur_vendor_provincelist.length; i++) {
        if(this.new_vendor.ProvinceIDn === this.cur_vendor_provincelist[i].IDn){
          new_vendor_addressProvince = this.cur_vendor_provincelist[i].Province;
        }
      }
      // Get country name from countryIDn
      let temp_country_list = JSON.parse(JSON.stringify(this.country_list));
      for (var i = 0; i < Object.keys(temp_country_list).length; i++) {
        if(this.new_vendor.CountryIDn === temp_country_list[Object.keys(temp_country_list)[0]].IDn){
          new_vendor_addressCountry = temp_country_list[Object.keys(temp_country_list)[0]].Country;
        }
      }

      // Create new address in MultiAdd
      let new_vendor_address = {
        IDn: 0,
        Type: "Vendor",
        ReferenceIDn: 0,
        BillingFlag: 0,
        ShippingFlag: 0,
        Status: 1,
        Name: "",
        Street1: this.new_vendor.Address1,
        Street2: this.new_vendor.Address2,
        AddressCity: this.new_vendor.City,
        ProvinceIDn: this.new_vendor.ProvinceIDn,
        AddressProvince: new_vendor_addressProvince,
        CountryIDn: this.new_vendor.CountryIDn,
        AddressCountry: new_vendor_addressCountry,
        ZIP: this.new_vendor.ZIP,
        Remarks: "",
      };
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      for (var key in new_vendor_address) {
        bodyFormData.append(key, new_vendor_address[key]);
      }
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/submit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_address_list = [];
              var newaddr = rt.data;
              that.cur_address_list.push(newaddr);
              // console.log("that.cur_address_list", that.cur_address_list);
              if (newaddr.BillingFlag == 1) {
                that.cur_po.PurchaseFAddressIDn = newaddr.IDn;
              }
              that.submit_new_vendor(rt.data.IDn, newaddr);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          //XXXXX that.$router.push("/login");
        });
      
    },
    async submit_new_vendor(address_IDn, newaddr){
      var valid = true;
      if(!valid){
        return;
      } else {
        var bodyFormData = new FormData();
        bodyFormData.append("token", this.token);
        bodyFormData.append("user_id", this.user_id);
        bodyFormData.append("opt", "add");
        bodyFormData.append("address_added", address_IDn);
        bodyFormData.append("CompanyName", this.new_vendor.Name);
        bodyFormData.append("OtherName", this.new_vendor.OtherName);
        bodyFormData.append("TEL", this.new_vendor.Tel);
        bodyFormData.append("ContactFirstName1", this.new_vendor.Contact.FirstName);
        bodyFormData.append("ContactLastName1", this.new_vendor.Contact.LastName);
        bodyFormData.append("ContactCell1", this.new_vendor.Contact.Tel);
        bodyFormData.append("ContactEMail1", this.new_vendor.Contact.Email);
        bodyFormData.append("Status", this.new_vendor.Status);
        bodyFormData.append("UserIDn", this.user_id);
        // TODO: requires ContactPositionIDn1
        // bodyFormData.append("ContactPositionIDn1", );
        // TODO: requires CompanyTypeIDn
        // bodyFormData.append("CompanyTypeIDn", );
        // TODO: requires PayPeriodIDn
        // bodyFormData.append("PayPeriodIDn", );
        var that = this;
        axios({
          method: "post",
          url: "/Api/Web/Purchase/Vendor/submit",
          data: bodyFormData,
        })
          .then(function (response) {
            if (response.status == "200") {
              var rt = response.data;
              if (rt.status == 0) {
                that.vendor_list[rt.data.vendors[rt.data.vendors.length - 1].IDn] = rt.data.vendors[rt.data.vendors.length - 1];
                that.vendor_list[rt.data.vendors[rt.data.vendors.length - 1].IDn].Address = [newaddr];
                that.cur_vendor_name = rt.data.vendors[rt.data.vendors.length - 1].CompanyName;
                that.create_vendor_datalist();
                that.vendor_changed();
                that.loading = false;
                that.close_add_vendor_modal();
              } else if (rt.status == 4) {
                alert(that.$t("No Permission"));
                that.$router.go(-1);
              } else if (rt.message != undefined) {
                alert(rt.message);
              }
            } else {
              alert(response.statusText);
              that.loading = false;
            }
          })
          .catch(function (error) {
            that.$router.push("/login");
          });
      }
    },
    close_add_vendor_modal(){
      this.$refs["add-vendor-modal"].hide();
    },
    manageVendorAddress(){
      if ((this.cur_po.VendorIDn == undefined) || (this.cur_po.VendorIDn <= 0)){
        alert("Please select vendor first");
        return;
      } else {
        this.$refs["manage-vendor-address-modal"].show();
      }
    },
    select_vendor_address(){
      this.set_purchasef_address();
      this.$refs["manage-vendor-address-modal"].hide();
    },
    select_new_vendor_address(newIDn){
      this.cur_po.PurchaseFAddressIDn = newIDn;
    },
    close_manage_vendor_address_modal(){
      this.$refs["manage-vendor-address-modal"].hide();
    },
    editVendorAddress(addr){
      // Select the corresponding address to edit
      this.select_new_vendor_address(addr.IDn);

      // Set initial value for vendor_address_edit
      this.vendor_address_edit.Address1 = addr.Street1;
      this.vendor_address_edit.Address2 = addr.Street2; 
      this.vendor_address_edit.AddressCity = addr.AddressCity; 
      this.vendor_address_edit.ProvinceIDn = addr.ProvinceIDn; 
      this.vendor_address_edit.CountryIDn = addr.CountryIDn; 
      this.vendor_address_edit.ZIP = addr.ZIP;

      // Show edit modal and hide manage modal
      // this.$refs["edit-vendor-address-modal"].show();
      this.$refs["manage-vendor-address-modal"].hide();
    },
    delay_search_listed_products(){
      if (this.search_delay_timer) {
        clearTimeout(this.search_delay_timer);
        this.search_delay_timer = null;
      }
      var that = this
      this.search_delay_timer = setTimeout(() => {
        that.search_listed_products();
      }, 400);
    },
    handleCommand(selected) {
      this.per_page = Number(selected.target.innerText);
      this.current_page=1;
      this.get_lists();
    },
    search_listed_products(){
      // If cascader_search_array is empty, reset cascader_search_array
      if(this.cascader_search_array.length == 0){
        for (var i = 0; i < this.full_product_list.length; i++) {
          for (var j = 0; j < this.full_product_list[i].Categorys.length; j++) {
            for (var k = 0; k < this.full_product_list[i].Categorys[j].Products.length; k++) {
              this.cascader_search_array.push(this.full_product_list[i].Categorys[j].Products[k]);
            }
          }
        }
      }
      var search_result=[];
      if( (this.pro_search_plu && this.pro_search_plu!="") || 
          (this.pro_search_name && this.pro_search_name!="") || 
          (this.pro_search_description && this.pro_search_description!="") || 
          (this.pro_search_unit && this.pro_search_unit!="") || 
          (this.pro_search_qty && this.pro_search_qty!="") || 
          (this.pro_search_onhold && this.pro_search_onhold!="")  ||
          (this.pro_search_onorder && this.pro_search_onorder!="")){    // If there are inputs
        // Loop through cascader_search_array
        for(let i=0; i<this.cascader_search_array.length; i++){
          // Find matching products
          if( String(this.cascader_search_array[i].PLU).toLowerCase().includes(this.pro_search_plu.toLowerCase()) && 
              String(this.cascader_search_array[i].Name1).toLowerCase().includes(this.pro_search_name.toLowerCase()) && 
              String(this.cascader_search_array[i].Description1).toLowerCase().includes(this.pro_search_description.toLowerCase()) && 
              String(this.cascader_search_array[i].Unit).toLowerCase().includes(this.pro_search_unit.toLowerCase()) &&
              String(this.cascader_search_array[i].StoreQty).toLowerCase().includes(this.pro_search_qty.toLowerCase()) &&
              String(this.cascader_search_array[i].OnHoldQty).toLowerCase().includes(this.pro_search_onhold.toLowerCase()) && 
              String(this.cascader_search_array[i].OnOrderQty).toLowerCase().includes(this.pro_search_onorder.toLowerCase()) ){ 
            // Update search_result
            search_result.push(this.cascader_search_array[i]);
          }
        }
        // After looping through cascader_search_array
        if(search_result.length > 0){     // If there are any results, update cur_listed_products
          this.cur_listed_products = search_result;
          this.isNoData = false;
        } else {                          // If there are not matching results, update cur_listed_products
          // this.cur_listed_products = [];
          this.isNoData = true;
        }
      } else {                            // If there are no inputs
        this.cur_listed_products = this.cascader_search_array;
        this.isNoData = false;
      }
    },
    reset_search_parameters(){
      this.pro_search_plu = "";
      this.pro_search_name = "";
      this.pro_search_description = "";
      this.pro_search_unit = "";
      this.pro_search_qty = "";
      this.pro_search_onhold = "";
      this.pro_search_onorder = "";
    },
    hideCell(){
      return "display: none;";
    },
    showCell(){
      return "";
    },
    save_vendor_address(){
      this.close_edit_vendor_address_modal();
    },
    close_edit_vendor_address_modal(){
      this.$refs["edit-vendor-address-modal"].hide();
      this.$refs["manage-vendor-address-modal"].show();
    },
    disableBillingInput(){
      if(this.disable_billing_input){ 
        this.disable_billing_input=false;
        return;
      }
      this.disable_billing_input=true;
    },
    addNewBilling(){

    },
    disableShippingInput(){
      if(this.disable_shipping_input){
        this.disable_shipping_input=false;
        return;
      }
      this.disable_shipping_input=true;
    },
    addNewShipping(){

    },
    determineCurrencyName(currency_IDN){
      for(let i=0; i<this.currency_List.length; i++){
        if(this.currency_List[i].IDn == currency_IDN){
          return this.currency_List[i].Currency;
        }
      }
    },
    set_cascader_options(){
      var department = {};
      var category = {};
      var product = {};
      this.cascader_options = [];
      // if(this.cascader_options.length == 0){
        for(let i=0; i<this.full_product_list.length; i++){
          var department_children = [];
          department = {"value": i, "label": this.full_product_list[i].Name1, "children": [] };
          for(let j=0; j<this.full_product_list[i].Categorys.length; j++){
            var category_children = [];
            category = {"value": j, "label": this.full_product_list[i].Categorys[j].Name1, "children": [] };
            for(let k=0; k<this.full_product_list[i].Categorys[j].Products.length; k++){
              product = {"value": k, "label": this.full_product_list[i].Categorys[j].Products[k].Name1};
              category_children.push(product);
            }
            category.children = category_children;
            department_children.push(category);
          }
          department.children = department_children;
          this.cascader_options.push(department);
        // }
      }
    },
    cascader_selection_change(value){
      // console.log("@cascader_selection_change value:", value);
      // construct matching_products
      var matching_products = [];
      for(var i=0; i<value.length; i++){
        let dept = value[i][0];   // Hard-coded [0], depends on how many layers we have in cascader
        let cate = value[i][1];   // Hard-coded [1], depends on how many layers we have in cascader
        let prod = value[i][2];   // Hard-coded [2], depends on how many layers we have in cascader
        matching_products.push(this.full_product_list[dept].Categorys[cate].Products[prod]);
      }
      // update cur_listed_products
      // if(matching_products.length == 0){
      //   this.build_product();
      // } else {
      //   this.build_product(matching_products);
      // }

      // console.log("@cascader_selection_change matching_products:", matching_products);
      
      // when selection change, add only not yet added items to selected_product_list
      let temp = [];
      // console.log("matching_products", matching_products);
      for(var i=0; i<matching_products.length; i++){
        let productAlreadyAdded = false;
        for(var j=0; j<this.selected_product_list.length; j++){
          if(matching_products[i].IDn == this.selected_product_list[j].ProductIDn){
            productAlreadyAdded = true;
            break;
          }
        }
        if(!productAlreadyAdded){
          temp.push(matching_products[i]);
        }
      }
      this.add_order_product_array(temp);
      
      // when selection change, remove unchecked items from selected_product_list
      for(var i=this.selected_product_list.length-1; i>=0; i--){
        let productChecked = false;
        for(var j=0; j<matching_products.length; j++){
          if(this.selected_product_list[i].ProductIDn == matching_products[j].IDn){
            productChecked = true;
            break;
          }
        }
        if(!productChecked){
          this.selected_product_list.splice(i, 1);
        }
      }
      // console.log("@cascader_selection_change selected_product_list:", this.selected_product_list);
    }
  },
  watch:{
    "$data.cur_vendor_name": {
      handler: function(newValue){
        this.calculate_el_select_width2();
      },
      deep: true,
    },
    "$data.cur_po.PurchaseFCity": {
      handler: function(newValue){
        this.calculate_el_select_width2();
      },
      deep: true,
    },
    "$data.cur_po.LocationID": {
      handler: function(newValue){
        this.calculate_el_select_width2();
      },
      deep: true,
    },
    "$data.cur_po.ComCity": {
      handler: function(newValue){
        this.calculate_el_select_width2();
      },
      deep: true,
    },
    "$data.cur_po.ShipTLocationID": {
      handler: function(newValue){
        this.calculate_el_select_width2();
      },
      deep: true,
    },
    "$data.cur_po.ShipTCity": {
      handler: function(newValue){
        this.calculate_el_select_width2();
      },
      deep: true,
    },
    "$data.current_page": {
      handler: function(newVal, oldVal){
        this.get_lists();
      },
      deep: true,
    },
  },
  mounted() {
    this.token = getStore("token");
    this.user_id = getStore("user_id");
    if (!this.token || !this.user_id) {
      this.$router.push("/login");
      return;
    }
    this.get_init_data();
    this.setStartandEndDate();
    setTimeout(()=>{
      this.calculate_el_select_width()
    },50); // timer is used to avoid funciton call before left menu is loaded
    window.addEventListener('resize', this.calculate_el_select_width);
    window.addEventListener('resize', this.calculate_el_select_width2);
    this.checkLoadingState("loadingTabContent");
  },
  unmounted(){
    window.removeEventListener('resize', this.calculate_el_select_width);
    window.removeEventListener('resize', this.calculate_el_select_width2);
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
hr.solid {
  border-top: 1px solid #bbb;
}
.input-label {
  display: inline-block;
  width: 130px;
}
.el-radio {
  line-height: 275%;
}
.el-collapse-item__header {
  background-color: #a5d1fa;
  font-size: 1.3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 2.2rem;
  border-radius: 0.75rem 0.75rem 0 0;
}
.el-collapse-item__content {
  padding-bottom: 0.5rem;
  border: 3px solid rgb(165, 209, 250);
}
.el-collapse-item__wrap {
  display: contents;
  content: "";
  clear: both;
  width: calc(100% - 5px);
  overflow-y: visible;
}
.addressinfo .el-collapse-item__content,
.productlist .el-collapse-item__content,
.podetail .el-collapse-item__content {
  padding: 0;
}
.product_popup {
  padding: 2%;
  position: fixed;
  top: calc(50% - 21rem);
  left: 15%;
  height: 42rem;
  width: 70%;
  z-index: 10;
  background: white;
}
.group-append-text .input-group-text {
  flex: 1;
}

.product-table th, .product-table td {
  padding: 0.1rem !important;
}
.product-table th div, .product-table td div {
  padding: 0.1rem !important;
  line-height: 1.25rem;
  overflow: visible;
}

#search_modal .modal-title,#import_modal .modal-title {
  font-size: 1.5rem;
}
#search_modal hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.search_product_section {
  max-height: 22rem !important;
  overflow: auto;
}
.search_product_section .el-table td,
.el-table th {
  padding: 2px 0;
}
.product_row {
  border-top: 1px solid gray;
  padding-top: 5px;
}
.product_sn {
  max-width: 10rem;
  display: inline-flex;
}
.product_sn_col {
  max-height: 4rem;
  overflow: auto;
}
.btn-inner--icon .icon-add {
  color: green;
  font-size: 1.8rem;
}
.btn-inner--icon .icon-remove {
  color: red;
  font-size: 1.8rem;
}
.po-edit .el-card__header{
  padding: 0.1rem 1rem;
}
.po-edit div{
  font-size: 1rem;
}
.po-edit .el-card__body {
  padding: 0.2rem;
}
.po-edit .icon-add {
  color: #404040;
  background-color: rgba(0, 255, 0, 0.5);
  border-radius: 50%;
}
.el-select .el-input {
  border-color: #409EFF;
}
.carrier_input .el-input__inner {
  height: 46px !important;
}
.el-input.is-disabled .el-input__inner{
  background-color: #e9ecef !important;
  border: 1px solid #cad1d7;
  color: #32325d;
}
.container_input {
  height: 47px !important;
}
.err{
  border:1px solid red;
}
.el-cascader{
  width: 80%;
}
.el-cascader-panel {
  height: 320px !important;
}
.el-cascader-menu {
  height: 320px !important;
  max-height: 320px !important;
}
.el-cascader-menu__wrap {
  height: 320px !important;
}
.el-cascader-menu__wrap.el-scrollbar__wrap {
  height: 320px !important;
}
.el-cascader__tags .el-tag:not(.is-hit){
  font-size: 18px;
}
.el-cascader-menu__list{
  min-width: 250px;
  min-height: 300px;
  max-height: 300px;
  font-size: 18px;
  overflow-x: hidden;
}
.el-checkbox:last-of-type{
  margin-top: 8px;
}
.el-scrollbar__bar.is-vertical{
  width: 0px !important;
  top: 2px !important;
}
#search_modal___BV_modal_body_ > form > section > div > div.el-table__body-wrapper.is-scrolling-none{
  height: 240px !important;
}
.el-table th.gutter{
  display: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.el-table .POProduct td:first-child .cell{ 
  display: none !important; 
}
.dropdown-menu {
  min-width: 2rem !important;
}
</style>
<style lang="scss" scoped>
/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #b3e5fc;
}
/deep/ .el-table__expand-icon>.el-icon{
  display: initial;
}
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: #fcfcfa;
  }
}
.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #cad1d7;
}


</style>